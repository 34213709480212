import React from 'react'
import styled from 'styled-components'

import Info from '../assets/icon/info_v2.png'

export const Input = ({ title, value, setValue, type, info, margin }) => {
    return (
        <InputWrap style={{marginBottom: margin}}>
            <div className="input-title">{title}</div>
            <input 
                className="input-elem" 
                type={type ? type : 'text'}
                value={value}
                onChange={(e) => setValue(e.target.value)}>
            
            </input>
            {
                info && <img src={Info} alt='info' className="input-img"/>
            }
        </InputWrap>
    )
}

const InputWrap = styled.div`
    width: 100%;
    margin-bottom: 25px;
    position: relative;

    .input {

        &-img {
            position: absolute;
            right: 11px;
            top: 56%;
            transform: translateX(-50%);
        }

        &-title {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #989899;
            margin: 10px 0;
        }
        
        &-elem {
            width: calc(100% - 30px);
            background: #F2F7FA;
            box-shadow: 0px 0px 2px rgba(50, 47, 109, 0.1);
            border-radius: 3px;
            border: none;
            font-size: 14px;
            line-height: 19px;
            color: #29335C;
            padding: 13px;

            &:focus {
                outline: none;
            }
        }
    }


`