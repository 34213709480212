import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Chart } from "chart.js";

export const Grafics = ({ data, vertical, revers }) => {

    return !revers ? (
        <StyledDiagram>
            <div className="data">
                {
                    data.data.map((item, index) => (
                        <div key={index} className="data__elem">
                            <div className='data__title'>
                                {item.label}
                                <div className="data__title-sub">{item.sub}</div>
                            </div>
                            <Canvas
                                data={item.value}
                            />
                        </div>
                    ))
                }
            </div>
            <div className={`legend${!!vertical ? ' vertical' : ''}`}>
                {
                    data.legend.map((item, index) => {
                        return (
                            <div key={index} className="legend__line">
                                <div style={{background: item.color}} className="point"/>
                                {item.title}
                            </div>
                        )
                    })
                }
            </div>
        </StyledDiagram>
    ) : (
        <StyledDiagram>
            <div className="data">
                <div className="data__elem">
                    {
                        data.data.map((item, index) => (
                            <div className='data__title' key={index} >
                                {item.label}
                                <div className="data__title-sub">{item.sub}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="data">
                {
                    data.data.map((item, index) => (
                        <div key={index} className="data__elem gorizontal">
                            <div style={{width: '100%'}}>
                                <Canvas
                                    data={item.value}
                                />
                            </div>
                            <div className={`legend vertical`}>
                                {
                                    data.legend.map((item, index) => {
                                        return (
                                            <div key={index} className="legend__line">
                                                <div style={{background: item.color}} className="point"/>
                                                {item.title}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </StyledDiagram>
    )
}

const Canvas = ({data}) => {
    const ref = useRef()
    const arr = data.map(item => item.value)
    const max = Math.max.apply(null, arr)

    useEffect(() => {

        Chart.elements.Rectangle.prototype.draw = function() {
    
            var ctx = this._chart.ctx;
            var vm = this._view;
            var left, right, top, bottom, signX, signY, borderSkipped, radius;
            var borderWidth = vm.borderWidth;
            // Set Radius Here
            // If radius is large enough to cause drawing errors a max radius is imposed
            var cornerRadius = 7;
        
            if (!vm.horizontal) {
                // bar
                left = vm.x - vm.width / 2;
                right = vm.x + vm.width / 2;
                top = vm.y;
                bottom = vm.base;
                signX = 1;
                signY = bottom > top? 1: -1;
                borderSkipped = vm.borderSkipped || 'bottom';
            } else {
                // horizontal bar
                left = vm.base;
                right = vm.x;
                top = vm.y - vm.height / 2;
                bottom = vm.y + vm.height / 2;
                signX = right > left? 1: -1;
                signY = 1;
                borderSkipped = vm.borderSkipped || 'left';
            }
        
            // Canvas doesn't allow us to stroke inside the width so we can
            // adjust the sizes to fit if we're setting a stroke on the line
            if (borderWidth) {
                // borderWidth shold be less than bar width and bar height.
                var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
                borderWidth = borderWidth > barSize? barSize: borderWidth;
                var halfStroke = borderWidth / 2;
                // Adjust borderWidth when bar top position is near vm.base(zero).
                var borderLeft = left + (borderSkipped !== 'left'? halfStroke * signX: 0);
                var borderRight = right + (borderSkipped !== 'right'? -halfStroke * signX: 0);
                var borderTop = top + (borderSkipped !== 'top'? halfStroke * signY: 0);
                var borderBottom = bottom + (borderSkipped !== 'bottom'? -halfStroke * signY: 0);
                // not become a vertical line?
                if (borderLeft !== borderRight) {
                    top = borderTop;
                    bottom = borderBottom;
                }
                // not become a horizontal line?
                if (borderTop !== borderBottom) {
                    left = borderLeft;
                    right = borderRight;
                }
            }
        
            ctx.beginPath();
            ctx.fillStyle = vm.backgroundColor;
            ctx.strokeStyle = vm.borderColor;
            ctx.lineWidth = borderWidth;
        
            // Corner points, from bottom-left to bottom-right clockwise
            // | 1 2 |
            // | 0 3 |
            var corners = [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom]
            ];
        
            // Find first (starting) corner with fallback to 'bottom'
            var borders = ['bottom', 'left', 'top', 'right'];
            var startCorner = borders.indexOf(borderSkipped, 0);
            if (startCorner === -1) {
                startCorner = 0;
            }
        
            function cornerAt(index) {
                return corners[(startCorner + index) % 4];
            }
        
            // Draw rectangle from 'startCorner'
            var corner = cornerAt(0);
            ctx.moveTo(corner[0], corner[1]);
        
            for (var i = 1; i < 4; i++) {
                corner = cornerAt(i);
                var nextCornerId = i+1;
                if(nextCornerId == 4){ // eslint-disable-line
                    nextCornerId = 0
                }
        
                var nextCorner = cornerAt(nextCornerId); // eslint-disable-line
        
                var width = corners[2][0] - corners[1][0];
                var height = corners[0][1] - corners[1][1];
                var x = corners[1][0];
                var y = corners[1][1];
                
                var radius = cornerRadius; // eslint-disable-line
                
                // Fix radius being too large
                if(radius > height/2){
                    radius = height/2;
                }if(radius > width/2){
                    radius = width/2;
                }
        
                ctx.moveTo(x + radius, y);
                ctx.lineTo(x + width - radius, y);
                ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                ctx.lineTo(x + width, y + height);
                ctx.quadraticCurveTo(x + width, y + height, x + width, y + height);
                ctx.lineTo(x, y + height);
                ctx.quadraticCurveTo(x, y + height, x, y + height);
                ctx.lineTo(x, y + radius);
                ctx.quadraticCurveTo(x, y, x + radius, y);
        
            }
        
            ctx.fill();
            if (borderWidth) {
                ctx.stroke();
            }
        };         

        const column = {
            borderWidth: 0,
            barPercentage: 0.3,
            categoryPercentage: 0.5,
            stacked: true
        }

        const dataChart = {
            labels: [''],
            datasets: data.map(item => ({
                ...column,
                label: data.label,
                backgroundColor: item.color,
                borderColor: item.color,
                data: [item.value]

            }))
        };

        const axes = {
            display: false,
            gridLines: {
                display: false
            },
            ticks: {
                display: false,
                max: 0,
                min: max, 
                stepSize: 0.01,
            }
        }

        const chart = new Chart(ref.current, {
            type: 'bar',
            data: dataChart,
            options: {
                responsive: data.lenght !== 2,
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 50,
                        bottom: 0
                    }
                },
                scales: {
                    xAxes: [{...axes}],
                    yAxes: [{...axes}]
                },
                legend: {
                    display: false,
                },
                hover: {
                    animationDuration: 0
                },
                animation: {
                    duration: 1,
                    onComplete: data[0].sub 
                        ? function() {
                            var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
            
                            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
                            ctx.fontStyle  = 'bold';
            
                            this.data.datasets.forEach(function(dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach((bar, index) => {
                                    var data = dataset.data[index] + ' wks';
                                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                });
                            })
                        }
                        :
                        function() {
                            var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
            
                            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
                            ctx.fontStyle  = 'bold';
            
                            this.data.datasets.forEach(function(dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach((bar, index) => {
                                    var data = dataset.data[index];
                                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                });
                            });
                        }
                },
                title: {
                    display: false,
                },
            }
        })

        return () => {
          chart.destroy();
        };
    }, [data, max]); 

    return (
        <canvas
            height="130px"
            width="296px"
            ref={ref}
            style={{margin: '0 auto'}}
        />
    )
}

const StyledDiagram = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .data {
        height: 100%;
        display: flex;
        margin-bottom: 10px;

        &__elem {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            &.gorizontal {
                flex-direction: row;
                align-items: center;
                margin-bottom: 30px;

                .data__graf {
                    height: 180px;
                }

                .legend {
                    margin-right: 40px;
                }
            }
        }

        &__graf {
            width: 100%;
            height: 120px;
            object-fit: contain;
        }

        &__title {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            color: #29335C;
            margin: 20px 40px 20px 30px;

            &-sub {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #989899;
                margin-top: 10px;
            }
        }
    }

    .legend {
        background: #F2F7FA;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #989899;
        padding: 13px 30px;
        display: flex;
        justify-content: center;

        &__line {
            display: flex;
            align-items: center;
            margin: 5px 15px;
        }

        .point {
            height: 16px;
            width: 16px;
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);
            border-radius: 3px;
            margin-right: 16px;
        }

        &.vertical {
            flex-direction: column;
            justify-content: start;
            align-items: start;

            .legend__line {
                margin: 15px 0;
            }
        }
    }

`