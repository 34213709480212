import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { LineGrafic2 } from '../../../components/LineGraficv2'
import { LineGrafic4 } from '../../../components/LineGraficv4'
import { LineGrafic5 } from '../../../components/LineGraficv5'
import { LineGrafic6 } from '../../../components/LineGraficv6'
import { Clock } from '../../../components/ClockDiagram/Clock'
import { Paper } from '../../../components/Paper'
import { Button } from '../../../components/Button'
import { DropDown } from '../../../components/DropDown'
import { UserInfo } from '../../../components/UserInfo'
import './style.scss'

import D8 from '../../../assets/d8.png'
import Case from '../../../assets/icon/case.png'
import Notification from '../../../assets/icon/notification.png'
import Calendar from '../../../assets/icon/calendar.png'
import Arrow from '../../../assets/icon/arrow.png'
import User from '../../../assets/user.png'

const data1 = [
    'USD',
    'EUR'
]

const Stats = (props) => {

    const [dropCurrent, setDropCurrent] = useState(data1[0])

    useEffect(() => {

        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (
                <div className="row">
                    <div className="col stats-user__header">
                        <div className="stats-user__header-left">
                            <div className="stats-user__header-calendar">
                                <img src={Calendar} alt="calendar" style={{marginRight: 10}}/>
                                From
                                <img src={Arrow} alt="arrow" className="arrow" />
                            </div>   
                            <div className="stats-user__header-calendar">
                                To
                                <img src={Arrow} alt="arrow" className="arrow" />
                            </div> 
                            <div className="stats-user__header-calendar">
                                2019 - annual stat
                                <img src={Arrow} alt="arrow" className="arrow" />
                            </div>
                        </div>
                        <div className="stats-user__header-right">
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Notification} alt="notification" />
                            </Button>
                            <UserInfo
                                pic={User}
                                name={'Eran Yessodi'}
                                style={{marginLeft: '10px'}}
                            />
                        </div>
                    </div>
                </div>
            )
        })
    }, []) // eslint-disable-line

    return (
        <div className="stats-user">
            <div className="row">
                <div className="col" style={{display: 'flex'}}>
                    <Paper
                        title="My Deals"
                    >
                        <div style={{height: 170}}>
                            <Clock  
                                data={{
                                    h1: 50,
                                    h2: 65,
                                    h3: 75,
                                    h4: 90,
                                    sale: true,
                                    line: [
                                        {
                                            value: '120',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#25182E',
                                                bottom: '15%'
                                            }
                                        },
                                        {
                                            value: '9',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#989899',
                                                bottom: '55%'
                                            }
                                        },
                                        {
                                            value: '5',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#989899',
                                                bottom: '65%'
                                            }
                                        },
                                        {
                                            value: '15',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#989899',
                                                bottom: '75%'
                                            }
                                        },
                                        {
                                            value: 'Pipeline',
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#25182E',
                                                bottom: '90%'
                                            }
                                        },
                                    ]
                                }}
                            />
                        </div>
                        <LineGrafic2
                            value={66.7}
                        />
                        <div className="stats-legend">
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#5F80F5`}}/>
                                <div className="stats-legend__elem-title">Closed-won:</div>
                                <div className="stats-legend__elem-value">{'120/180'}</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#32B76C`}}/>
                                <div className="stats-legend__elem-title">Ready to buy:</div>
                                <div className="stats-legend__elem-value">{9}</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#F3CB3D`}}/>
                                <div className="stats-legend__elem-title">On the fence:</div>
                                <div className="stats-legend__elem-value">{5}</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#F26065`}}/>
                                <div className="stats-legend__elem-title">NO GO:</div>
                                <div className="stats-legend__elem-value">{15}</div>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className="col" style={{display: 'flex'}}>
                    <Paper
                        title={
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                My Deals Value
                                <DropDown
                                    style={{
                                        width: 'auto',
                                        height: 30,
                                        paddingLeft: 10,
                                        marginLeft: 10

                                    }}
                                    data={data1}
                                    current={dropCurrent}
                                    setCurrent={setDropCurrent}
                                />
                            </div>
                        }
                    >
                        <div style={{height: 170}}>
                            <Clock  
                                data={{
                                    h1: 50,
                                    h2: 65,
                                    h3: 75,
                                    h4: 90,
                                    sale: true,
                                    line: [
                                        {
                                            value: '1.71 M',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#25182E',
                                                bottom: '15%'
                                            }
                                        },
                                        {
                                            value: '0.14 M',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#989899',
                                                bottom: '55%'
                                            }
                                        },
                                        {
                                            value: '0.7 M',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#989899',
                                                bottom: '65%'
                                            }
                                        },
                                        {
                                            value: 'Pipeline',
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#25182E',
                                                bottom: '90%',
                                            }
                                        },
                                    ]
                                }}
                            />
                        </div>
                        <LineGrafic2
                            value={68.4}
                        />
                        <div className="stats-legend">
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#5F80F5`}}/>
                                <div className="stats-legend__elem-title">Closed-won:</div>
                                <div className="stats-legend__elem-value">1.71/2.5 m</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#32B76C`}}/>
                                <div className="stats-legend__elem-title">Ready to buy:</div>
                                <div className="stats-legend__elem-value">0.14 m</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#F3CB3D`}}/>
                                <div className="stats-legend__elem-title">On the fence:</div>
                                <div className="stats-legend__elem-value">0.14 m</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#F26065`}}/>
                                <div className="stats-legend__elem-title">NO GO</div>
                                <div className="stats-legend__elem-value"></div>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className="col" style={{display: 'flex'}}>
                    <Paper
                        title="Sales Stage vs Buying Zone"
                    >
                        <div style={{margin: '0 25px'}}>
                            <LineGrafic4
                                data={[
                                    {
                                        color: '#F26065',
                                        width: 40,
                                        value: 2
                                    },
                                    {
                                        color: '#F3CB3D',
                                        width: 30,
                                        value: 2
                                    },
                                    {
                                        color: '#32B76C',
                                        width: 10,
                                        value: 1
                                    }
                                ]}
                                value={5}
                                title={'Qualification'}
                            />
                            <LineGrafic4
                                data={[
                                    {
                                        color: '#F26065',
                                        width: 90,
                                        value: 4
                                    },
                                    {
                                        color: '#F3CB3D',
                                        width: 20,
                                        value: 1
                                    },
                                    {
                                        color: '#32B76C',
                                        width: 70,
                                        value: 4
                                    }
                                ]}
                                value={9}
                                title={'Needs analysis'}
                            />
                            <LineGrafic4
                                data={[
                                    {
                                        color: '#F26065',
                                        width: 30,
                                        value: 2
                                    },
                                    {
                                        color: '#F3CB3D',
                                        width: 30,
                                        value: 2
                                    },
                                    {
                                        color: '#32B76C',
                                        width: 40,
                                        value: 2
                                    }
                                ]}
                                value={6}
                                title={'Proposal/notes'}
                            />
                            <LineGrafic4
                                data={[
                                    {
                                        color: '#F26065',
                                        width: 60,
                                        value: 4
                                    },
                                    {
                                        color: '#F3CB3D',
                                        width: 80,
                                        value: 4
                                    },
                                    {
                                        color: '#32B76C',
                                        width: 30,
                                        value: 2
                                    }
                                ]}
                                value={10}
                                title={'Negotiation'}
                            />
                        </div>
                        <div className="stats-legend">
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#32B76C`}}/>
                                <div className="stats-legend__elem-title">Ready to buy </div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#F3CB3D`}}/>
                                <div className="stats-legend__elem-title">On the fence </div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#F26065`}}/>
                                <div className="stats-legend__elem-title">NO GO</div>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col flex-grow-1" style={{display: 'flex', width: '35%'}}>
                    <Paper>
                        <div className='stats__title'>
                            Deals lifecycle
                            <div className="stats__title-sub">Average, by weeks</div>
                        </div>
                        <LineGrafic5
                            data={[
                                {
                                    color: '32B76C',
                                    label: 'team',
                                    value: 9
                                },
                                {
                                    color: 'F26065',
                                    label: 'you',
                                    value: 12
                                },
                            ]}
                        />
                    </Paper>
                </div>
                <div className="col flex-grow-2" style={{display: 'flex', width: '73%'}}>
                    <Paper className="average">
                        <div className="average__left">
                            <div className='stats__title'>
                                Average Contract Value
                            </div>
                            <div className="average__contract">
                                <div className="average__contract-value"><span>$</span>18 387.09</div>
                                <div className="average__contract-subvalue">+17% (2018)</div>
                                <img src={D8} alt="diagrama" />
                            </div>
                        </div>
                        <div className="average__right">
                            <div className='stats__title'>
                                Deals by Lead Source
                            </div>
                            <div className="average__right-data">
                                <LineGrafic6
                                    data={[
                                        {
                                            value: 120,
                                            title: '60',
                                            color: '#5F80F5'
                                        },
                                        {
                                            value: 190,
                                            title: '16.3 K',
                                            color: '#F3CB3D'
                                        }
                                    ]}
                                    title="Inbound"
                                />
                                <LineGrafic6
                                    data={[
                                        {
                                            value: 60,
                                            title: '33',
                                            color: '#5F80F5'
                                        },
                                        {
                                            value: 150,
                                            title: '22 K',
                                            color: '#F3CB3D'
                                        }
                                    ]}
                                    title="Outbound"
                                />
                            </div>
                            <div className="stats-legend">
                                <div className="stats-legend__elem">
                                    <div className="stats-legend__elem-point" style={{background:  `#5F80F5`}}/>
                                    <div className="stats-legend__elem-title">Number of deals </div>
                                </div>
                                <div className="stats-legend__elem">
                                    <div className="stats-legend__elem-point" style={{background:  `#F3CB3D`}}/>
                                    <div className="stats-legend__elem-title">Avg. contract value (USD)</div>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col stats-user__btn">
                    <Link to="/my-deals">
                        <div className="stats-user__btn-deals">
                            <img src={Case} alt="case" />
                            My deals
                        </div>
                    </Link>
                    
                    <Link to="/my-insights">
                        <Button type={"big"}>
                            My insights
                        </Button>
                    </Link>
                   
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        state: state
    })
)(Stats);