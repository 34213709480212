import React, { useState, useEffect, useRef } from 'react'

export default ({ data, index, numRow, numCol }) => {

    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const ref = useRef()
    const [renderSvg, setRenderSvg] = useState([])

    useEffect(() => {
        window.addEventListener('onload', editSize)
        window.addEventListener('resize', editSize)
    }, []) // eslint-disable-line

    useEffect(() => {
        editSize()
    }, [ref, ref.current, data, numRow, numCol]) // eslint-disable-line

    useEffect(() => {
        point()
    }, [height, width]) // eslint-disable-line

    const editSize = () => {
        setWidth(ref.current.width.animVal.value)
        setHeight(ref.current.height.animVal.value)
    }

    const point = () => {

        let line = []

        setRenderSvg(data.value.map((item, index) => {
            return data.value[index + 1] && (
                <line 
                    key={index}
                    stroke={data.color}
                    strokeWidth="3"
                    x1={`${(width / numCol * index) + width / numCol / 2}`} 
                    y1={`${height - (70 * (item / 50000))}`} 
                    x2={`${(width / numCol * (index + 1)) + width / numCol / 2}`}  
                    y2={`${height - (70 * (data.value[index + 1] / 50000))}`}
                />
            )
        }))
    }

    return (
        <svg
            ref={ref}
            style={{
                height: '100%',
                width: '100%',
                position: 'absolute'
            }}
        >
            {
                renderSvg
            }
        </svg>
    )
}