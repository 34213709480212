import React, { useState } from 'react'
import styled from 'styled-components'

import Arrow from '../assets/icon/arrow.png'

export const InputDropDown = ({ title, value, setValue, list }) => {
    
    const [open, setOpen] = useState(false)

    return (
        <InputWrap>
            <div className="input-title">{title}</div>
            <div 
                className="input-elem" 
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onMouseLeave={() => setOpen(false)}
                onClick={() => setOpen(!open)}
            >
                
                <div className="input-elem__wrap">
                    {value}
                </div>
                <img src={Arrow} alt="arrow" className="arrow" />
                {
                    open &&
                        <div className="drop">
                            {
                                list.map((item, index) => {
                                    return (
                                        <div className="drop__elem" key={index} onClick={() => setValue(item)}>
                                            {item}
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        </InputWrap>
    )
}

const InputWrap = styled.div`
    width: 100%;
    margin-bottom: 25px;

    .input {

        &-title {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #989899;
            margin: 10px 0;
        }
        
        &-elem {
            width: 100%;
            max-width: calc(100% - 30px);
            background: #F2F7FA;
            box-shadow: 0px 0px 2px rgba(50, 47, 109, 0.1);
            border-radius: 3px;
            border: none;
            font-size: 14px;
            line-height: 19px;
            color: #29335C;
            padding: 13px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            &__wrap {
                white-space: nowrap; 
                overflow: hidden; 
                text-overflow: ellipsis;
            }

            &:focus {
                outline: none;
            }

            .drop {
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 100%;
                padding: 10px 0;
                border-radius: 3px;
                box-shadow: 0px 2px 4px rgba(50, 47, 109, 0.1);
                background: #FEFEFF;
                z-index: 1000000;

                &__elem {
                    padding: 6px 20px;
                    
                    &:hover {
                        background: #FaFaFa;
                    }
                }
            }
        }
    }
`