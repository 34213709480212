import React from 'react'
import styled from 'styled-components'

export default () => {

    return <Loader>
        <div className="loader-wrap">
            <div className="loader-elem" />
            <div className="loader-elem reverse" />
            <div className="loader-elem" />
            <div className="loader-elem reverse" />
            <div className="loader-elem" />
        </div>
    </Loader>
}

const Loader = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
        &-wrap {
            height: 120px;
            width: 160px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-elem {
            height: 100%;
            width: 18px;
            background: #56c3ff;
            animation: 1.3s ease-in-out loading infinite;

            &.reverse {
                animation: 1.3s ease-in-out loading_reverse infinite;
            }
        }
    }

    @keyframes loading { from { height: 100% } 50% { height: 50% } to { height: 100% }  }
    @keyframes loading_reverse { from { height: 50% } 50% { height: 100% } to { height: 50% }  }

`