import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Line from './Line'
import WFPlayer from 'wfplayer';

import {ReactComponent as Play} from '../../assets/Player/play_v2.svg'
import {ReactComponent as Pause} from '../../assets/Player/pause_v2.svg'
import {ReactComponent as Next} from '../../assets/Player/next_v2.svg'
import {ReactComponent as Volume} from '../../assets/Player/volume_v2.svg'
import {ReactComponent as FullScreen} from '../../assets/Player/full.svg'

import Call from '../../assets/Player/call.png'
import Pitch from '../../assets/Player/pitch.png'
import Intensity from '../../assets/Player/intensity.png'
import Speed from '../../assets/Player/speed.png'
import Close from '../../assets/Player/close.png'
import Search from '../../assets/Player/search.png'

export const VideoPlayer = ({ data, transcriptionVisible = true }) => {
    const ref = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const [status, setStatus] = useState(false)

    const [audio] = useState(new Audio(data.audio[0]))
    const [audio2] = useState(new Audio(data.audio[1]))
    const [Spectrum, setSpectrum] = useState()
    const [Spectrum2, setSpectrum2] = useState()    
    const [time, setTime] = useState('0:00')
    const [currentTime, setCurrentTime] = useState(0)
    // const [volumeStatus, setVolumeStatus] = useState(audio.muted ? VolumeOff : Volume)
    const [callTranscription, setCallTranscription] = useState(transcriptionVisible)
    // const [speed, setSpeed] = useState(false)

    const FormatTime = (time) => {
        let minute = Math.floor(time / 60)
        let sec = Math.floor(time - (minute * 60))
        return `${minute}:${sec < 10 ? '0' + sec : sec}`
    }

    useEffect(() => {
        ref && (ref.current.muted = true)
        ref && ref.current.addEventListener('loadedmetadata', () => {
            setTime(ref.current.duration)
        });
    }, [ref])

    useEffect(() => {
        if (Spectrum) {
            Spectrum.isMuted = false
            Spectrum.on("ready", function(){
                Spectrum.setMute()
            });
            Spectrum.load(data.audio[0]);
        }
    }, [Spectrum, data.audio])

    useEffect(() => {
        if (Spectrum2) {
            Spectrum2.isMuted = false
            Spectrum2.on("ready", function(){
                Spectrum2.setMute()
            });
            Spectrum2.load(data.audio[1]);
        }
    }, [Spectrum2, data.audio])

    useEffect(() => {
        if (ref2 && ref2.current) {
            ref2 && setSpectrum(new WFPlayer({
                container: ref2.current,
                mediaElement: audio,
                ...SpectrumOption
            }))
        }
        
        if (ref3 && ref3.current) {
            ref3 && setSpectrum2(new WFPlayer({
                container: ref3.current,
                mediaElement: audio2,
                ...SpectrumOption
            }))
        }
    }, [ref2, ref3, data]) // eslint-disable-line

    const SpectrumOption = {
        grid: false,
        cursor: false,
        waveColor: '#EBEEFF',
        progressColor: '#DADFFF',
        backgroundColor: '#FFFFFF',
        padding: 1,
        waveScale: 1.8,
        duration: 20,
    }

    var timer;

    const onPlay = () => {
        ref.current.play()
        audio.play()
        audio2.play()
        timer = setInterval(() => {
            setCurrentTime(ref.current.currentTime)
        }, 500)
        setStatus(true)
    }

    const onPause = () => {
        ref.current.pause()
        audio.pause()
        audio2.pause()
        setStatus(false)
        clearInterval(timer)
    }

    const onMuted = () => {
        audio.muted = !audio.muted
        audio2.muted = !audio2.muted
    }

    audio.onended = () => {
        audio.currentTime = 0
        audio2.currentTime = 0
        ref.current.currentTime = 0
        onPause()
    }

    audio2.onended = () => {
        audio.currentTime = 0
        audio2.currentTime = 0
        ref.current.currentTime = 0
        onPause()
    }
    
    return data && (
        <Widget>
            <div className="videow">
                <video 
                    src={data.video} 
                    ref={ref}
                />
                <div className="videow-control">
                    <div className="videow-control__time">
                        {FormatTime(currentTime)}/{FormatTime(time)}
                    </div>
                    <div className="videow-control__line">
                        <div 
                            className="videow-control__line-progress" 
                            style={{
                                width: `${100/time * currentTime}%`
                            }}
                        />
                    </div>
                    <div className="videow-control__group">
                        <div className="videow-control__group-left">
                            <div className="videow-control__group-btn min">
                                {
                                    status
                                        ? <Pause onClick={onPause} />
                                        : <Play onClick={onPlay} />
                                }
                            </div>
                            <div className="videow-control__group-btn min">
                                <Next />
                            </div>
                            <div className="videow-control__group-btn min">
                                <Volume onClick={onMuted} />
                            </div>
                        </div>
                        <div className="videow-control__group-right">
                            <div 
                                className={`videow-control__group-btn control${callTranscription ? ' active' : ''}`}
                                onClick={() => {
                                    setCallTranscription(val => !val)
                                }}
                            >
                                <div className="videow-control__group-btn-filter">
                                    <img src={Call} alt="filter" />
                                </div>
                                Call transcript
                            </div>
                            <div 
                                className={`videow-control__group-btn control${true ? ' active' : ''}`}
                                onClick={() => {}}
                            >
                                <div className="videow-control__group-btn-filter">
                                    <img src={Pitch} alt="filter" />
                                </div>
                                Pitch
                            </div>
                            <div 
                                className={`videow-control__group-btn control${false ? ' active' : ''}`}
                                onClick={() => {}}
                            >
                                <div className="videow-control__group-btn-filter">
                                    <img src={Intensity} alt="filter" />
                                </div>
                                Intensity
                            </div>
                            <div 
                                className={`videow-control__group-btn control${false ? ' active' : ''}`}
                                onClick={() => {}}
                            >
                                <div className="videow-control__group-btn-filter">
                                    <img src={Speed} alt="filter" />
                                </div>
                                Speed
                            </div>
                            <div className={`videow-control__group-btn min${false ? ' active' : ''}`}>
                                <FullScreen />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="audio-wrap">

                <div className="audio-side">
                    {
                        data.user.map((item, index) => {
                            return (
                                <div className="audio-side__user" key={index}>
                                    <div className="audio-side__user-photo">
                                        <img src={item.pic} alt="pic" />
                                        {item.mailNum ? <div className="mailnum">+{item.mailNum}</div> : null}
                                    </div>
                                    <div className="audio-side__user-name">
                                        {item.name}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="audio">
                    <div className="audio-line" ref={ref2} />
                    <div className="audio-line" ref={ref3} />
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%'
                    }}>
                        <Line
                            height={37}
                            width={[]}
                        />
                        <Line
                            height={30}
                            width={[]}
                        />
                    </div>
                </div>
            </div>
            {
                callTranscription && (
                    <div className="call">
                        <div className="call-header">
                            <h2 className="title" style={{margin: 0}}>Call transcript</h2>
                            <div className="call-action">
                                <img src={Search} alt="search" />
                                <img 
                                    src={Close} 
                                    alt="close" 
                                    onClick={() => setCallTranscription(false)}
                                />
                            </div>
                        </div>
                        
                        <div className="call-wrap">
                            {
                                data.chat.map((item, index) => {
                                    return (
                                        <div className="call-message" key={index}>
                                            <img src={data.user[item.id].pic} className="call-message__photo" alt="user" />
                                            <div className="call-message__text">
                                                {item.text}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
        </Widget>
    )
}

const Widget = styled.div`

    .videow {
        width: 100%;
        position: relative;

        video {
            width: 100%;
            height: 100%;
        }

        &-control {
            position: absolute;
            bottom: 5px;
            width: 100%;
            background: rgba(56, 56, 56, .5);
            padding: 10px;
            box-sizing: border-box;

            &__time {
                display: flex;
                justify-content: flex-end;
                font-size: 12px;
                line-height: 16px;
                color: #FFFFFF;
            }

            &__line {
                width: 100%;
                height: 4px;
                padding: 1px;
                margin: 10px 0;
                background: #FFFFFF;
                border-radius: 5px;

                &-progress {
                    height: 100%;
                    background: #394577;
                    border-radius: 5px;
                }
            }

            &__group {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-left {
                    display: flex;
                    align-items: center;
                }

                &-right {
                    display: flex;
                    align-items: center;
                }

                &-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 10px 0 5px;
                    padding-right: 10px;
                    cursor: pointer;
                    font-size: 10px;
                    line-height: 14px;
                    color: #989899;
                    white-space: nowrap;

                    &.control {
                        margin: 0 15px;
                    }

                    &.min {
                        height: 15px;
                        width: 15px;
                        padding-right: 0;
                    }

                    &.active {
                        background: #5F80F5;
                        border-radius: 2px;
                        color: #FFFFFF;
                    }

                    &-filter {
                        height: 15px;
                        width: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #5F80F5;
                        border-radius: 2px;
                        padding: 3px;
                        margin-right: 5px;
                    }

                    svg {
                        path {
                            stroke: #5F80F5;
                            fill: #5F80F5;
                        }
                    }

                    &:hover {
                        svg path {
                            stroke: #F9F8F8;
                            fill: #F9F8F8;
                        }
                    }
                }
            }
        }
    }

    .audio {
        width: calc(100% - 100px);
        position: relative;

        &-line {
            height: 108px;

            wave {
                height: 108px!important;
            }
        }

        &-wrap {
            width: 100%;
            display: flex;
            margin-top: 25px;
        }

        &-side {
            background: #F2F7FA;
            padding: 0 32px;
            border-radius: 4px;
            position: relative;

            &__user {
                margin: 30px 0;

                &-photo {
                    height: 32px;
                    width: 32px;
                    margin-bottom: 10px;
                    position: relative;
                    border-radius: 30px;
                    border: 2px solid white;
                    box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);

                    .mailnum {
                        position: absolute;
                        bottom: -5px;
                        right: -5px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 9px;
                        line-height: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFFFFF;
                        background-color: #394577;
                        padding: 3px;
                        border-radius: 50%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 30px;
                        object-fit: cover;
                    }
                }

                &-name {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    color: #25182E;
                    text-align: center;
                }
            }
        }
    }

    .call { 
        padding: 0 30px 50px;

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 35px 0;
        }

        &-action {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
                margin-left: 40px;
                cursor: pointer;
            }
        }

        &-wrap {
            height: 120px;
            overflow: hidden;
            overflow-y: scroll;

            &::-webkit-scrollbar-track {
                background-color: #fff;
            }

            &::-webkit-scrollbar {
                width: 3px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #394577;
            }
        }

        &-message {
            display: flex;
            margin-bottom: 30px;

            &__photo {
                height: 40px;
                width: 40px;
                object-fit: cover;
                border-radius: 20px;
                margin-right: 10px;
            }

            &__text {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #29335C;
                background: #F2F7FA;
                border-radius: 5px;
                padding: 10px 10px 10px 20px;
            }
        }
    }
`