import React from'react'

import Warning from '../../assets/icon/warning.png'

export default ({ color, style, data, index, popupPosition, lastIndex }) => {
    return (
        <div 
            className="diagram-svg__point" 
            style={{
                ...style
            }}
        >
            <div className="diagram-svg__point-elem" style={{ background: color }}/>
            <div 
                className="diagram-svg__point-popup" 
                style={{
                    left: index <= 5 ? '100%' : '', 
                    right: index > 5 ? '100%' : '',
                    bottom: !popupPosition ? '100%' : '',
                    top: popupPosition ? '100%' : ''
                }}
            >
                <div className="popup-row">
                    <div className="popup-row__title">
                        Deal Maker's name:
                    </div>
                    <div className="popup-row__value"> 
                        {data.name}
                    </div>
                </div>
                <div className="popup-row">
                    <div className="popup-row__title">
                        % of change in revenue:
                    </div>
                    <div className="popup-row__value"> 
                        {`${data.revenue > 0 ? '+': data.revenue < 0 ? '-' : ''}${data.revenue}%`}
                    </div>
                </div>
                <div className="popup-row">
                    <div className="popup-row__title">
                        Total revenue in USD:
                    </div>
                    <div className="popup-row__value"> 
                        {`$${Math.floor(data.totalRevenue/1000)}k`}
                    </div>
                </div>
                <div className="popup-row">
                    <div className="popup-row__title">
                        Total Upsell in USD from total:
                    </div>
                    <div className="popup-row__value"> 
                        {`$${(data.totalUpsell/1000).toFixed(1)}k`}
                    </div>
                </div>
                    
            </div>
            {
                index === lastIndex && data.status && <img src={Warning} alt="status" />
            }
        </div>
    )
}