import React, { useState } from 'react'
import styled from 'styled-components'

import Time from '../../assets/Widget/time.png'
import Filter from '../../assets/Widget/filter.png'
import FilterA from '../../assets/Widget/filter_white.png'
import Sleep from '../../assets/Widget/sleep.png'
import Arrow from '../../assets/Widget/arrow_v2.png'



export default ({ setTimeLineVisible, setVisible, visible }) => {
    
    const filter = [
        {
            icon: Time,
            title: 'Timeline',
            onclick: () => {
                setTimeLineVisible(value => !value)
            }
        },
        {
            icon: Filter,
            title: 'Filter: ',
            active: FilterA,
            child: ['Main contacts', 'All contacts'],
            action: visible,
            setAction: (val) => {setVisible(val)}
        },
        {
            icon: Sleep,
            title: 'Inactivity',
            tooltip: 'Click to see interaction details'
        }
    ]
    return (
        <StyledFilter>
            {
                filter.map((item, index) => {
                    return <Element 
                        key={index} 
                        data={item} 
                    />
                })
            }
        </StyledFilter>
    )
}

const Element = ({data}) => {
    const [open, setOpen] = useState(false)

    return (
        <div 
            className="filter" 
            onClick={() => {
                data.onclick && data.onclick()
                setOpen(value => !value)
            }}
            onMouseLeave={() => setOpen(false)}
            style={{
                background: `${data.active && '#C0CBEF'}`
            }}
        >
            {/* { data.tooltip && <div className="filter-tooltip">
                {data.tooltip}
            </div>} */}
            <img className="filter-icon" src={data.active || data.icon} alt="icon" />
            <div className="filter-title" style={{color: `${data.active && '#ffffff'}`}}>
                {data.title}
                { 
                    data.child && data.action
                }
            </div>
            {
                data.active && <img className="filter-icon" src={Arrow} alt="icon" />
            }
            {
                open && data.child &&
                    <div className="filter-popup">
                        {
                            data.child.map((item, index) => {
                                return item !== data.action && (
                                    <div className="filter-popup__elem" key={index} onClick={() => {
                                        data.setAction(item)
                                    }}>
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}

const StyledFilter = styled.div`
    background: #F2F7FA;
    border-radius: 5px;
    padding: 7px 2px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    z-index: 100;

    .filter {
        display: flex;
        align-items: center;
        padding: 0 7px;
        border-radius: 2px;
        cursor: pointer;
        position: relative;

        &:hover {
            .filter-tooltip {
                display: block;
            }
        }

        &-tooltip {
            width: 150px;
            display: none;
            position: absolute;
            top: 110%;
            right: 0;
            font-size: 9px;
            padding: 5px 8px;
            border-radius: 5px;
            background-color: #F2F7FA;
            border: 2px solid #FFFFFF;
            box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);
        }

        &-icon {
            margin-right: 4px;
        }

        &-title {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 16px;
            color: #989899;
            margin: 0 6px;
        }

        &-popup {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            border-radius: 4px;
            border: 1px solid #efefef;
            background: #fff;
            font-weight: normal;
            font-size: 10px;
            line-height: 16px;
            text-align: center;

            &__elem {

            }
        }
    }
`