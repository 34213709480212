import React from 'react'
import styled from 'styled-components'

export const Paper = ({ children, title, nav, topNav, style, className }) => (
    <PaperStyled style={style} className={className}>
        {
            topNav && (
                <div className="top-nav">
                    {topNav}
                </div>
            )
        }
        { 
            title || nav 
                ? (
                    <div className="header">
                        {title ? <h2 className="title">{title}</h2> : null }
                        {nav}
                    </div>
                )
                : null
        }
        { children }
    </PaperStyled>
)

const PaperStyled = styled.div`
    width: 100%;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 25px rgba(50, 47, 109, 0.1);
    border-radius: 10px;
    margin-bottom: 50px;
    box-sizing: border-box;

    .top-nav {
        margin: 15px 25px 0;
    }

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #29335C;
        margin: 25px 0;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 25px;
    }
`