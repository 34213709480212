import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox } from '../../../components/Checkbox'
import { Paper } from '../../../components/Paper'
import { Button } from '../../../components/Button'
import RedArrow from '../../../assets/icon/red_arrow.png'
import Group from '../../../assets/icon/group.png'
import D1 from '../../../assets/d7.png'
import D2 from '../../../assets/d8.png'
import Mic from '../../../assets/icon/mic.png'
import Error from '../../../assets/icon/error.png'
import Info from '../../../assets/icon/info.png'
import User from '../../../assets/Beverly.png'
import Close from '../../../assets/Player/close.png'
import { Clock } from '../../../components/ClockDiagram/Clock'

export default () => {

    const [check, setCheck] = useState(0)

    return (<>
        <div className="row leader">
            <div className="col" style={{display: 'flex'}}>
                <div className="checkbox-wrap">
                    <Checkbox
                        checked={check}
                        value={0}
                        onChange={setCheck}
                        title={'Top closers'}
                    />
                </div>
                <div className="checkbox-wrap">
                    <Checkbox
                        checked={check}
                        value={1}
                        onChange={setCheck}
                        title={'Best upsellers'}
                    />
                </div>
            </div>
        </div>
        <div className="row leader">
            <div className="col">
                <div className="leader-list">
                    <div className="leader-list__row">
                        <div className="leader-list__row-col start">
                            Pos.
                            <img src={RedArrow} alt="arrow" />
                        </div>
                        <div className="leader-list__row-col">
                            Dealmaker
                            <img src={RedArrow} alt="arrow" />
                        </div>
                        <div className="leader-list__row-col">
                            # of closed-won
                            <img src={RedArrow} alt="arrow" />
                        </div>
                        <div className="leader-list__row-col" style={{justifyContent: 'center'}}>
                            Badges
                            <img src={RedArrow} alt="arrow" />
                        </div>
                    </div>
                    {
                        data.map((item, index) => {
                            return (
                                <div className="leader-list__row leader-list__row-content" key={index}>
                                    <div className="leader-list__row-col start">
                                        {index + 1}
                                    </div>
                                    <div className="leader-list__row-col">
                                        {item.name}
                                    </div>
                                    <div className="leader-list__row-col" style={{justifyContent: 'center'}}>
                                        {item.closed}
                                    </div>
                                    <div className="leader-list__row-col" style={{justifyContent: 'center'}}>
                                        {
                                            item.badges.map((item, index) => {
                                                return (
                                                    <img 
                                                        key={index}
                                                        src={
                                                            item === 'group'
                                                                ? Group
                                                                : item === 'mic'
                                                                    ? Mic
                                                                    : Error
                                                        }
                                                        alt="pic" 
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="col">
                <Paper
                    title={<div style={{display: 'flex', alignItems: 'center'}}>Details<img src={Info} alt="info" style={{marginLeft: 15}} /></div>}
                    nav={<img src={Close} alt="close" />}
                >
                    <div className="row user">
                        <div className="user-photo">
                            <img src={User} alt="user" />
                        </div>
                        <div className="user-info">
                            <div className="user-info__title"><span className="user-info__value" style={{marginLeft: 0}}>Beverly Mendes</span> <img src={Group} alt="group" /></div>
                            <div className="user-info__title">Employed: <span className="user-info__value">since Nov. 2017</span></div>
                            <div className="user-info__title">Date of last deal closed: <span className="user-info__value">Dec. 28.2019</span></div>
                        </div>
                    </div>
                    <div className="row data">
                        <div className="col">
                            <div className="data-title">Deals closed</div>
                            <div style={{display: 'flex'}}>
                                <div style={{height: 70}}>
                                    <Clock  
                                        data={{
                                            h1: 50,
                                            h2: 65,
                                            h3: 75,
                                            h4: 90,
                                            bottom: 53
                                        }}
                                        color={'F3F8FA'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="data-title">Events to closed-won</div>
                            <div className="data-elem">
                               <img src={D1} alt="d1" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="data-title">Average Contract Value</div>
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    color: '#25182E',
                                    width: '70%',
                                    textAlign: 'center'
                                }}
                            >$23 512</div>
                            <div className="data-elem">
                               <img src={D2} alt="d2" />
                            </div>
                        </div>
                    </div>  
                    <div className="row user-btn">
                        <Link to="/my-insights">
                            <Button>
                                Dealmaker’s insights
                            </Button>
                        </Link>
                        
                    </div>
                </Paper>
            </div>
        </div>
    </>)
}

const data = [
    {
        name: 'Beverly Mendes',
        closed: 53,
        badges: ['group']
    },
    {
        name: 'James Reed',
        closed: 48,
        badges: ['group', 'mic']
    },
    {
        name: 'John Higgins',
        closed: 47,
        badges: ['group']
    },
    {
        name: 'Beth Stewart',
        closed: 44,
        badges: []
    },
    {
        name: 'Donna Perez',
        closed: 42,
        badges: ['mic']
    },
    {
        name: 'William Young',
        closed: 39,
        badges: ['group']
    },
    {
        name: 'Jessica Taylor',
        closed: 38,
        badges: []
    },
    {
        name: 'Emma Watkins',
        closed: 36,
        badges: []
    },
    {
        name: 'Gabrielle LaRue',
        closed: 31,
        badges: []
    },
    {
        name: 'Ron Mitchell',
        closed: 28,
        badges: []
    }
]