import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import './auth.scss'
import {
    TextField
} from '@material-ui/core'
import { Button } from '../../components/Button'
import { TopLink } from '../../components/TopLink'
import moment from 'moment';

export default () => {

    const [redirect, setRedirect]  = useState(null)
    const [data, setData] = useState({
        email: '',
        password: '',
        // email: 'eran@substrata.me',
        // password: '1234'
    })
    const [error, setError] = useState({
        email: false,
        password: false
    })

    const submit = () => {
        const errors = {...error}

        if (!data.email)  {
            errors.email = 'Email address is required'
        // eslint-disable-next-line
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data.email))) {
            errors.email = 'Email has the wrong format'
        } else if (data.email !== 'eran@substrata.me' && data.email !== 'admin@substrata.me') {
            errors.email = 'Email is invalid'
        } else {
            errors.email = false
        }

        if (!data.password)  {
            errors.password = 'Password is required'
        } else if ((data.email === 'eran@substrata.me' && data.password !== 'rjfre234@jde') || (data.email === 'admin@substrata.me' && data.password !== 'oinjoi2453nj@jinii$')) {
            errors.password = 'Password is invalid'
        } else {
            errors.password = false
        }

        setError(errors)

        if (Object.values(errors).filter(item => !!item).length === 0) {
            localStorage.setItem('loginTime', moment().toISOString())
            data.email === 'eran@substrata.me' && setRedirect(<Redirect to="/my-insights" />)
            data.email === 'admin@substrata.me' && setRedirect(<Redirect to="/admin/insights" />)
        }
    }

    return (<>
        <TopLink
            // text={'Don’t have an account?'}
            // textButton={'Get started'}
            text={'We`re in beta phase'}
            textButton={'Sign Up for Updates'}
            link={'/auth/get-started'}
        />
        { redirect }

        <div className="auth">
            <h3 className="auth__title">
                Sign in to SCIP by Substrata
            </h3>
            <h5 className="auth__subtitle">
                Enter your details below
            </h5>
            <TextField
                error={!!error.email}
                variant="outlined"
                className="auth__elem"
                label="Email Address"
                value={data.email}
                onChange={e => {
                    setData({
                        ...data,
                        email: e.target.value
                    })
                }}
                helperText={error.email}
            />
            <div className="auth__end">
                <Link to="/auth/forgot-password">
                    Forgot your password?
                </Link>
            </div>
            <TextField
                error={!!error.password}
                variant="outlined"
                type="password"
                className="auth__elem"
                label="Password"
                value={data.password}
                onChange={e => {
                    setData({
                        ...data,
                        password: e.target.value
                    })
                }}
                helperText={error.password}
            />
            <div className="auth__elem btn-group">
                <Button
                    onClick={submit}
                >
                    Sign In
                </Button>
            </div>
        </div>
    </>)
}
