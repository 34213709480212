import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import user1 from '../../assets/Widget/user1.png'
import user2 from '../../assets/Widget/user2.png'
import user3 from '../../assets/Widget/user3.png'
import user4 from '../../assets/Widget/user4.png'
import user5 from '../../assets/Widget/user5.png'
import email from '../../assets/Widget/mail.png'
import corona from '../../assets/Widget/corona.png'
import ccGrean from '../../assets/Widget/cc_grean.png'
import ccWhite from '../../assets/Widget/cc_white.png'
import time from '../../assets/Widget/time.png'

import Line from './Line'
import DotLine from './DotLine'
import Filter from './Filter'
import Arrow from './Arrow'
import TimeLine from './TimeLine'

export default ({ setVisible, visible }) => {

    const position = x => 100 / dataEmail[dataEmail.length - 1].date.id * x - 100 / dataEmail[dataEmail.length - 1].date.id / 2
    const widthBlock = x => 100 / dataEmail[dataEmail.length - 1].date.id

    const [timeLineVisible, setTimeLineVisible] = useState(true)

    return (
        <div className="widget">
            <div className="fageBlock" />
            <div className="left-side">
                {
                    data.map((item, index) => {
                        return (
                            <div className="left-side__user" key={index} style={{opacity: item.date === 0 ? 1 : 0}}>
                                <div className="left-side__user-photo">
                                    <img src={item.photo} alt="pic" />
                                    {item.mailNum ? <div className="mailnum">+{item.mailNum}</div> : null}
                                </div>
                                <div className="left-side__user-name">
                                    {item.name}
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="main">
                {
                    dataEmail.map((item, index) => {
                        return dataEmail[index + 1] ? (
                            <DotLine
                                key={index}
                                current={item}
                                next={dataEmail[index + 1]}
                                lenghtX={dataEmail[dataEmail.length - 1].date.id}
                                lenghtY={data.length}
                            />
                        ) : null
                    })
                }

                <Arrow />
                
                {
                    data.map((item, index) => {
                        return (
                            <div className="main__user" key={index} style={{height: `${100/data.length}%`}}>
                                <Line />
                                {
                                    dataEmail.map((elem, index2) => {
                                        return item.id === elem.userId ? (
                                            <Link
                                                to={`/my-deals/email/${index2}`}
                                                key={`${index}${index2}`}
                                            >
                                                <div 
                                                    className="main__email" 
                                                    key={`${index}${index2}`}
                                                    style={{
                                                        left: `${position(elem.date.id)}%`,
                                                        animationDelay: `${position(elem.date.id)/50}s`
                                                    }}
                                                >
                                                    <div className="main__email-anim" style={{ background: '#394577'}}>
                                                        <img src={email} alt="mail" />
                                                        {elem.status && <img src={corona} alt="corona" className="main__email-top" />}
                                                        {
                                                            elem.substatus === 2
                                                                ? <img src={ccGrean} alt="cc" className="main__email-bottom" />
                                                                : elem.substatus === 1
                                                                    ? <img src={ccWhite} alt="cc" className="main__email-bottom" />
                                                                    : null
                                                        }
                                                    </div>
                                                    <div className="main__email-tooltip">
                                                        Click to see interaction details
                                                    </div>
                                                </div>
                                            </Link>
                                        ) : null
                                    })
                                }
                                {
                                    data.map((elem, index2) => {
                                        return item.id === elem.id && elem.date !== 0 
                                            ? (
                                                <div 
                                                    className="main__users" 
                                                    key={`${index}${index2}`}
                                                    style={{
                                                        left: `${position(elem.date)}%`,
                                                        width: `${widthBlock() - 1}%`,
                                                        animationDelay: `${position(elem.date.id)/50}s`
                                                    }}
                                                >
                                                    <div className="main__users-photo">
                                                        <img src={elem.photo} alt="pic" />
                                                        {elem.mailNum ? <div className="mailnum">+{elem.mailNum}</div> : null}
                                                    </div>
                                                    <div className="main__users-name">
                                                        {elem.name}
                                                    </div>
                                                </div>
                                            ) : null
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        
            <Filter 
                setTimeLineVisible={setTimeLineVisible}
                setVisible={setVisible}
                visible={visible}
            />

            {
                timeLineVisible && (
                    <div className="time-line">
                        <div className="time-line__label">
                            <img src={time} alt="time" />
                            <span className="time-line__label-text">By month</span>
                        </div>

                        <TimeLine 
                            data={dataEmail}
                        />
                    </div>
                )
            }
        </div>
    )
}

const data = [
    {
        id: 0,
        name: 'John',
        photo: user1,
        mailNum: 1,
        date: 0
    },
    {
        id: 1,
        name: 'Eran',
        photo: user2,
        mailNum: 0,
        date: 1
    },
    {
        id: 2,
        name: 'Tom',
        photo: user3,
        mailNum: 2,
        date: 0
    },
    {
        id: 3,
        name: 'Lisa',
        photo: user4,
        mailNum: 0,
        date: 4
    },
    {
        id: 4,
        name: 'Kim',
        photo: user5,
        mailNum: 0,
        date: 4
    }
]

const dataEmail = [
    {
        userId: 0,
        date: {
            id: 1,
            mouth: 'Jun',
            day: '19'
        },
        type: 'email',
        status: true,
        substatus: 2
    },
    {
        userId: 2,
        date: {
            id: 2,
            mouth: 'Jun',
            day: '25'
        },
        type: 'email',
        status: true,
        substatus: 0  
    },
    {
        userId: 1,
        date: {
            id: 3,
            mouth: 'Jun',
            day: '28'
        },
        type: 'email',
        status: false,
        substatus: 1
    },
    {
        userId: 2,
        date: {
            id: 4,
            mouth: 'Jun',
            day: '30'
        },
        type: 'email',
        status: false,
        substatus: 2  
    },
    {
        userId: 1,
        date: {
            id: 7,
            mouth: 'Jul',
            day: '07'
        },
        type: 'email',
        status: true,
        substatus: 1
    },
    {
        userId: 3,
        date: {
            id: 8,
            mouth: 'Jul',
            day: '15'
        },
        type: 'email',
        status: false,
        substatus: 1  
    },
    {
        userId: 4,
        date: {
            id: 9,
            mouth: 'Jul',
            day: '26'
        },
        type: 'email',
        status: false,
        substatus: 0
    },
    {
        userId: 1,
        date: {
            id: 11,
            mouth: 'Aug',
            day: '02'
        },
        type: 'email',
        status: false,
        substatus: 0  
    },
    {
        userId: 3,
        date: {
            id: 12,
            mouth: 'Aug',
            day: '05'
        },
        type: 'email',
        status: true,
        substatus: 0
    }
]