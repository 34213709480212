import React from 'react'
import styled from 'styled-components'

export const Button = (props) => {
    return (
        <ButtonStyled
            {...props}
            className={`${props.type === 'secondary' ? ' secondary' : ''}${props.type === 'min' ? ' min' : ''}${props.type === 'big' ? ' big' : ''}${props.type === 'light' ? ' light' : ''}`}
        >
            { props.children }
        </ButtonStyled>
    )
}

const ButtonStyled = styled.button`
    min-height: 40px;
    min-width: 120px;
    padding: 0 10px;
    background: #394577;
    border: none;
    border-radius: 11px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FAFAFA;
    cursor: pointer;
    outline: none;

    &.big {
        min-height: 50px;
        min-width: 150px;
        padding: 0 30px;
    }

    &.secondary {
        background: #FAFAFA;
        border: 2px solid #394577;
        border-radius: 11px;
        color: #394577;
    }

    &.min {
        min-width: 40px;
    }

    &.light {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #394577;
        background: #F5F7FF;
        border-radius: 11px;
    }

    &:hover {
        opacity: .9;
    }
`