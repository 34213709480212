import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import WrapClockF3F8FA from '../../assets/Widget/clockF3F8FA.svg'
import WrapClock from '../../assets/Widget/clock.svg'
import Line from '../../assets/Widget/line.svg'
import Red1 from '../../assets/Widget/red1.png'
import Red2 from '../../assets/Widget/red2.png'
import Arrow from '../../assets/Widget/arrow_v3.png'

export const Clock = ({data, color}) => {

    const [h1, setH1] = useState(0)
    const [h2, setH2] = useState(0)
    const [h3, setH3] = useState(0)
    const [h4, setH4] = useState(0)

    useEffect(() => {
        setH1(data.h1)
        setH2(data.h2)
        setH3(data.h3)
        setH4(data.h4)
    }, [data])

    return (
        <StyledClock>
            <div className="clock-wrap">
                <div
                    className="clock-data h1 animation" 
                    style={{height: `${h1 > 0 ? h1 : 0}%`}}
                >
                    <img src={Line} alt="line" />
                </div>
                <div className="clock-data h2 animation" style={{height: `${h2 - 2 > 0 ? h2 - 2 : 0}%`}}/>
                <div className="clock-data h3 animation" style={{height: `${h3 - 2 > 0 ? h3 - 2 : 0}%`}}/>
                <div className="clock-data h4 animation" style={{height: `${h4 - 2 > 0 ? h4 - 2 : 0}%`}}>
                    <img src={Red1} alt="red" className="red1" />
                    <img src={Red2} alt="red" className="red2" />
                    <div className="h4-bg" />
                </div>
                {
                    data.bottom && (
                        <div className="clock-data__label">
                            <img src={Arrow} alt="arrow" className="clock-data__label-img"/><span>{data.bottom}</span>
                        </div>
                    )
                }
                {
                    data.sale && (
                        <div className="clock-data__sale">
                            <img src={Arrow} alt="arrow" className="clock-data__label-img"/><span>Sale</span>
                        </div>
                    )
                }
                {
                    data.line && 
                        data.line.map((item, index) => {
                            return (
                                <div className="clock-data__labelsub" style={{...item.style}} key={index}>
                                    {item.arrow && <img src={Arrow} alt="arrow" className="clock-data__label-img"/> }
                                    <span>{item.value}</span>
                                </div>
                            )
                        })
                }

                <div className="clock-clock__face">
                    <img src={`${color}` === 'F3F8FA' ? WrapClockF3F8FA : WrapClock} alt="clock" />
                </div>
                <div className="clock-clock">
                    <img src={`${color}` === 'F3F8FA' ? WrapClockF3F8FA : WrapClock} alt="clock" />
                </div>
            </div>
        </StyledClock>
    )
}

const StyledClock = styled.div`
    height: 100%;
    display: flex;

    .clock {

        &-wrap {
            position: relative;
            height: 100%;
            background-color: #F2F4FF;
            display: flex;
        }

        &-clock {
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 100;

            &__face {
                height: 100%;
                opacity: 0;
                
                img {
                    height: 100%;
                    object-fit: contain;
                    z-index: 99;
                }
            }

            img {
                height: 100%;
                object-fit: contain;
                z-index: 99;
            }
        }

        &-data {
            position: absolute;
            bottom: 1px;
            height: 0;
            left: 3px;
            right: 3px;
            overflow: hidden;

            &__sale {
                position: absolute;
                display: flex;
                align-items: center;
                left: 70%;
                bottom: 50%;
                font-weight: bold;
                font-size: 11px;
                line-height: 15px;
                color: #25182E;
                z-index: 1000;
                transform: translateY(50%);

                &-img {
                    margin-right: 5px;
                }
            }

            &__labelsub {
                position: absolute;
                display: flex;
                align-items: center;
                left: 100%;
                white-space: nowrap;

                &-img {
                    margin-right: 5px;
                }
            }

            &__label {
                position: absolute;
                display: flex;
                align-items: center;
                left: 100%;
                bottom: 10%;
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
                color: #25182E;

                &-img {
                    margin-right: 5px;
                }
            }

            &.h1 {
                background: linear-gradient(180deg, #A4B6F8 0%, #5F80F5 59.9%, #486EF7 91.78%, #5F80F5 100%);
                z-index: 98;
                transition-delay: 1s;
                overflow: visible;

                img {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    transform: translateY(-50%)

                }
            }

            &.h2 {
                background: #32B76C;
                z-index: 97;
                transition-delay: 1.15s;
            }

            &.h3 {
                background: #F3CB3E;
                z-index: 96;
                transition-delay: 1.3s;
            }

            &.h4 {
                z-index: 95;
                transition-delay: 1.45s;

                .h4-bg {
                    background: #F26065;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    top: 5%;
                }
                
                .red1 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 200%;
                    animation: 4s linear 0s infinite alternate left;
                }

                .red2 {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 200%;
                    animation: 4s linear 0s infinite alternate right;
                }

                @keyframes left{
                    from {
                        left: 0;
                    }
                    to {
                        left: -100%;
                    }
                }

                @keyframes right{
                    from {
                        right: 0;
                    }
                    to {
                        right: -100%;
                    }
                }
            }
        }
    }

    .animation {
        transition: height 1s ease-in;
    }

`