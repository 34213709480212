import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Arrow from '../assets/icon/arrow_black.png'

export const ScoreLine = ({value, style}) => {

    const [val, setVal] = useState(0)

    useEffect(() => {
        setVal(value)
    }, [value])

    return (
        <Line style={style}>
            <div className="line-value" style={{left: `calc(${100/5 * val}% - 2px)`}}>
                <div>
                    {value}
                </div>
                <img src={Arrow} alt="value" />
            </div>
            <div className="line-main">
                <div className="line-main__light" />
                {/* <div className="line-main__green" /> */}
                <div className="line-main__yellow" />
                <div className="line-main__red" />
            </div>
            <div className="weak line-label">WEAK</div>
            <div className="strong line-label">STRONG</div>
            <div className="zero line-label">0</div>
            <div className="five line-label">5</div>
        </Line>
    )
}

const Line = styled.div`
    position: relative;

    .weak {
        left: 0;
        top: 120%;
    }

    .strong {
        right: 0;
        top: 120%;
    }

    .zero {
        left: 0;
        bottom: 120%;
    }

    .five {
        right: 0;
        bottom: 120%;
    }

    .line {

        &-label {
            position: absolute;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #989899;
        }

        &-value {
            bottom: 120%;
            position: absolute;
            left: 0;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #29335C;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: left 1s ease-in;
        }

        &-main {
            width: 100%;
            height: 7px;
            box-shadow: 0px 0px 4px rgba(41, 51, 92, 0.25);
            border-radius: 5px;
            border: 2px solid #fefefe;
            overflow: hidden;
            position: relative;
            margin: 15px 0;

            & > div {
                position: absolute;
                left: 0;
                height: 100%;
            }

            &__red {
                width: 40%;
                background: #F26065;
            }

            &__yellow {
                width: 60%;
                background: #F2C537;
            }

            &__green {
                width: 80%;
                background: #32B76C;
            }

            &__light {
                width: 100%;
                background: #D7F9E6;
            }
        }
    }

`