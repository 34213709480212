import React from 'react'
import styled from 'styled-components'

export const Switch = ({ status, setStatus }) => {

    return (
        <SwitchWrap>
            <div className={`wrap${status ? ' active' : ''}`} onClick={() => setStatus && setStatus(!status)}>
                <div className={`circle${status ? ' active' : ''}`}/>
            </div>
        </SwitchWrap>
    )
}

const SwitchWrap = styled.div`

    .wrap {
        height: 30px;
        width: 60px;
        border-radius: 25px;
        position: relative;
        box-sizing: border-box;
        background: #C1C1C1;
        transition: background .3s ease-in;

        &.active {
            background: #32B76C;
        }

        .circle {
            position: absolute;
            top: 3px;
            left: 3px;
            height: 22px;
            width: 22px;
            background: #FFFFFF;
            border: 0.5px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 8px rgba(0, 0, 0, 0.15);
            border-radius: 100px;
            transition: left .3s ease-in;

            &.active {
                left: calc(100% - 22px - 5px)
            }
        }
    }

`