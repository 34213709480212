import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { DropDown } from '../../../components/DropDown'
import { Paper } from '../../../components/Paper'
import { ScoreLineMin } from '../../../components/ScoreLineMin'
import { connect } from 'react-redux'
import { UserInfo } from '../../../components/UserInfo'
import Pic from '../../../assets/user.png'
import './style.scss'

import Add from '../../../assets/icon/add.png'
import Notification from '../../../assets/icon/notification.png'
import Arrow from '../../../assets/icon/arrow_line.png'
import InteractionList from './InteractionList'

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]

const DealsOverviewTable = (props) => {

    const [dropCurrent, setDropCurrent] = useState(data1[0])

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (
                <div className="row">
                    <div className="col jcsb">
                        <DropDown
                            data={data1}
                            current={dropCurrent}
                            setCurrent={setDropCurrent}
                        />
                        <div className="btn-group">
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Add} alt="add" />
                            </Button>
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Notification} alt="notification" />
                            </Button>
                            <UserInfo
                                pic={Pic}
                                name={'Eran Yessodi'}
                                style={{marginLeft: '10px'}}
                            />
                        </div>
                    </div>
                </div>
            )
        })
    }, [])  // eslint-disable-line

    return (
        <div className="deals-overview-table">
            <div className="row">
                <div className="col flex-grow-2" style={{display: 'flex'}}>
                    <Paper
                        title={'Interaction List'}
                        topNav={<Link 
                            to="/my-deals/overview" 
                            className="table-link" 
                            style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#C1C1C1',
                                textDecoration: 'none',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        > <img src={Arrow} alt="arrow" style={{marginRight: 5}}/> Back to Sonata (timeline view)</Link>}
                    >
                        <InteractionList />
                    </Paper>
                </div>
                <div className="col flex-grow-1">
                    <Paper
                        title='Deal Overview'
                    >
                        <div className="overview">
                            <div className="overview__elem">
                                <div className="overview__elem-label">
                                Deal status:
                                </div>
                                <div className="overview__elem-value">
                                Opportunity
                                </div>
                            </div>
                            <div className="overview__elem">
                                <div className="overview__elem-label">
                                    Formal stage:
                                </div>
                                <div className="overview__elem-value">
                                    Qualification
                                </div>
                            </div>
                            <div className="overview__elem">
                                <div className="overview__elem-label">
                                    Total touchpoints:
                                </div>
                                <div className="overview__elem-value">
                                    13 (9; 4) 
                                </div>
                            </div>
                            <div className="overview__elem">
                                <div className="overview__elem-label">
                                    Est. deal size:
                                </div>
                                <div className="overview__elem-value">
                                    $ 28,050 / year
                                </div>
                            </div>
                            <div className="overview__elem">
                                <div className="overview__elem-label">
                                    Buying zone:
                                </div>
                                <div className="overview__elem-value" style={{width: '100%', maxWidth: '200px'}}>
                                    <ScoreLineMin
                                        value={2.5}
                                    />
                                </div>
                            </div>
                        </div>
                    </Paper>
                    <Paper
                        title={'Company Overview'}
                    >
                        <table className="company-table">
                            <tbody>
                                <tr>
                                    <td className="overview__elem-label">
                                        Category
                                    </td>
                                    <td className="overview__elem-value">
                                        Business information
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Competitors
                                    </td>
                                    <td className="overview__elem-value">
                                        Insideview, DiscoverOrg
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Emp. Headcount
                                    </td>
                                    <td className="overview__elem-value">
                                        11,085
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Location
                                    </td>
                                    <td className="overview__elem-value">
                                        New York, USA
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Est. revenues
                                    </td>
                                    <td className="overview__elem-value">
                                        1.75 B USD (2019)
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Stock symbol
                                    </td>
                                    <td className="overview__elem-value">
                                        DNB
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Contacts
                                    </td>
                                    <td className="overview__elem-value">
                                        Doron Cohen (ISR)
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Subsidiaries
                                    </td>
                                    <td className="overview__elem-value">
                                        Hoovers, Avention
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Acquisitions
                                    </td>
                                    <td className="overview__elem-value">
                                        Lattice Engines
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Investments
                                    </td>
                                    <td className="overview__elem-value">
                                        N/A
                                    </td>
                                </tr>
                                <tr>
                                    <td className="overview__elem-label">
                                        Similar companies
                                    </td>
                                    <td className="overview__elem-value">
                                        Equifax, Infogroup
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col jcsb">
                    <div/>
                    <Link to="/my-deals/insights">
                        <Button type={'big'}>
                            Deal Insights
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        state: state
    })
)(DealsOverviewTable);