import React from 'react'

export default [
    {
        date: 'Jun.11, 9:28 am',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Lisa</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>Glad you could join us. Would you like a quick update or can we schedule
                that demo we discussed with Tom?</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Best regards,<br/>
                Eran Yessodi<br/>
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: 'Jun.12, 11:56 am',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi again Lisa,</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>Hope it’s ok - did you get my previous email?<br />
                I would like to schedule that demo so you could see our product’s full potential.</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Thanks again,<br/>
                Eran Yessodi<br/>
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: 'Jun.13, 5:48 pm',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Eran</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>I did get your email, couldn’t find the right time to respond.<br />
                A call towards the end of the month might work - Kim CCed here will help set it up</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Best,<br/>
                Lisa<br/>
            </div>
        </>
    },
    {
        date: 'Jun.14, 10:11 am',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>Great!<br />
                Looking forward to this.<br />
                Kim - nice to meet you, please let me know possible dates.
                </div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Thanks,<br />
                Eran.<br />
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: 'Jun.15, 03:22 pm',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Kim,</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>Did you get my email?<br />
                Would love to schedule for next week.</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Thanks,<br />
                Eran.<br />
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: 'Jun.18, 02:17 pm',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Lisa</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>I sent you an invite, did you receive it?<br />
                Agreed on a date with Kim (June 21st), I hope it still works?</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Thanks,<br/>
                Eran Yessodi<br/>
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: 'Jun.18, 08:05 am',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Lisa</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>I believe there was a misunderstanding, I was on the call and you did not show up. Probably a
                mixup in the calendar :)<br />
                Do you think we can have that call later today?</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Thanks,<br/>
                Eran Yessodi<br/>
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: 'Jun.23, 11:14 am',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Eran</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>I’m Sorry, there was indeed a mixup - had another meeting to attend.<br />
                We can have that call tomorrow if that works</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Best,<br />
                Lisa
            </div>
        </>
    },
    {
        date: '21 Dec 2019, 3:48 pm',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Eran</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>Lisa - I just sent you an invite (a few hours ago). <a href="/" style={{textDecoration: 'underline'}}>Hope you received it</a>. Looking forward to our zoom call on Wednesday.</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Regards,<br/>
                Eran Yessodi<br/>
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: '22 Dec 2019, 3:48 pm',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Eran</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>Lisa - I just sent you an invite (a few hours ago). <a href="/" style={{textDecoration: 'underline'}}>Hope you received it</a>. Looking forward to our zoom call on Wednesday.</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Regards,<br/>
                Eran Yessodi<br/>
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: '23 Dec 2019, 3:48 pm',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Eran</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>Lisa - I just sent you an invite (a few hours ago). <a href="/" style={{textDecoration: 'underline'}}>Hope you received it</a>. Looking forward to our zoom call on Wednesday.</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Regards,<br/>
                Eran Yessodi<br/>
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
    {
        date: '24 Dec 2019, 3:48 pm',
        text: <>
            <div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: '#25182E'
                }}>Hi Eran</div>
                <div style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#25182E'
                }}>Lisa - I just sent you an invite (a few hours ago). <a href="/" style={{textDecoration: 'underline'}}>Hope you received it</a>. Looking forward to our zoom call on Wednesday.</div>
            </div>
            <div style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#25182E',
            }}>
                Regards,<br/>
                Eran Yessodi<br/>
                Senior project manager<br/>
                {'//'}. Softarama.com<br/>
            </div>
        </>
    },
]