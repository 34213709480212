import React from 'react'
import styled from 'styled-components'

export default ({style, value}) => {
    return (
        <StyledCol style={style}>
            <div className="d9-value">
                ${value}
            </div>
        </StyledCol>
    )
}

const StyledCol = styled.div`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #5F80F5;
    width: 20px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    transform: translateX(-50%);

    .d9-value {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #25182E;
        position: absolute;
        bottom: calc(100% + 8px);
    }
`