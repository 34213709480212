import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Paper } from '../../../components/Paper'
import { Button } from '../../../components/Button'
import { DropDown } from '../../../components/DropDown'
import Widget from '../../../components/WidgetAudioV2/Widget'
import { connect } from 'react-redux'
import { UserInfo } from '../../../components/UserInfo'
import Pic from '../../../assets/user.png'
import './style.scss'

import Add from '../../../assets/icon/add.png'
import Notification from '../../../assets/icon/notification.png'
import Eran from '../../../assets/Widget/user2.png'
import Lisa from '../../../assets/Widget/user4.png'
import Voice from '../../../assets/Player/voice-full.mp3'
import Voice2 from '../../../assets/Player/voice2-full.mp3'
import Arrow from '../../../assets/icon/arrow_line_v2.png'
import Warning from '../../../assets/icon/warning.png'
import Check from '../../../assets/icon/check.png'

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]

const data = {
    user: [
        {
            name: 'Eran',
            pic: Eran
        },
        {
            name: 'Lisa',
            pic: Lisa
        }
    ],
    audio: [Voice, Voice2],
    chat: [
        {
            id: 0,
            text: 'So how do you see your sales cycle? What can be improved?'
        },
        {
            id: 1,
            text: 'Honestly, we`re using a lot of tools, but in the end it`s more of an intuition thing you know.'
        },
        {
            id: 0,
            text: 'Trust me I know.'
        },
        {
            id: 1,
            text: 'I wish there was a way we could know exactly what are the issues at hand and how to deal with them. I spend an unbelievable amount of time listening to cold calls with customers just searching for the needle in the haystack'
        },
        {
            id: 0,
            text: 'Well actually, that`s exactly the reason I`m calling you in for.'
        },
        {
            id: 0,
            text: 'So how do you see your sales cycle? What can be improved?'
        },
        {
            id: 1,
            text: 'Honestly, we`re using a lot of tools, but in the end it`s more of an intuition thing you know.'
        },
        {
            id: 0,
            text: 'Trust me I know.'
        },
        {
            id: 1,
            text: 'I wish there was a way we could know exactly what are the issues at hand and how to deal with them. I spend an unbelievable amount of time listening to cold calls with customers just searching for the needle in the haystack'
        },
        {
            id: 0,
            text: 'Well actually, that`s exactly the reason I`m calling you in for.'
        },
    ]
}

const Zoom = (props) => {
    
    const [dropCurrent, setDropCurrent] = useState(data1[0])

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (<>
                <div className="row">
                    <div className="col jcsb">
                        <DropDown
                            data={data1}
                            current={dropCurrent}
                            setCurrent={setDropCurrent}
                        />
                        <div className="btn-group">
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Add} alt="add" />
                            </Button>
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Notification} alt="notification" />
                            </Button>
                            <UserInfo
                                pic={Pic}
                                name={'Eran Yessodi'}
                                style={{marginLeft: '10px'}}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{display: 'flex'}}>
                        <Link to="/my-deals">
                            <div className="widget-layout__header">
                                {
                                    data.user.map((item, index) => {
                                        return <img
                                            src={item.pic}
                                            alt="user"
                                            key={index}
                                            style={{transform: `translateX(-${index * 5}px)`}}
                                            className="widget-layout__header-pic"
                                        />
                                    })
                                }
                                <div className="widget-layout__header-text">View Relationship</div>
                                <img src={Arrow} alt="arrow" />
                            </div>
                        </Link>
                    </div>
                </div>
            </>)
        })
    }, []) // eslint-disable-line

    return (<>
        <div className="row zoom">
            <div className="col">
                <Paper style={{padding: 0}}>
                    <Widget
                        data={data}
                        transcriptionVisible={true}
                    />
                </Paper>
            </div>
        </div>
        <div className="row zoom">
            <div className="col flex-grow-1" style={{display: 'flex'}}>
                <Paper
                    title="Interaction Breakdown"
                >
                    {
                        data2.map((item, index) => {
                            return (
                                <div className="breakdown" key={index}>
                                    <div className="breakdown-label">
                                        {item.title}
                                    </div>
                                    <div className="breakdown-value">
                                        {item.value}
                                    </div>
                                </div>
                            )
                        })
                    }
                </Paper>
            </div>
            <div className="col flex-grow-2" style={{display: 'flex'}}>
                <Paper
                    title="Interaction Notes"
                >
                    <table className="notes">
                        <tbody>
                            {
                                data3.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="notes-status">
                                                <div className="notes-status__fage">
                                                    <div className="notes-status__fage-line" />
                                                    <img 
                                                        src={item.status ? Check : Warning} 
                                                        alt="status"
                                                        className="fage"
                                                    />
                                                </div>
                                                
                                            </td>
                                            
                                            <td className="notes-label">
                                                {item.title}
                                            </td>
                                            <td className="notes-value">
                                                {item.value}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    
                </Paper>
            </div>
        </div>
        <div className="row">
            <div className="col"  style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Link to="/zoom/insights">
                    <Button type={"big"}>
                        Interaction Insights
                    </Button>
                </Link>
                
            </div>
        </div>
    </>)
}

const data2 = [
    {
        title: 'Interaction',
        value: '14'
    },
    {
        title: 'Type',
        value: 'Zoom'
    },
    {
        title: 'Date',
        value: 'Dec 26 2019'
    },
    {
        title: 'Time started',
        value: '09:46 am (EST)'
    },
    {
        title: 'Time ended',
        value: '10:05 am (EST)'
    },
    {
        title: 'Duration',
        value: '19:03 min'
    }
]

const data3 = [
    {
        status: false,
        title: 'Call duration was shorter than benchmark',
        value: '19:03 min vs 42 min'
    },
    {
        status: false,
        title: 'Talk ratio above benchmark',
        value: '68% vs 43%'
    },
    {
        status: false,
        title: 'Your pitch was much higher than normal',
        value: '25% above base line'
    },
    {
        status: true,
        title: 'You applied a real-time tip succesfully',
        value: 'View segment'
    }
]

export default connect(
    state => ({
        state: state
    })
)(Zoom);