import React from 'react'
import { Link } from 'react-router-dom'
import './auth.scss'
import {
    TextField
} from '@material-ui/core'
import { Button } from '../../components/Button'

export default () => {

    return (
        <div className="auth">
            <h3 className="auth__title">
                Forgot your password?
            </h3>
            <h5 className="auth__subtitle">
                We’ll help you reset it and get back on track.
            </h5>
            <TextField
                className="auth__elem"
                label="Email Address"
                variant="outlined"
            />
            <div className="auth__elem btn-group">
                <Link to="/auth/password"> 
                    <Button>
                        Send mail
                    </Button>
                </Link>
                <Link to="/auth/login"> 
                    <Button
                        type="secondary"
                    >
                        Back
                    </Button>
                </Link>
            </div>
        </div>
    )
}