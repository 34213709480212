import React from 'react'
import styled from 'styled-components'
import Warning from '../../assets/Widget/warning.svg'

export default ({ refEl, height, width }) => {

    return (
        <Line 
            style={{
                width: '100%',
                height: '50%',

            }}
        >
            {
                width.map((item, index) =>{
                    return (
                        <image 
                            href={Warning}
                            x={item - 14} 
                            y={height - 20} 
                            width="14" 
                            height="14"
                            key={index} 
                        />
                    )
                })
            }
            {
                [...new Array(200)].map((item, index) => {
                    return (
                        <circle cx={index * 8} cy={height} r="1" fill="#BDCAFB" key={index} />
                    )
                })
            }
        </Line>
    )
}

const Line = styled.svg`

`