import React from 'react'
import styled from 'styled-components'

export default () => {
    return (
        <Line>
            <div className="one-elem" />
            <div className="one-elem" />
            <div className="one-elem" />
            <div className="one-elem" />
            <div className="one-elem" />
        </Line>
    )
}

const Line = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;

    .one-elem {
        width: 100%;
        height: 1px;
        background: #F2F7FA;
        margin: 5px 0;
    }
`