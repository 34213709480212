import React from 'react'
import styled from 'styled-components'

import g32B76C from '../assets/icon/32B76C.png'
import yF3CB3D from '../assets/icon/F3CB3D.png'
import rF26065 from '../assets/icon/F26065.png'

export const LineGrafic3 = ({ data }) => {
    return (
        <Grafic>
            <div className="v2">
                <div className="v2-wrap">
                    {
                        data.map((item, index) => {
                            return (
                                <div 
                                    key={index}
                                    className="v2-elem"
                                    style={{
                                        left: `${100/15*item.value}%`
                                    }}
                                >
                                    {item.value}
                                    <img src={
                                        item.color === '32B76C'
                                            ? g32B76C
                                            : item.color === 'F3CB3D'
                                                ? yF3CB3D
                                                : item.color === 'F26065'
                                                    ? rF26065
                                                    : rF26065
                                    } alt="pic" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="v2-title" style={{left: `${100/15*0}%`}}>0</div>
                <div className="v2-title" style={{left: `${100/15*5}%`}}>5</div>
                <div className="v2-title" style={{left: `${100/15*10}%`}}>10</div>
                <div className="v2-title" style={{left: `${100/15*15}%`}}>15</div>
            </div>
        </Grafic>
    )
}

const Grafic = styled.div`
    margin: 0 20px;

    .v2 {
        margin: 45px 0 35px;
        padding-bottom: 30px;
        border-bottom: 1px solid #DDDDDD;
        position: relative;

        &-title {
            position: absolute;
            top: 105%;
            transform: translateX(-50%);
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #989899;
        }

        &-elem {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            bottom: 110%;
            transform: translateX(-50%);
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #25182E;

            img {
                filter: drop-shadow(0px 0px 4px rgba(50, 47, 109, 0.25));
            }
        }

        &-wrap {
            height: 7px;
            width: 100%;
            background: #F2F4FF;
            border-radius: 5px;
            background: #5F80F5;
            position: relative;
        }
    }
`