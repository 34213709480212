import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Arrow from '../../assets/Widget/arrow.png'
import Phone from '../../assets/Widget/phone.png'
import BG from '../../assets/Widget/bg_phone.png'


export default ({setStep, phone, back}) => {
    return (<StyledArrow
        style={{
            right: `${!back && '-10%'}`, 
            left: `${back && '-5%'}` 
        }}
    >
        {
            phone && (
                <Link to="/zoom">
                    <div className="phone">
                        <img src={BG} alt="BG" />
                        <img src={Phone} alt="Phone" />

                        <div className="phone-tooltip">
                            Click to see interaction details
                        </div>
                    </div>

                </Link>
            )
        }
        
        <div 
            className="arrow" 
            onClick={setStep} 
            style={{
                transform: `${back && 'rotate(180deg)'}`,
            }}>
            <img src={Arrow} alt="arrow" />
        </div>
    </StyledArrow>)
}

const StyledArrow = styled.div`
    position: absolute;
    top: 42%;
    z-index: 100;
    display: flex;
    align-items: center;

    .phone {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 50px;

        img {
            position: absolute;
        }


        &:hover {
            .phone-tooltip {
                display: block;
            }
        }

        &:active {
            .phone-tooltip {
                color: #394577;
            }
        }

        &-tooltip {
            width: 75px;
            display: none;
            position: absolute;
            top: 120%;
            left: -50%;
            font-size: 9px;
            padding: 5px 8px;
            border-radius: 5px;
            background-color: #F2F7FA;
            border: 2px solid #FFFFFF;
            box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);
            z-index: 10000000000;
            text-align: center;
            color: #394577;
        }
    }
    
    .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 25px rgba(50, 47, 109, 0.2);
        background: #5F80F5;
        border-radius: 25px;
        height: 33px;
        width: 33px;
        cursor: pointer;
    }
`