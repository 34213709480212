import Account from '../../assets/icon/home.png'
import Insights from '../../assets/icon/insights.png'
import Deals from '../../assets/icon/deals.png'
import Stats from '../../assets/icon/stats.png'
import Account2 from '../../assets/icon/home_2.png'
import Insights2 from '../../assets/icon/insights_2.png'
import Deals2 from '../../assets/icon/deals_2.png'
import Stats2 from '../../assets/icon/stats_2.png'
import Support from '../../assets/icon/support.png'
import Powered from '../../assets/icon/powered.png'
import Learning from '../../assets/icon/learning.png'

export const MenuConfig = {
    top: [
        {
            label: 'Account & settings',
            icon: [Account, Account2],
            link: '/account',
            active: 'account'
        },
        {
            label: 'My Stats',
            icon: [Stats, Stats2],
            link: '/my-stats',
            active: 'my-stats'
        },
        {
            label: 'My Deals',
            icon: [Deals, Deals2],
            link: '/my-deals',
            active: 'my-deals'
        },
        {
            label: 'My Insights',
            icon: [Insights, Insights2],
            link: '/my-insights',
            active: 'my-insights'
        },
    ],
    bottom: [
        {
            label: 'Support',
            icon: [Support, Support],
            link: 'https://substrata.me/support'
        },
        {
            label: 'Learning center',
            icon: [Learning, Learning],
            link: 'https://www.substrata.me/learning-center/'
        },
        {
            label: 'Powered by Substrata',
            icon: [Powered, Powered],
            link: 'https://substrata.me'
        },
    ]
}

export const AdminMenuConfig = {
    top: [
        {
            label: 'Account & settings',
            icon: [Account, Account2],
            link: '/admin/account',
            active: 'account',
        },
        {
            label: 'Stats',
            icon: [Stats, Stats2],
            link: '/admin/stats',
            active: 'stats',
        },
        {
            label: 'Deals',
            icon: [Deals, Deals2],
            link: '/admin/deals',
            active: 'deals',
        },
        {
            label: 'Insights',
            icon: [Insights, Insights2],
            link: '/admin/insights',
            active: 'insights',
        },
    ],
    bottom: [
        {
            label: 'Support',
            icon: [Support, Support],
            link: 'https://substrata.me/support'
        },
        {
            label: 'Learning center',
            icon: [Learning, Learning],
            link: 'https://www.substrata.me/learning-center/'
        },
        {
            label: 'Powered by Substrata',
            icon: [Powered, Powered],
            link: 'https://substrata.me'
        },
    ]
}