import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import RedArrow from '../../../assets/icon/red_arrow.png'
import Info from '../../../assets/icon/info.png'
// import Corona from '../../../assets/icon/corona.png'
import Mail from '../../../assets/icon/mail.png'
import User1 from '../../../assets/Widget/user1.png'
import User2 from '../../../assets/Widget/user2.png'
import User4 from '../../../assets/Widget/user4.png'
import User5 from '../../../assets/Widget/user3.png'
import Warning from '../../../assets/icon/warning.png'
import Corona from '../../../assets/icon/corona.png'
import Calendar from '../../../assets/Widget/calendar_v2.png'

// const date = ['JUN 19 2019', 'JUN 25 2019', 'JUN 28 2019', 'JUN 30 2019', 'JUL 07 2019', 'JUL 15 2019']

// const user = [User1, User3, User2, User3, User2, User4]

const data = [
    {
        date: 'JUN 11 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'JUN 12 2019',
        pic: User2,
        type: 'mail',
        info: 'error'
    },
    {
        date: 'JUN 13 2019',
        pic: User4,
        type: 'mail',
        info: ''
    },
    {
        date: 'JUN 14 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'JUN 15 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'JUN 17 2019',
        pic: User2,
        type: 'calendar',
        info: ''
    },
]

const data_second = [
    {
        date: 'JUN 19 2019',
        pic: User1,
        type: 'mail',
        info: 'corona'
    },
    {
        date: 'JUN 25 2019',
        pic: User5,
        type: 'mail',
        info: 'corona'
    },
    {
        date: 'JUN 28 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'JUN 30 2019',
        pic: User5,
        type: 'mail',
        info: ''
    },
    {
        date: 'JUL 07 2019',
        pic: User2,
        type: 'mail',
        info: 'corona'
    },
    {
        date: 'JUL 15 2019',
        pic: User4,
        type: 'mail',
        info: ''
    },
]

export default ({ list }) => {

    return (
        <div className="table-list">
            <div className="table-list__row">
                <div className="table-list__row-col">
                    Type
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    Who
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col start">
                    Date
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    #
                </div>
                <div className="table-list__row-col start">
                    Breakdown
                </div>
                <div className="table-list__row-col">
                    <img src={Info} alt="corona" />
                </div>
            </div>
            {/* {
                [true,true,false,false,true,false].map((item, index) => {
                    return (
                        <div className="table-list__row table-list__row-content" key={index}>
                            <div className="table-list__row-col">
                                <img src={Mail} alt="mail" />
                            </div>
                            <div className="table-list__row-col">
                                <img src={user[index]} alt="user" className="user-pic" />
                            </div>
                            <div className="table-list__row-col start">
                                {date[index]}
                            </div>
                            <div className="table-list__row-col">
                                {index + 1}
                            </div>
                            <div className="table-list__row-col start">
                                <Link to={`/my-deals/email/${index}`} className="table-link">View</Link>
                            </div>
                            <div className="table-list__row-col">
                                {
                                    item && <img src={Corona} alt="corona" />
                                }
                            </div>
                            <div className="line" />
                        </div>
                    )
                })
            } */}
            {
                (list === 'Main contacts' ? data : data_second).map((item, index) => {
                    return (
                        <div className="table-list__row table-list__row-content" key={index}>
                            <div className="table-list__row-col">
                                {
                                    item.type === 'mail'
                                        ? <img src={Mail} alt="mail" />
                                        : item.type === 'calendar' 
                                            ? <img src={Calendar} alt="calendar" />
                                            : null
                                }
                            </div>
                            <div className="table-list__row-col">
                                <img src={item.pic} alt="user" className="user-pic" />
                            </div>
                            <div className="table-list__row-col start">
                                {item.date}
                            </div>
                            <div className="table-list__row-col">
                                {index + 1}
                            </div>
                            <div className="table-list__row-col start">
                                <Link to={`/my-deals/email/${index + 1}`} className="table-link">View</Link>
                            </div>
                            <div className="table-list__row-col">
                                {
                                    item.info === 'error' 
                                        ? <img src={Warning} alt="corona" />
                                        : item.info === 'corona' 
                                            ? <img src={Corona} alt="corona" />
                                            : null
                                }
                            </div>
                            <div className="line" />
                        </div>
                    )
                })
            }
        </div>
    )
}