import React from 'react'
import styled from 'styled-components'

export default ({data, style}) => {
    return (
        <StyledTimeLine style={{padding: `30px ${100 / data[data.length - 1].date.id / 2 - 0.75}%`, ...style}}>
            <div className="line">
                {
                    data.map((item, index, arr) => {
                        return item.date.day && <Point
                            data={item}
                            arr={arr}
                            key={index}
                        />
                    })
                }
            </div>
        </StyledTimeLine>
    )
}

const StyledTimeLine = styled.div`
    width: calc(90% - 100px);
    box-sizing: border-box;

    .line {
        width: 100%;
        height: 3px;
        background: #BDCAFB;
        border: 3px solid white;
        border-radius: 5px;
        position: relative;
        display: flex;
        align-items: center;

        .point {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 10px;
            height: 10px;
            background: #BDCAFB;
            border: 3px solid white;
            border-radius: 15px;
            transform: translateX(-75%);
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 15px;
            text-align: center;
            color: #394577;

            &-mouth, &-day {
                margin: 10px
            }
        }
    }

`

const Point = ({data, arr}) => {
    return (
        <div className="point" style={{left: `${100 / (arr[arr.length - 1].date.id - 1) * (data.date.id - 1)}%`}}>
            <div className="point-mouth">{data.date.mouth}</div>
            <div className="point-day">{data.date.day}</div>
        </div>
    )
}