import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './auth.scss'
import {
    TextField
} from '@material-ui/core'
import { Button } from '../../components/Button'
import { TopLink } from '../../components/TopLink'
import axios from 'axios'

export default () => {

    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [website, setWebsite] = useState('')
    const [errors, setErrors] = useState({})
    const [send, setSend] = useState(false)

    const [modal, setModal] = useState(false)

    const setEr = (arr) => {
        let obj = {}
        arr.map((item) => {
            let key = item.into.split('.')
            obj[key[key.length - 1]] = item.message
            return null
        })
        setErrors(obj)
    }

    const signUp = () => {
        const formData = new FormData()
        formData.append('Name', name)
        formData.append('LastName', lastName)
        formData.append('email-808', email)
        formData.append('tel-290', phone)
        formData.append('CompanyName', company)
        formData.append('website', website)
        setSend(true)
        axios.post('https://www.substrata.me/wp-json/contact-form-7/v1/contact-forms/1340/feedback', formData)
            .then(res => {
                console.log(res)
                res && res.data && res.data.invalid_fields && setEr(res.data.invalid_fields)
                if (res.data.status === "mail_sent") {
                    setModal(true)
                }
                setSend(false)
            })
            .catch(({ response }) => {
                setSend(false)
                console.log(response)
                setEr(response.data.invalid_fields)
            })
    }

    return (<>
        <TopLink
            text={'Already have an account?'}
            textButton={'SIGN IN'}
            link={'/auth/login'}
        />

        {
            modal && (
                <div className="modal" onClick={(event) => {
                    event.preventDefault();
                        if(event.target === event.currentTarget) {
                            setModal(false)
                        }
                    }}>
                    <div className="modal-window">
                        Thank you for your message. It has been sent.
                        <Link to="/auth/login">
                            <Button onClick={signUp}>
                                Go to Sign In
                            </Button>
                        </Link>
                        
                    </div>
                </div>
            )
        }

        
        
        <div className="auth">
            <h3 className="auth__title" style={{'margin-bottom': '22px'}}>
                Almost There
            </h3>
            <TextField
                className="auth__elem"
                label="Name"
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
            />
            {
                errors.Name && (
                    <div className="auth__elem-valid">{errors.Name}</div>
                )
            }

            <TextField
                className="auth__elem"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
            />
            {
                errors.LastName && (
                    <div className="auth__elem-valid">{errors.LastName}</div>
                )
            }

            <TextField
                className="auth__elem"
                label="Email"
                variant="outlined"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            {
                errors['email-808'] && (
                    <div className="auth__elem-valid">{errors['email-808']}</div>
                )
            }
            
            <TextField
                className="auth__elem"
                label="Phone Number"
                variant="outlined"
                value={phone}
                onChange={e => setPhone(e.target.value)}
            />
            {
                errors['tel-290'] && (
                    <div className="auth__elem-valid">{errors['tel-290']}</div>
                )
            }
            
            <TextField
                className="auth__elem"
                label="Company Name"
                variant="outlined"
                value={company}
                onChange={e => setCompany(e.target.value)}
            />
            {
                errors.CompanyName && (
                    <div className="auth__elem-valid">{errors.CompanyName}</div>
                )
            }
            
            <TextField
                className="auth__elem"
                label="Website"
                variant="outlined"
                value={website}
                onChange={e => setWebsite(e.target.value)}
            />
            {
                errors.website && (
                    <div className="auth__elem-valid">{errors.website}</div>
                )
            }
            
            <div className="auth__elem btn-group">
                <Button onClick={signUp} disabled={send}>
                    Sign up
                </Button>
            </div>
        </div>
    </>)
}