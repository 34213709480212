import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import user2 from '../../assets/Widget/user2.png'
import user4 from '../../assets/Widget/user4.png'
import email from '../../assets/Widget/mail.png'
import open_mail from '../../assets/Widget/mail_open.png'
import calendar from '../../assets/Widget/calendar.png'
import sleep from '../../assets/Widget/sleep_v2.png'
import question from '../../assets/Widget/question.png'
import phone from '../../assets/Widget/phone.png'
import video from '../../assets/Widget/video.png'
import check from '../../assets/Widget/check.png'
import warning from '../../assets/Widget/warning.png'
import time from '../../assets/Widget/time.png'
import BG from '../../assets/Widget/bg_phone.png'
import Line from './Line'
import DotLine from './DotLine'
import Filter from './Filter'
import Arrow from './Arrow'
import TimeLine from './TimeLine'

export default (props) => {

    const { setVisible, visible } = props

    const [id, setId] = useState(false)
    const [keyg, setKey] = useState(0)

    useEffect(() => {
        const href = window.location.href.split('/')
        setId(isNaN(parseInt(href[href.length - 1])) ? false : parseInt(href[href.length - 1]))
    }, [window, window.location, window.location.href]) // eslint-disable-line

    const position = x => {
        return currentData.length
            ? 100 / currentData[currentData.length - 1].date.id * x - 100 / currentData[currentData.length - 1].date.id / 2
            : 0
    }
    // const widthBlock = x => 100 / dataEmail[dataEmail.length - 1].date.id

    const [step, setStep] = useState(1)

    const [timeLineVisible, setTimeLineVisible] = useState(true)

    const [currentData, setCurrentData] = useState(step === 1 
        ? dataEmail
            : step === 2
            ? dataEmail_2
                : [])

    useEffect(() => {
        setKey(keyg + 1)
        setCurrentData(step === 1 
            ? dataEmail
                : step === 2
                ? dataEmail_2
                    : [])
    }, [step]) // eslint-disable-line

    return (
        <div className="widgetEmail">
            <div className="fageBlock" />
            <div className="left-side">
                {
                    data.map((item, index) => {
                        return (
                            <div className="left-side__user" key={index} style={{opacity: item.date === 0 ? 1 : 0}}>
                                <div className="left-side__user-photo">
                                    <img src={item.photo} alt="pic" />
                                    {item.mailNum ? <div className="mailnum">+{item.mailNum}</div> : null}
                                </div>
                                <div className="left-side__user-name">
                                    {item.name}
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="main" style={{marginLeft: `${step === 2 ? '5%' : '0'}`, width: `${step === 2 ? 'calc(95% - 100px)' : 'calc(90% - 100px)'}`}}>

            {
                step === 1 && <Arrow phone={true} setStep={() => { setStep(2) } }/>
            }
            {
                step === 2 && <Arrow back={true} setStep={() => { setStep(1) } }/>
            } 
            
            {
                    step === 2 && dataEmail_2.map((item, index) => {
                        return dataEmail_2[index + 1] ? (
                            <DotLine
                                step={step}
                                key={index}
                                current={item}
                                next={dataEmail_2[index + 1]}
                                lenghtX={dataEmail_2[dataEmail_2.length - 1].date.id}
                                lenghtY={data.length}
                            />
                        ) : null
                    })
                }
                {
                    step === 1 && dataEmail.map((item, index) => {
                        return dataEmail[index + 1] ? (
                            <DotLine
                                step={step}
                                key={index}
                                current={item}
                                next={dataEmail[index + 1]}
                                lenghtX={dataEmail[dataEmail.length - 1].date.id}
                                lenghtY={data.length}
                            />
                        ) : null
                    })
                }
                {
                    data.map((item, index) => {
                        return (
                            <div className="main__user" key={index} style={{height: `${100/data.length}%`}}>
                                <Line />
                                {
                                    currentData.map((elem, index2) => {
                                        const content = (key) => { 
                                            return (
                                                <div 
                                                    className="main__email" 
                                                    key={`${keyg}-${key}`}
                                                    style={{
                                                        left: `${position(elem.date.id)}%`,
                                                        animationDelay: `${position(elem.date.id)/50}s`,
                                                        top: elem.position === 'bottom' && '100%'
                                                        
                                                    }}
                                                >
                                                    <div
                                                        className="main__email-anim"
                                                        style={{
                                                            background: elem.type === 'email' && (id === false || id !== elem.mail)
                                                            ? '#394577'
                                                            : elem.type === 'calendar'
                                                                ? '#9DB1FA'
                                                                : elem.type === 'sleep'
                                                                    ? '#DBDDE5'
                                                                    : ((elem.type === 'phone') || (elem.type === 'video')) && (step === 1)
                                                                        ? '#32B76C'
                                                                        : elem.type === 'email' && id === elem.mail
                                                                            ? '#FFFFFF'
                                                                            : null
                                                        }}
                                                    >
                                                        {
                                                            elem.type === 'email' && (id === false || id !== elem.mail)
                                                                ? <img src={email} alt="mail" />
                                                                : elem.type === 'calendar'
                                                                    ? <img src={calendar} alt="calendar" />
                                                                    : elem.type === 'sleep'
                                                                        ? <img src={sleep} alt="sleep" />
                                                                        : elem.type === 'video'
                                                                            ? <img src={video} alt="video" style={{height: '10px', width: '17px'}}/>
                                                                            : elem.type === 'phone'
                                                                                ? <img src={phone} alt="phone" />
                                                                                : elem.type === 'email'  && id === elem.mail
                                                                                    ? <img src={open_mail} alt="open-mail" />
                                                                                    : null
                                                        }
                                                        {elem.status === 'check'
                                                            ? <img src={check} alt="check" className="main__email-top" style={{height: 30, width: 30, top: -15, right: -15}}/>
                                                            : elem.status === 'question'
                                                                ? <img src={question} alt="question" className="main__email-top" />
                                                                : elem.status === 'warning'
                                                                    ? <img src={warning} alt="warning" className="main__email-top" />
                                                                    : null
                                                        }

                                                        {
                                                            ((elem.type === 'video') || (elem.type === 'phone')) && (step === 2)
                                                                ? <img src={BG} alt="BG" className="main__email-circle"/>
                                                                : null
                                                        }
                                                    </div>
                                                    
                                                    <div className="main__email-tooltip">
                                                        Click to see interaction details
                                                    </div>
                                                 
                                                </div>
                                            )
                                        }

                                        return item.id === elem.userId 
                                            ? elem.mail 
                                                ? <Link
                                                    to={`/my-deals/email/${elem.mail}`}
                                                    key={`${index}${index2}`}
                                                >
                                                    { content(`${index}${index2}`) }
                                                </Link>
                                                : elem.type === 'phone'
                                                    ? <Link
                                                        to={`/zoom`}
                                                        key={`${index}${index2}`}
                                                    >
                                                        { content(`${index}${index2}`) }
                                                    </Link>
                                                    : elem.type === 'video'
                                                        ? <Link
                                                            to={`/video`}
                                                            key={`${index}${index2}`}
                                                        >
                                                            { content(`${index}${index2}`) }
                                                        </Link>
                                                        : content(`${index}${index2}`)
                                            : null
                                    })
                                }
                                {   
                                    step === 1 &&
                                    dataEmail2.map((elem, index2) => {
                                        const content = (key) => (
                                            <div 
                                                key={`${keyg}-${key}`}
                                                className="main__email" 
                                                style={{
                                                    zIndex: 49,
                                                    left: `${position(elem.date.id)}%`,
                                                    top: `${elem.position === 'top' ? '0' : null}`,
                                                    animationDelay: `${position(elem.date.id)/50}s`
                                                }}
                                                >
                                                    <div
                                                        className="main__email-anim"
                                                        style={{

                                                            background: elem.type === 'email'
                                                            ?  '#394577'
                                                            : elem.type === 'calendar'
                                                                ? '#9DB1FA'
                                                                : elem.type === 'sleep'
                                                                    ? '#DBDDE5'
                                                                    : elem.type === 'phone'
                                                                        ? '#32B76C'
                                                                        : elem.type === 'open-mail'
                                                                            ? '#FFFFFF'
                                                                            : null
                                                        }}
                                                    >
                                                        {
                                                            elem.type === 'email'
                                                                ?  <img src={email} alt="mail" />
                                                                : elem.type === 'calendar'
                                                                    ? <img src={calendar} alt="calendar" />
                                                                    : elem.type === 'sleep'
                                                                        ? <img src={sleep} alt="sleep" />
                                                                        : elem.type === 'open-mail'
                                                                            ? <img src={open_mail} alt="open-mail" />
                                                                            : elem.type === 'phone'
                                                                                ? <img src={phone} alt="phone" />
                                                                                : null
                                                        }

                                                        {elem.status === 'check'
                                                            ? <img src={check} alt="check" className="main__email-top" style={{height: 30, width: 30, top: -15, right: -15}}/>
                                                            : elem.status === 'question'
                                                                ? <img src={question} alt="question" className="main__email-top" />
                                                                : elem.status === 'warning'
                                                                    ? <img src={warning} alt="warning" className="main__email-top" />
                                                                    : null
                                                        }
                                                    </div>

                                                    { elem.tooltip &&
                                                        <div className="main__email-tooltip">
                                                            Click to see interaction details
                                                        </div>
                                                    }

                                                </div>
                                        )
                                        return item.id === elem.userId 
                                            ? elem.mail 
                                                ? <Link
                                                    to={`/my-deals/email/${elem.mail}`}
                                                    key={`${index}${index2}`}
                                                >
                                                    { content(`${index}${index2}`) }
                                                </Link>
                                                : elem.type === 'phone'
                                                    ? <Link
                                                        to={`/zoom`}
                                                        key={`${index}${index2}`}
                                                    >
                                                        { content(`${index}${index2}`) }
                                                    </Link>
                                                    : elem.type === 'video'
                                                        ? <Link
                                                            to={`/video`}
                                                            key={`${index}${index2}`}
                                                        >
                                                            { content(`${index}${index2}`) }
                                                        </Link>
                                                        : content(`${index}${index2}`)
                                            : null
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        
            <Filter 
                setTimeLineVisible={setTimeLineVisible}
                setVisible={setVisible}
                visible={visible}
            />

            {
                timeLineVisible && (
                    <div className="time-line">
                        <div className="time-line__label">
                            <img src={time} alt="time" />
                            <span className="time-line__label-text">{step === 1 ? 'Nov.2020' : 'Dec.2020'}</span>
                        </div>
                        {
                            currentData.length && (
                                <TimeLine
                                    style={{
                                        marginLeft: step === 2 && '5%',
                                        width: step === 2 ? 'calc(95% - 100px)' : 'calc(90% - 100px)'
                                    }}
                                    data={currentData}
                                />
                            )
                        }
                        
                    </div>
                )
            }
        </div>
    )
}

const data = [
    {
        id: 0,
        name: 'Eran',
        photo: user2,
        mailNum: 0,
        date: 0
    },
    {
        id: 1,
        name: 'Lisa',
        photo: user4,
        mailNum: 0,
        date: 0
    },
]

const dataEmail = [
    {
        userId: 0,
        date: {
            id: 1,
            mouth: 'Mon',
            day: '11'
        },
        type: 'email',
        status: '',
        mail: 1
    },
    {
        userId: 0,
        date: {
            id: 2,
            mouth: 'Tue',
            day: '12'
        },
        type: 'email',
        status: 'warning',
        mail: 2
    },
    {
        userId: 1,
        date: {
            id: 3,
            mouth: 'Wed',
            day: '13'
        },
        type: 'email',
        status: '',
        mail: 3
    },
    {
        userId: 0,
        date: {
            id: 4,
            mouth: 'Thu',
            day: '14'
        },
        type: 'email',
        status: '',
        mail: 4
    },
    {
        userId: 0,
        date: {
            id: 5,
            mouth: 'Fri',
            day: '15'
        },
        type: 'email',
        status: '',
        mail: 5
    },
    {
        userId: 0,
        date: {
            id: 7,
            mouth: 'Fri',
            day: '15'
        },
        type: 'calendar',
        status: 'question'
    },
    {
        userId: 0,
        date: {
            id: 8,
            mouth: 'Mon',
            day: '18'
        },
        type: 'email',
        status: 'warning',
        mail: 6
    },
    {
        userId: 1,
        date: {
            id: 9,
            mouth: 'Mon',
            day: '18'
        },
        type: 'calendar',
        status: 'check'
    },
    {
        userId: 0,
        date: {
            id: 10
        },
        type: 'sleep',
        status: '',
        position: 'bottom'
    },
    {
        userId: 0,
        date: {
            id: 11,
            mouth: 'Mon',
            day: '25'
        },
        type: 'phone',
        status: ''
    },
    {
        userId: 0,
        date: {
            id: 12,
            mouth: 'Tue',
            day: '26'
        },
        type: 'email',
        status: '',
        mail: 7
    },
    {
        userId: 1,
        date: {
            id: 13,
            mouth: 'Wed',
            day: '27'
        },
        type: 'email',
        status: '',
        mail: 8
    },
]

const dataEmail2 = [
    {
        userId: 1,
        date: {
            id: 11,
            mouth: 'Jun',
            day: '19'
        },
        type: 'phone',
        status: 'warning',
        tooltip: true
    }
]

const dataEmail_2 = [
    {
        userId: 0,
        date: {
            id: 1,
            mouth: 'Thu',
            day: '5'
        },
        type: 'phone',
        status: '',
        position: 'bottom'
    },
    {
        userId: 0,
        date: {
            id: 2,
            mouth: 'Fri',
            day: '6'
        },
        type: 'email',
        status: ''
    },
    {
        userId: 1,
        date: {
            id: 3,
            mouth: 'Sun',
            day: '8'
        },
        type: 'email',
        status: ''
    },
    {
        userId: 0,
        date: {
            id: 4,
            mouth: 'Sun',
            day: '8'
        },
        type: 'email',
        status: ''
    },
    {
        userId: 0,
        date: {
            id: 5,
            mouth: 'Mon',
            day: '9'
        },
        type: 'calendar',
        status: 'question'
    },
    {
        userId: 0,
        date: {
            id: 7,
            mouth: 'Tue',
            day: '10'
        },
        type: 'email',
        status: 'warning'
    },
    {
        userId: 1,
        date: {
            id: 8,
            mouth: 'Thu',
            day: '12'
        },
        type: 'calendar',
        status: 'check'
    },
    {
        userId: 1,
        date: {
            id: 9,
            mouth: 'Fri',
            day: '13'
        },
        type: 'email',
        status: ''
    },
    {
        userId: 0,
        date: {
            id: 10,
            mouth: 'Fri',
            day: '20'
        },
        type: 'video',
        status: '',
        position: 'bottom'
    },
    {
        userId: 0,
        date: {
            id: 11,
            mouth: 'Sat',
            day: '21'
        },
        type: 'email',
        status: ''
    },
    {
        userId: 1,
        date: {
            id: 12,
            mouth: 'Mon',
            day: '23'
        },
        type: 'email',
        status: ''
    },
    {
        userId: 0,
        date: {
            id: 13,
            mouth: 'Wed',
            day: '25'
        },
        type: 'email',
        status: ''
    }
]