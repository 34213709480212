import React, { useState } from 'react'

import Mastercard from '../../../assets/icon/mastercard.png'
import Amex from '../../../assets/icon/amex.png'
import Dot from '../../../assets/icon/dot.png'
import Delete from '../../../assets/icon/delete.png'
import Arrow from '../../../assets/icon/arrow_primary.png'
import Pen from '../../../assets/icon/pen.png'
import Load from '../../../assets/icon/load.png'

const data = [
    {
        name: 'Primary',
        code: '0195',
        icon: Mastercard
    },
    {
        name: 'Additional',
        code: '1004',
        icon: Amex
    }
]

export default () => {
    return (
        <div className="billing">
            <h3 className="billing-subtitle">billing methods</h3>
            <div style={{marginBottom: 50}}>
                {
                    data.map((item, index) => {
                        return (
                            <RowPay
                                key={index}
                                index={index}
                                item={item}
                            />
                        )
                    })
                }
            </div>
    
            <h3 className="billing-subtitle">{'transactions & invoices'}</h3>
            <div className="link">Go to Transaction history</div>
            <div className="link-img">
                <img src={Load} alt="load" />
                Download invoices (zip)
            </div>
            <div className="link-img">
                <img src={Load} alt="load" />
                Download CSV
            </div>
        </div>
    )
}

const RowPay = ({ item, index }) => {

    const [open, setOpen] = useState(false)

    return (
        <div className={`billing-elem${index === 0 ? ' bolder' : ''}`} onMouseLeave={() => setOpen(false)}>
            <div className="billing-elem__name">{item.name}</div>
            <img className="billing-elem__logo" src={item.icon} alt="logo" />
            <div className="billing-elem__code">{item.code}</div>
            <div className="billing-elem__dot" onClick={() => setOpen(!open)}><img src={Dot} alt="dot" /></div>
            {
                open && (
                    <div className="billing-popup">
                        <div className="billing-popup__elem">
                            <img src={Pen} alt="edit" />Edit
                        </div>
                        
                        <div className="billing-popup__elem">
                            <img src={Arrow} alt="edit" />Set as primary
                        </div>
                        
                        <div className="billing-popup__elem">
                            <img src={Delete} alt="edit" />Remove
                        </div>
                    </div>
                )
            }
        </div>
    )
}