import React from 'react'
import styled from 'styled-components'
import Arrow from '../../assets/Widget/arrow.png'

export default () => {
    return (
        <StyledArrow>
            <img src={Arrow} alt="arrow" />
        </StyledArrow>
    )
}

const StyledArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 25px rgba(50, 47, 109, 0.2);
    background: #5F80F5;
    border-radius: 25px;
    height: 33px;
    width: 33px;
    z-index: 100;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
`