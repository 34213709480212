import React from 'react'
import styled from 'styled-components'

export default () => {
    return (
        <Error>
            Error
        </Error>
    )
}

const Error = styled.div`

`
