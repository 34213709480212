import React from 'react'
import RedArrow from '../../../assets/icon/red_arrow.png'
import Warning from '../../../assets/icon/warning.png'

export default ({ data }) => {
    return (
        <div className="table-list">
            <div className="table-list__row">
                <div className="table-list__row-col start">
                    Dealmaker
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    Qualification
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    Need analysis
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    Proposal/notes
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    Negotiation
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    Total
                    <img src={RedArrow} alt="arrow" />
                </div>
            </div>
            {
                data.map((item, index) => {
                    return (
                        <div className="table-list__row table-list__row-content" key={index}>
                            <div className="table-list__row-col start">
                                <img src={item.pic} alt="mail" style={{marginRight: 20}}/>
                                <div className="table-list__row-col__name">{item.name}</div>
                            </div>
                            <div className="table-list__row-col">
                                <div 
                                    style={{fontWeight: item.qualification.bold ? 'bold' : null}}
                                    className="table-list__row-col__value"
                                >
                                    {item.qualification.value}
                                    {
                                        item.qualification.status === 'error'
                                            ? <img src={Warning} alt="warning" />
                                            : null
                                    }
                                    {
                                        item.qualification.child
                                            ? (
                                                <div className="hover-popup">
                                                    {
                                                        item.qualification.child.map((item, index) => {
                                                            return (
                                                                <div className="hover-popup__elem" key={index}>
                                                                    <div className="hover-popup__elem-title">
                                                                        <div style={{background: item.color}} className="hover-popup__elem-point"/>
                                                                        {item.title}
                                                                    </div>
                                                                    <div className="hover-popup__elem-value">{item.value}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ): null
                                    }
                                </div>
                            </div>
                            <div className="table-list__row-col">
                                <div 
                                    style={{fontWeight: item.analysis.bold ? 'bold' : null}}
                                    className="table-list__row-col__value"
                                >
                                    {item.analysis.value}
                                    {
                                        item.analysis.status === 'error'
                                            ? <img src={Warning} alt="warning" />
                                            : null
                                    }
                                    {
                                        item.analysis.child
                                            ? (
                                                <div className="hover-popup">
                                                    {
                                                        item.analysis.child.map((item, index) => {
                                                            return (
                                                                <div className="hover-popup__elem" key={index}>
                                                                    <div className="hover-popup__elem-title">
                                                                        <div style={{background: item.color}} className="hover-popup__elem-point"/>
                                                                        {item.title}
                                                                    </div>
                                                                    <div className="hover-popup__elem-value">{item.value}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ): null
                                    }
                                </div>
                            </div>
                            <div className="table-list__row-col">
                                <div 
                                    style={{fontWeight: item.note.bold ? 'bold' : null}}
                                    className="table-list__row-col__value"
                                >
                                    {item.note.value}
                                    {
                                        item.note.status === 'error'
                                            ? <img src={Warning} alt="warning" />
                                            : null
                                    }
                                    {
                                        item.note.child
                                            ? (
                                                <div className="hover-popup">
                                                    {
                                                        item.note.child.map((item, index) => {
                                                            return (
                                                                <div className="hover-popup__elem" key={index}>
                                                                    <div className="hover-popup__elem-title">
                                                                        <div style={{background: item.color}} className="hover-popup__elem-point"/>
                                                                        {item.title}
                                                                    </div>
                                                                    <div className="hover-popup__elem-value">{item.value}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ): null
                                    }
                                </div>
                            </div>
                            <div className="table-list__row-col">
                                <div 
                                    style={{fontWeight: item.negotiation.bold ? 'bold' : null}}
                                    className="table-list__row-col__value"
                                >
                                    {item.negotiation.value}
                                    {
                                        item.negotiation.status === 'error'
                                            ? <img src={Warning} alt="warning" />
                                            : null
                                    }
                                    {
                                        item.negotiation.child
                                            ? (
                                                <div className="hover-popup">
                                                    {
                                                        item.negotiation.child.map((item, index) => {
                                                            return (
                                                                <div className="hover-popup__elem" key={index}>
                                                                    <div className="hover-popup__elem-title">
                                                                        <div style={{background: item.color}} className="hover-popup__elem-point"/>
                                                                        {item.title}
                                                                    </div>
                                                                    <div className="hover-popup__elem-value">{item.value}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ): null
                                    }
                                </div>
                            </div>
                            <div className="table-list__row-col">
                                <div 
                                    style={{fontWeight: item.total.bold ? 'bold' : null}}
                                    className="table-list__row-col__value"
                                >
                                    {item.total.value}
                                    {
                                        item.total.status === 'error'
                                            ? <img src={Warning} alt="warning" />
                                            : null
                                    }
                                    {
                                        item.total.child
                                            ? (
                                                <div className="hover-popup">
                                                    {
                                                        item.total.child.map((item, index) => {
                                                            return (
                                                                <div className="hover-popup__elem" key={index}>
                                                                    <div className="hover-popup__elem-title">
                                                                        <div style={{background: item.color}} className="hover-popup__elem-point"/>
                                                                        {item.title}
                                                                    </div>
                                                                    <div className="hover-popup__elem-value">{item.value || <span className="hover-popup__elem-value__clear">No Deals</span>}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ) : null
                                    }
                                </div>
                            </div>
                            
                            <div className="line" />
                        </div>
                    )
                })
            }
        </div>
    )
}