import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Arrow from '../assets/icon/arrow_black.png'

export const LineGrafics = ({data}) => {
    
    const [val, setVal] = useState(0)

    useEffect(() => {
        setVal(data.value)
    }, [data])

    return (
        <Grafics>
            <div style={{margin: '0px 30px'}}>
                <h2 className="title">{data.label}</h2>
                <div className="line-grafics">
                    <div className="line-value" style={{left: `${val}%`}}>
                        <img src={Arrow} alt="value" />
                    </div>
                    <div className="line-main">
                        {
                            data.legend.sort((a, b) => b.width - a.width).map((item, index) => {
                                return <div 
                                    key={index}
                                    className="line-main__point" 
                                    style={{  
                                        width: `${item.width}%`,
                                        background: item.color
                                    }}
                                />
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={`legend`}>
                {
                    data.legend.sort((a, b) => a.width - b.width).map((item, index) => {
                        return (
                            <div key={index} className="legend__line">
                                <div style={{background: item.color}} className="point"/>
                                {item.title}
                            </div>
                        )
                    })
                }
            </div>
        </Grafics>
    )
}

const Grafics = styled.div`

    .line-grafics {
        position: relative;

        .line {

            &-value {
                bottom: 120%;
                position: absolute;
                left: 0;
                transition: left 1s ease-in;
            }

            &-main {
                width: 100%;
                height: 7px;
                box-shadow: 0px 0px 4px rgba(41, 51, 92, 0.25);
                border-radius: 5px;
                border: 2px solid #fefefe;
                overflow: hidden;
                position: relative;
                margin: 30px 0;

                &__point {
                    position: absolute;
                    left: 0;
                    height: 100%;
                }
            }
        }
    }

    .legend {
        background: #F2F7FA;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #989899;
        padding: 13px 30px;
        display: flex;
        justify-content: center;

        &__line {
            display: flex;
            align-items: center;
            margin: 5px 15px;
        }

        .point {
            height: 16px;
            width: 16px;
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);
            border-radius: 3px;
            margin-right: 16px;
        }
    }
    
`