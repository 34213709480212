import React from 'react'
import styled from 'styled-components'

export const LineGrafic6 = ({ data, title }) => {
    return (
        <Grafic>
            <div className="v6-title">
                {title}
            </div>
            <div className="v6-wrap">
                {
                    data.map((item, index) => {
                        return (
                            <div className="v6-elem" key={index}>
                                <div className="v6-elem__value" style={{background: item.color, width: item.value}}></div>
                                <div className="v6-elem__title">{item.title}</div>
                            </div>
                        )
                    })
                }
            </div>
        </Grafic>
    )
}

const Grafic = styled.div`
    margin-bottom: 25px;

    .v6 {

        &-title {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #989899;
            margin-bottom: 10px;
        }

        &-wrap {

        }

        &-elem {
            margin-bottom: 8px;
            display: flex;
            align-items: center;

            &__value {
                height: 7px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }

            &__title {
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                color: #25182E;
                margin-left: 10px;
            }
        }

    }

`