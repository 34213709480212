import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../components/Button'
import Icon from '../../../assets/icon/list.png'
import Arrow from '../../../assets/icon/arrow_white.png'
import Arrow2 from '../../../assets/icon/arrow_x2.png'

export default ({ data }) => {

    const renderData = () => data.slice(0, 4)

    return (
        <List>
            {
                renderData().map((item, index) => {
                    return (
                        <div className="list-elem" key={index}>
                            <div className="list-elem__text">
                                <div className="list-elem__text-icon">
                                    <img src={Icon} alt="list" />
                                </div>
                                <div className="list-elem__text-content">
                                    {item.text}
                                </div>
                            </div>
                            {
                                item.link &&
                                    <div className="list-elem__btn">
                                        <a href="https://www.substrata.me/learning-center/">
                                            <Button>Go to Learning Center</Button>
                                        </a>
                                    </div>
                            }
                        </div>
                    )
                })
            }
            <div className="list-pagination">
                <div className="list-pagination__text">
                    1 of 1
                </div>
                <div className="list-pagination__btn">
                    <img src={Arrow} alt="arrow" />
                </div>
                <div className="list-pagination__btn">
                    <img src={Arrow2} alt="arrow" />
                </div>
            </div>
        </List>
    )
}

const List = styled.div`
    .list {

        &-pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 20px;

            &__text {
                font-size: 12px;
                line-height: 16px;
                color: rgba(37, 24, 46, 0.7);
                margin: 0 10px;
            }

            &__btn {
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(152, 152, 153, 0.5);
                border-radius: 15px;
                box-shadow: 0px 2px 25px rgba(50, 47, 109, 0.2);
                margin-left: 10px;
            }
        }

        &-elem {
            margin-bottom: 30px;

            &__text {
                display: flex;

                &-icon {
                    margin-right: 13px;
                    margin-top: 5px;
                }

                &-content {
                    font-size: 14px;
                    line-height: 24px;
                    color: #29335C;
                }
            }

            &__btn {
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
`