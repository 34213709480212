import React from 'react'
import styled from 'styled-components'


import change from '../assets/icon/change.png'
import deleteIcon from '../assets/icon/delete.png'
import Logo from '../assets/logo.jpg'

export const InputFile = ({ title }) => {
    return (
        <InputWrap>
            <div className="input-title">{title}</div>
            <div
                className="input-elem"
            >
                <img src={Logo} alt="logo" className="input-elem__logo" />
                <div className="input-action">
                    <img src={change} alt="action" />
                    <img src={deleteIcon} alt="action" />
                </div>
            </div>
        </InputWrap>
    )
}

const InputWrap = styled.div`
    width: 100%;
    margin-bottom: 25px;

    .input {

        &-action {
            display: flex;
            flex-direction: column;

            img {
                margin-top: 5px;
            }
        }

        &-title {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #989899;
            margin: 10px 0;
        }
        
        &-elem {
            width: calc(100% - 30px);
            border: none;
            font-size: 14px;
            line-height: 19px;
            color: #29335C;
            display: flex;

            &__logo {
                height: 50px;
                width: 66px;
                object-fit: contain;
            }

            &:focus {
                outline: none;
            }
        }
    }


`