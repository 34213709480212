import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from './Button'

export const TopLink = ({text, textButton, link}) => {

    return (
        <TopLinkStyled>
            <span>{text}</span>
            <Link to={link}>
                <Button>
                    {textButton}
                </Button>
            </Link>
        </TopLinkStyled>
    )

}

const TopLinkStyled = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 30px 20px;
    display: flex;
    align-items: center;

    span {
        font-size: 14px;
        font-weight: 700;
        margin-right: 20px;
    }
`