import React from 'react'
import styled from 'styled-components'

export const UserInfo = ({pic, name, style}) => {
    return (
        <StyledUserInfo style={style}>
            <img 
                className="photo"
                src={pic} 
                alt="user" 
            />
            <span className="name">
                {name}
            </span>
        </StyledUserInfo>
    )
}

const StyledUserInfo = styled.div`
    display: flex;
    align-items: center;

    .photo {
        height: 30px;
        width: 30px;
        border-radius: 15px;
        object-fit: cover;
        margin-right: 10px;
    }

    .name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #29335C;
        white-space: nowrap;
    }
`