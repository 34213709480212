import React, { useState } from 'react'
import styled from 'styled-components'

import Arrow from '../assets/icon/arrow.png'

export const DropDown = ({data, current, setCurrent, bgColor, style}) => {

    const [open, setOpen] = useState(false)

    return (
        <StyledDropDown onClick={() => setOpen(!open)} onMouseLeave={() => setOpen(false)} style={{background: bgColor, ...style}}>
            <div className="current">{current}</div>
            <img src={Arrow} alt="arrow" className="arrow" />
            {
                open &&
                    <div className="drop" style={{background: bgColor}}>
                        {
                            data.map((item, index) => {
                                return (
                                    <div className="drop__elem" key={index} onClick={() => setCurrent(item)}>
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </StyledDropDown>
    )
}

const StyledDropDown = styled.div`
    background: #FEFEFF;
    box-shadow: 0px 2px 4px rgba(50, 47, 109, 0.1);
    border-radius: 3px;
    position: relative;
    width: 150px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #29335C;
    padding: 10px 10px 10px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;

    .current {
        width: 100%;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis;
    }

    .arrow {
        margin-left: 5px;
    }

    .drop {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        padding: 10px 0;
        border-radius: 3px;
        box-shadow: 0px 2px 4px rgba(50, 47, 109, 0.1);
        background: #FEFEFF;
        z-index: 1000000;

        &__elem {
            padding: 6px 20px;
            
            &:hover {
                background: #FaFaFa;
            }
        }
    }
`