import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Arrow from '../assets/icon/arrow_black.png'

export const LineGrafic7 = ({ data, value }) => {
    return (
        <Grafic>
            {
                data.map((item, index) => {
                    return (
                        <Line 
                            key={index}
                            item={item}
                        />
                    )
                })
            }
            <div className="v7-bottom">
                <div className="v7-bottom__title">
                    Calculated Kinesics score:
                </div>
                <div className="v7-bottom__value">
                    {value} / 5
                </div>
            </div>
        </Grafic>
    )
}


const Line = ({item}) => {

    const [value, setValue] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setValue(item.value)
        }, 700)
    }, [item])
    return (
        <div className="v7-wrap">
            <div className="v7-icon">
                <img src={item.icon} alt="icon" />
            </div>
            <div className="v7-line">
                <div 
                    className="v7-line__bg" 
                    style={{
                        background: item.color,
                        width: `${100/5 * value}%`
                    }}
                />
                {
                    [...new Array(6)].map((item, index) => {
                        return (
                            <div 
                                key={index} 
                                className="v7-line__label"
                                style={{
                                    left: `${100/5 * index}%`
                                }}
                            >
                                {index}
                            </div>
                        )
                    })
                }
                <div className="v7-line__value" style={{left: `${100/5 * value}%`}}>
                    {value}
                    <img src={Arrow} alt="arrow" />
                </div>
            </div>
        </div>
    )
}

const Grafic = styled.div`

    .v7 {

        &-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
        }

        &-icon {
            margin-right: 20px;
            display: flex;
        }

        &-line {
            width: 100%;
            height: 7px;
            border: 1.5px solid white;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);
            border-radius: 5px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &__bg {
                height: 4px;
                border-radius: 4px;
                transition: width .5s ease-in;
            }

            &__label {
                position: absolute;
                top: calc(100% + 5px);
                transform: translateX(-50%);
                font-size: 10px;
                line-height: 14px;
                color: #989899;
            }

            &__value {
                font-weight: 600;
                font-size: 10px;
                line-height: 14px;
                color: #29335C;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                transform: translateX(-50%);
                bottom: calc(100% + 5px);
                transition: left .5s ease-in;
            }
        }

        &-bottom {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 10px;

            &__title {
                font-size: 14px;
                line-height: 19px;
                color: #C1C1C1;
                margin-right: 10px;
            }

            &__value {
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                color: #29335C;
            }
        }
    }
    
`