import React, { useState } from 'react'
import { DropDown } from '../../../components/DropDown'
import { Diagram } from '../../../components/Diagram/Diagram'

import Calendar from '../../../assets/Widget/calendar_v2.png'
import Warning from '../../../assets/icon/warning.png'

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]

const label = [
    <span>Q<sub>1</sub>18</span>,
    <span>Q<sub>2</sub>18</span>,
    <span>Q<sub>3</sub>18</span>,
    <span>Q<sub>4</sub>18</span>,
    <span>Q<sub>1</sub>19</span>,
    <span>Q<sub>2</sub>19</span>,
    <span>Q<sub>3</sub>19</span>,
    <span>Q<sub>4</sub>19</span>
]

const data = [
    {
        name: 'Emma Watkins',
        revenue: 7,
        totalRevenue: 285000,
        totalUpsell: 17200,
        status: false,
        color: '#F3CB3D',
        value: [210000, 225000, 245000, 255000, 265000, 275000, 280000, 290000]
    },
    {
        name: 'William Young',
        revenue: 7,
        totalRevenue: 285000,
        totalUpsell: 17200,
        status: true,
        color: '#32B76C',
        value: [145000, 165000, 205000, 225000, 230000, 225000, 220000, 180000]
    },
    {
        name: 'Donna Perez',
        revenue: 7,
        totalRevenue: 285000,
        totalUpsell: 17200,
        status: true,
        color: '#5F80F5',
        value: [135000, 155000, 200000, 230000, 252000, 248000, 235000, 225000]
    },
    {
        name: 'Beverly Mendes',
        revenue: 7,
        totalRevenue: 285000,
        totalUpsell: 17200,
        status: false,
        color: '#F26065',
        value: [122000, 135000, 150000, 155000, 160000, 168000, 172000, 195000]
    },
    {
        name: 'James Reed',
        revenue: 7,
        totalRevenue: 285000,
        totalUpsell: 17200,
        status: false,
        color: '#3E5DCB',
        value: [85000, 97000, 94000, 137000, 140000, 145000, 149000, 155000]
    },

]

export default () => {
    const [dropCurrent, setDropCurrent] = useState(data1[0])
    return (
        <>
            <div className="row">
                <div className="opportunities-header">
                    Dealmaker:
                    <DropDown
                        data={data1}
                        current={dropCurrent}
                        setCurrent={setDropCurrent}
                        bgColor={'#F2F7FA'}
                    />
                    <div className="date-picker">
                        <img src={Calendar} alt="calendar" />
                        2018 - 2019 by quarters
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col" style={{display: 'flex'}}>
                    <div className="trends-alert">
                        <img src={Warning} alt="warning" />
                        Note the latest decrease in revenue. You may want to check it out
                    </div>
                </div>
            </div>
            <div className="row">
                <Diagram 
                    label={label}
                    data={data}
                />
            </div>
        </>
    )
}