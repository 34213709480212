import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Icon from '../../../assets/icon/list.png'
import Arrow from '../../../assets/icon/arrow_white.png'
import Arrow2 from '../../../assets/icon/arrow_x2.png'
import ArrowBack from '../../../assets/icon/arrow_back.png'

export default ({ data }) => {

    const renderData = () => data.slice(0, 2)

    return (
        <List>
            {
                renderData().map((item, index) => {
                    return (
                        <div className="list-elem" key={index}>
                            <div className="list-elem__text">
                                <div className="list-elem__text-icon">
                                    <img src={Icon} alt="list" />
                                    {/* {item.icon &&  <img src={Icon} alt="list" />} */}
                                </div>
                                <div className="list-elem__text-content">
                                    {item.text}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <div className="list-bottom">
                <Link to="/video">
                    <div className="list-back">
                        <div className="list-back__icon">
                            <img src={ArrowBack} alt="back" />
                        </div>
                        Back
                    </div>
                </Link>
                <div className="list-pagination">
                    <div className="list-pagination__text">
                        1 of 1
                    </div>
                    <div className="list-pagination__btn">
                        <img src={Arrow} alt="arrow" />
                    </div>
                    <div className="list-pagination__btn">
                        <img src={Arrow2} alt="arrow" />
                    </div>
                </div>
            </div>
            
        </List>
    )
}

const List = styled.div`
    .list {

        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
        }

        &-back {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 19px;
            color: rgba(37, 24, 46, 0.7);

            &__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 2px 25px rgba(50, 47, 109, 0.2);
                height: 30px;
                width: 30px;
                border-radius: 15px;
                background: #394577;
                margin-right: 12px;
            }
        }

        &-pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &__text {
                font-size: 12px;
                line-height: 16px;
                color: rgba(37, 24, 46, 0.7);
                margin: 0 10px;
            }

            &__btn {
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(152, 152, 153, 0.5);
                border-radius: 15px;
                box-shadow: 0px 2px 25px rgba(50, 47, 109, 0.2);
                margin-left: 10px;
            }
        }

        &-elem {
            margin-bottom: 30px;

            &__text {
                display: flex;

                &-icon {
                    min-width: 15px;
                    margin-right: 13px;
                    margin-top: 5px;
                }

                &-content {
                    font-size: 14px;
                    line-height: 24px;
                    color: #29335C;
                }
            }
        }
    }
`