import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Paper } from '../../../components/Paper'
import { UserInfo } from '../../../components/UserInfo'
import { Button } from '../../../components/Button'
import { Grafics } from '../../../components/Grafics'
import { connect } from 'react-redux'
import './style.scss'

import Pic from '../../../assets/user.png'
// import Add from '../../../assets/icon/add.png'
// import Notification from '../../../assets/icon/notification.png'
import List from '../../../assets/icon/list.png'


const list = [
    'Your deals take longer to close than team average for closed-won deals',
    'Well done! You’re closing bigger deals than the team’s average closed-won.',
    <span>You might be engaging too frequently. This may signal neediness go to <a className="underline" href="http://substrata.me/learning-center/">“Learning Center”</a> for help.</span>,
    <span>You’re not optimizing vocal authority. You can improve this. Go to <a className="underline" href="http://substrata.me/learning-center/">“Learning Center”</a> to learn more.</span>,
]

const data1 = {
    data: [
        {
            label: 'All deals lifecycle vs team avg',
            sub: 'by weeks',
            value: [
                {
                    color: '#f3cb3d',
                    value: 12,
                    sub: true
                },
                {
                    color: '#5F80F5',
                    value: 8,
                    sub: true
                }, 
            ]
        },
        {
            label: 'Avg contract value vs team avg',
            sub: 'in USD',
            value: [
                {
                    color: '#f3cb3d',
                    value: 12097,
                },
                {
                    color: '#5F80F5',
                    value: 9157
                },
            ]
        }
    ],
    legend: [
        {
            color: '#F3CB3D',
            title: 'You'
        },
        {
            color: '#5F80F5',
            title: 'Team'
        }
    ]
}

const data2 = {
    data: [
        {
            label: 'Total avg engagement ratio vs team avg',
            sub: '',
            value: [
                {
                    color: '#f3cb3d',
                    value: 1.25,
                },
                {
                    color: '#5F80F5',
                    value: 1.48
                },
            ]
        }
    ],
    legend: [
        {
            color: '#F3CB3D',
            title: 'You / Prospects'
        },
        {
            color: '#5F80F5',
            title: 'Team / Prospects'
        }
    ]
}

const data3 = {
    data: [
        {
            label: 'Avg contract value vs team avg',
            sub: '',
            value: [
                {
                    color: '#f3cb3d',
                    value: 23.1,
                },
                {
                    color: '#5F80F5',
                    value: 18.9
                },
            ]
        }
    ],
    legend: [
        {
            color: '#F3CB3D',
            title: 'You'
        },
        {
            color: '#5F80F5',
            title: 'Team'
        }
    ]
}

const data4 = {
    data: [
        {
            label: 'Avg vocal dominance status vs top closers avg',
            sub: '',
            value: [
                {
                    color: '#f3cb3d',
                    value: 3.1
                },
                {
                    color: '#5F80F5',
                    value: 3.8
                },
                {
                    color: '#26A653',
                    value: 4.5
                },
            ]
        }
    ],
    legend: [
        {
            color: '#F3CB3D',
            title: 'You'
        },
        {
            color: '#5F80F5',
            title: 'Prospects'
        },
        {
            color: '#26A653',
            title: 'Top closers'
        }
    ]
}

const Insights = (props) => {

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (
                <div className="row">
                    <div className="col jcfe">
                        <UserInfo
                            pic={Pic}
                            name={'Eran Yessodi'}
                        />
                        {/* <div className="btn-group">
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Add} alt="add" />
                            </Button>
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Notification} alt="notification" />
                            </Button>
                        </div> */}
                    </div>
                </div>
            )
        })
    }, [])  // eslint-disable-line

    return (
        <div className="insights">
            <div className="row">
                <div className="col flex-grow-1">
                    <Paper
                        title={'Summary'}
                    >
                        <div className="list">
                            {
                                list.map((item, index) => (
                                    <div className="list__elem" key={index}>
                                        <div><img src={List} alt="list" className="list__elem-check"/></div>
                                        {item}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="list-btn">
                            <a href="https://www.substrata.me/learning-center/">
                                <Button>
                                    Go to Learning Center
                                </Button>
                            </a>
                        </div>
                    </Paper>
                </div>
                <div className="col flex-grow-2">
                    <div className="row">
                        <Paper>
                            <Grafics
                                data={data1}
                            />
                        </Paper>
                    </div>
                    <div className="row">
                        <div style={{paddingRight: 5, width: `50%`, display: 'flex'}}>  
                            <Paper>
                                <Grafics
                                    data={data2}
                                    vertical={true}
                                />
                            </Paper>
                        </div>
                        <div style={{paddingLeft: 5, width: `50%`, display: 'flex'}}>  
                            <Paper>
                                <Grafics
                                    data={data3}
                                    vertical={true}
                                />
                            </Paper>
                        </div>
                    </div>
                    <div className="row">
                        <Paper>
                            <Grafics
                                data={data4}
                                revers={true}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col jcsb">
                    <div/>
                    <Link to="/my-deals/overview">
                        <Button type={'big'}>
                            Deal Insights
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        state: state
    })
)(Insights);