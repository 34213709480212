import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Paper } from '../../../components/Paper'
import { Button } from '../../../components/Button'
import { DropDown } from '../../../components/DropDown'
import Widget from '../../../components/WidgetAudioV2/Widget'
import { connect } from 'react-redux'
import { UserInfo } from '../../../components/UserInfo'
import Pic from '../../../assets/user.png'
import './style.scss'

import Add from '../../../assets/icon/add.png'
import Notification from '../../../assets/icon/notification.png'
import Eran from '../../../assets/Widget/user2.png'
import Lisa from '../../../assets/Widget/user4.png'
import Voice from '../../../assets/Player/voice-full.mp3'
import Voice2 from '../../../assets/Player/voice2-full.mp3'
import Arrow from '../../../assets/icon/arrow_line_v2.png'
import List from '../../../assets/icon/list.png'
import ArrowBack from '../../../assets/icon/arrow_back.png'
import ArrowOne from '../../../assets/icon/arrow_white.png'
import Arrow2 from '../../../assets/icon/arrow_x2.png'

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]

const data = {
    user: [
        {
            name: 'Eran',
            pic: Eran
        },
        {
            name: 'Lisa',
            pic: Lisa
        }
    ],
    audio: [Voice, Voice2],
    chat: [
        {
            id: 0,
            text: 'So how do you see your sales cycle? What can be improved?'
        },
        {
            id: 1,
            text: 'Honestly, we`re using a lot of tools, but in the end it`s more of an intuition thing you know.'
        },
        {
            id: 0,
            text: 'Trust me I know.'
        },
        {
            id: 1,
            text: 'I wish there was a way we could know exactly what are the issues at hand and how to deal with them. I spend an unbelievable amount of time listening to cold calls with customers just searching for the needle in the haystack'
        },
        {
            id: 0,
            text: 'Well actually, that`s exactly the reason I`m calling you in for.'
        },
        {
            id: 0,
            text: 'So how do you see your sales cycle? What can be improved?'
        },
        {
            id: 1,
            text: 'Honestly, we`re using a lot of tools, but in the end it`s more of an intuition thing you know.'
        },
        {
            id: 0,
            text: 'Trust me I know.'
        },
        {
            id: 1,
            text: 'I wish there was a way we could know exactly what are the issues at hand and how to deal with them. I spend an unbelievable amount of time listening to cold calls with customers just searching for the needle in the haystack'
        },
        {
            id: 0,
            text: 'Well actually, that`s exactly the reason I`m calling you in for.'
        },
    ]
}

const Zoom = (props) => {
    
    const [dropCurrent, setDropCurrent] = useState(data1[0])

    const numPage = Math.floor(data2.length/3) + 1
    const [currentPage, setCurrentPage] = useState(1)


    const filterData = () => {
        return [...data2].splice((currentPage - 1) * 3, 3)

    }

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (<>
                <div className="row">
                    <div className="col jcsb">
                        <DropDown
                            data={data1}
                            current={dropCurrent}
                            setCurrent={setDropCurrent}
                        />
                        <div className="btn-group">
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Add} alt="add" />
                            </Button>
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Notification} alt="notification" />
                            </Button>
                            <UserInfo
                                pic={Pic}
                                name={'Eran Yessodi'}
                                style={{marginLeft: '10px'}}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{display: 'flex'}}>
                        <Link to="/my-deals">
                            <div className="widget-layout__header">
                                {
                                    data.user.map((item, index) => {
                                        return <img
                                            src={item.pic}
                                            alt="user"
                                            key={index}
                                            style={{transform: `translateX(-${index * 5}px)`}}
                                            className="widget-layout__header-pic"
                                        />
                                    })
                                }
                                <div className="widget-layout__header-text">View Relationship</div>
                                <img src={Arrow} alt="arrow" />
                            </div>
                        </Link>
                    </div>
                </div>
            </>)
        })
    }, []) // eslint-disable-line

    return (<>
        <div className="row zoom">
            <div className="col">
                <Paper style={{padding: 0}}>
                    <Widget
                        data={data}
                        transcriptionVisible={false}
                    />
                </Paper>
            </div>
        </div>
        <div className="row zoom">
            <div className="col insights">
                <Paper
                    title="Interaction Insights"
                >
                    <div className="insights-wrap">
                        {
                            filterData().map((item, index) => {
                                return (
                                    <div key={index} className="insights__elem">
                                        <div className="insights__elem-list">
                                            <img src={List} alt="list" />
                                        </div>
                                        <div className="insights__elem-content">
                                            <div className="insights__elem-content-top">
                                                {item.text}
                                            </div>
                                            <div className="insights__elem-content-sub">
                                                {item.subtext}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="insights-nav">
                        <Link to="/zoom">
                            <div className="insights-nav__back-wrap"> 
                                <div className="insights-nav__back">
                                    <img src={ArrowBack} alt='back' />
                                </div>
                                Back
                            </div>
                        </Link>
                        <div className="insights-nav__page">
                            {
                                currentPage !== 1 && (<>
                                    <div 
                                        className="page-arrow"
                                        onClick={() => {
                                            setCurrentPage(1)
                                        }}
                                    >
                                        <img 
                                            src={Arrow2} 
                                            alt="arrow" 
                                            style={{transform: 'rotate(180deg)'}}
                                        />
                                    </div>
                                    <div 
                                        className="page-arrow"
                                        onClick={() => {
                                            setCurrentPage(val => val - 1)
                                        }}
                                    >
                                        <img 
                                            src={ArrowOne} 
                                            alt="arrow" 
                                            style={{transform: 'rotate(180deg)'}}
                                        />
                                    </div>
                                </>)
                            }
                            <div className="page-num">
                                {`${currentPage} fo ${numPage}`}
                            </div>
                            {
                                currentPage !== numPage ? (<>
                                    <div 
                                        className="page-arrow"
                                        onClick={() => {
                                            setCurrentPage(val => val + 1)
                                        }}
                                    >
                                        <img 
                                            src={ArrowOne} 
                                            alt="arrow" 
                                        />
                                    </div>
                                    <div 
                                        className="page-arrow"
                                        onClick={() => {
                                            setCurrentPage(numPage)
                                        }}
                                    >
                                        <img
                                            src={Arrow2} 
                                            alt="arrow"
                                        />
                                    </div>
                                </>)
                                : <div style={{width: 80}} />
                            }
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    </>)
}

export default connect(
    state => ({
        state: state
    })
)(Zoom);

const data2 = [
    {
        text: 'Following a response from Lisa, your pitch went higher than usual. This may signal higher stress levels.',
        subtext: <span>Listen to <a href="/" className="underline">audio segment</a></span>
    },
    {
        text: 'When scheduling this call, Julie responded only to your 2nd email. This may be a sign of low interest.',
        subtext: <span>See chain of events in <a href="/" className="underline">Sonata</a></span>
    },
    {
        text: <span>You can learn how to improve your vocal dominance {'&'} overall authority in our <a href="/">“Learning Center”</a>.</span>,
        subtext: <a href="https://www.substrata.me/learning-center/"><Button>Go to Learning Center</Button></a>
    },
    {
        text: '2 Following a response from Lisa, your pitch went higher than usual. This may signal higher stress levels.',
        subtext: <span>Listen to <a href="/" className="underline">audio segment</a></span>
    },
    {
        text: '2 When scheduling this call, Julie responded only to your 2nd email. This may be a sign of low interest.',
        subtext: <span>See chain of events in <a href="/" className="underline">Sonata</a></span>
    },
    {
        text: <span>2 You can learn how to improve your vocal dominance {'&'} overall authority in our <a href="/">“Learning Center”</a>.</span>,
        subtext: <a href="https://www.substrata.me/learning-center/"><Button>Go to Learning Center</Button></a>
    },
    {
        text: '3 Following a response from Lisa, your pitch went higher than usual. This may signal higher stress levels.',
        subtext: <span>Listen to <a href="/" className="underline">audio segment</a></span>
    },
    {
        text: '3 When scheduling this call, Julie responded only to your 2nd email. This may be a sign of low interest.',
        subtext: <span>See chain of events in <a href="/" className="underline">Sonata</a></span>
    },
    {
        text: <span>3 You can learn how to improve your vocal dominance {'&'} overall authority in our <a href="/">“Learning Center”</a>.</span>,
        subtext: <a href="https://www.substrata.me/learning-center/"><Button>Go to Learning Center</Button></a>
    },
    {
        text: '4 Following a response from Lisa, your pitch went higher than usual. This may signal higher stress levels.',
        subtext: <span>Listen to <a href="/" className="underline">audio segment</a></span>
    },
]