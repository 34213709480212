import React, { useState, useEffect, useRef  } from 'react'
import './style.scss'

import PhoneBG from '../../assets/Widget/bg_phone.png'
import Phone from '../../assets/Widget/phone.png'
import Play from '../../assets/Player/play.png'
import Pause from '../../assets/Player/pause.png'
import Start from '../../assets/Player/back.png'
import End from '../../assets/Player/next.png'
import Prev from '../../assets/Player/back2.png'
import Next from '../../assets/Player/next2.png'
import Volume from '../../assets/Player/volume.png'
import VolumeOff from '../../assets/Player/volume_off.png'

import Comments from '../../assets/Player/comments.png'
import Call from '../../assets/Player/call.png'
import Pitch from '../../assets/Player/pitch.png'
import Intensity from '../../assets/Player/intensity.png'
import Speed from '../../assets/Player/speed.png'
import Close from '../../assets/Player/close.png'
import Search from '../../assets/Player/search.png'
// import Waves from 'wavesurfer.js'
import WFPlayer from 'wfplayer';
import Line from './Line'

export default ({ data, transcriptionVisible }) => {

    const ref = useRef()
    const ref2 = useRef()

    const [audio] = useState(new Audio(data.audio[0]))
    const [audio2] = useState(new Audio(data.audio[1]))
    const [Spectrum, setSpectrum] = useState()
    const [Spectrum2, setSpectrum2] = useState()

    useEffect(() => {
        if (Spectrum) {
            Spectrum.isMuted = false
            Spectrum.on("loadEnd", function(){
                Spectrum.muted = true
            });
            Spectrum.load(data.audio[0]);
        }
    }, [Spectrum, data.audio])

    useEffect(() => {
        if (Spectrum2) {
            Spectrum2.isMuted = false
            Spectrum2.on("loadEnd", function(){
                Spectrum2.muted = true
            });
            Spectrum2.load(data.audio[1]);
        }
    }, [Spectrum2, data.audio])

    const SpectrumOption = {
        grid: false,
        cursor: false,
        waveColor: '#EBEEFF',
        progressColor: '#DADFFF',
        backgroundColor: '#FFFFFF',
        padding: 1,
        waveScale: 1.8,
        duration: 20,
    }

    useEffect(() => {
        if (ref && ref.current) {
            ref && setSpectrum(new WFPlayer({
                container: ref.current,
                mediaElement: audio,
                ...SpectrumOption
            }))
        }
        
        if (ref2 && ref2.current) {
            ref2 && setSpectrum2(new WFPlayer({
                container: ref2.current,
                mediaElement: audio2,
                ...SpectrumOption
            }))
        }
    }, [ref, ref2, data]) // eslint-disable-line

    const [time, setTime] = useState('0:00')
    const [status, setStatus] = useState(true)
    const [currentTime, setCurrentTime] = useState(0)
    const [volumeStatus, setVolumeStatus] = useState(audio.muted ? VolumeOff : Volume)

    const [callTranscription, setCallTranscription] = useState(transcriptionVisible)
    const [speed, setSpeed] = useState(false)

    audio.addEventListener('loadedmetadata', () => {
        setTime(FormatTime(audio.duration))
    });

    audio.addEventListener('ended', () => {
        goStart()
    });
    audio2.addEventListener('ended', () => {
        goStart()
    });

    useEffect(() => {
        audio.playbackRate = speed ? 1.5 : 1
        // Spectrum && Spectrum.setPlaybackRate(speed ? 1.5 : 1)
        audio2.playbackRate = speed ? 1.5 : 1
        // Spectrum2 && Spectrum2.setPlaybackRate(speed ? 1.5 : 1)
    }, [speed]) // eslint-disable-line

    audio.onended = () => {
        stop()
    }

    audio2.onended = () => {
        stop()
    }
   
    const FormatTime = (time) => {
        let minute = Math.floor(time / 60)
        let sec = Math.floor(time - (minute * 60))
        return `${minute}:${sec < 10 ? '0' + sec : sec}`
    }

    var timer;

    const play = () => {
        audio.play()
        // Spectrum.play()
        audio2.play()
        // Spectrum2.play()
        timer = setInterval(() => {
            setCurrentTime(audio.currentTime)
        }, 500)
        setStatus(false)
    }

    const stop = () => {
        audio.pause()
        // Spectrum.pause()
        audio2.pause()
        // Spectrum2.pause()
        clearInterval(timer)
        setStatus(true)
    }

    const goStart = () => {
        stop()
        // Spectrum.setCurrentTime(0)
        audio.currentTime = 0
        // Spectrum2.setCurrentTime(0)
        audio2.currentTime = 0
        setCurrentTime(0)
    }

    const goEnd = () => {
        stop()
        // Spectrum.setCurrentTime(audio.duration)
        audio.currentTime = audio.duration
        // Spectrum2.setCurrentTime(audio2.duration)
        audio2.currentTime = audio2.duration
    }

    const editTime = (time) => {
        let edited = (audio.currentTime + time) < 0 
            ? 0
            : (audio.currentTime + time) > audio.duration
                ? audio.duration
                : audio.currentTime + time 
        if (edited > audio.duration) {
            // Spectrum.setCurrentTime(audio.duration)
            audio.currentTime = audio.duration
            // Spectrum2.setCurrentTime(audio.duration)
            audio2.currentTime = audio.duration
        } else {
            // Spectrum.setCurrentTime(edited)
            audio.currentTime = edited
            // Spectrum2.setCurrentTime(edited)
            audio2.currentTime = edited
        }
    }

    const muted = () => {
        audio.muted = !audio.muted
        audio2.muted = !audio2.muted
        setVolumeStatus(audio.muted ? VolumeOff : Volume)
    }

    return (
        <div className="widget">
            <div className="main-wrap">

                <div className="left-side">
                    <div className="left-side__phone">
                        <img src={PhoneBG} alt="bg" />
                        <img src={Phone} alt="phone" />
                    </div>
                    {
                        data.user.map((item, index) => {
                            return (
                                <div className="left-side__user" key={index}>
                                    <div className="left-side__user-photo">
                                        <img src={item.pic} alt="pic" />
                                        {item.mailNum ? <div className="mailnum">+{item.mailNum}</div> : null}
                                    </div>
                                    <div className="left-side__user-name">
                                        {item.name}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="main">
                    <div className="main-line" ref={ref} />
                    <div className="main-line" ref={ref2} />
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%'
                    }}>
                        <Line
                            height={37}
                            width={[300]}
                        />
                        <Line
                            height={30}
                            width={[640]}
                        />
                    </div>
                </div>
            
            </div>
            
            <div className="player">
                <div className="player-line__progress-time">
                    {time}
                </div>
                <div className="player-line">
                    
                    <div 
                        className="player-line__progress" 
                        style={{
                            width: `${100 / audio.duration * currentTime}%`
                        }}
                    />
                </div>
                <div className="player-bottom">
                    <div className="player-bottom__btn">
                        <img 
                            src={Start} 
                            alt="play" 
                            onClick={goStart}
                        />
                        <img 
                            src={Prev} 
                            alt="play" 
                            onClick={() => {
                                editTime(-10)
                            }}
                        />
                        {
                            status 
                                ? <img 
                                    src={Play} 
                                    alt="play" 
                                    onClick={play}
                                    className="player-bottom__btn-action"
                                />
                                : <img 
                                    src={Pause} 
                                    alt="play" 
                                    onClick={stop}
                                    className="player-bottom__btn-action"
                                />
                        }
                        <img 
                            src={Next} 
                            alt="play" 
                            onClick={() => {
                                editTime(10)
                            }}
                        />
                        <img 
                            src={End} 
                            alt="play" 
                            onClick={goEnd}
                        />
                        <img 
                            src={volumeStatus} 
                            alt="play" 
                            onClick={muted}
                        />
                    </div>
                    <div className="player-bottom__filter">
                        <div 
                            className={`player-bottom__filter-elem${false ? ' active' : ''}`}
                            onClick={() => {}}
                        >
                            <div className="player-bottom__filter-elem-img">
                                <img src={Comments} alt="filter" />
                            </div>
                            Comments
                        </div>
                        <div 
                            className={`player-bottom__filter-elem${callTranscription ? ' active' : ''}`}
                            onClick={() => setCallTranscription(val => !val)}
                        >
                            <div className="player-bottom__filter-elem-img">
                                <img src={Call} alt="filter" />
                            </div>
                            Call transcript
                        </div>
                        <div 
                            className={`player-bottom__filter-elem${true ? ' active' : ''}`}
                            onClick={() => {}}
                        >
                            <div className="player-bottom__filter-elem-img">
                                <img src={Pitch} alt="filter" />
                            </div>
                            Pitch
                        </div>
                        <div 
                            className={`player-bottom__filter-elem${false ? ' active' : ''}`}
                            onClick={() => {}}
                        >
                            <div className="player-bottom__filter-elem-img">
                                <img src={Intensity} alt="filter" />
                            </div>
                            Intensity
                        </div>
                        <div 
                            className={`player-bottom__filter-elem${speed ? ' active' : ''}`}
                            onClick={() => setSpeed(val => !val)}
                        >
                            <div className="player-bottom__filter-elem-img">
                                <img src={Speed} alt="filter" />
                            </div>
                            Speed
                        </div>
                    </div>
                </div>
            </div>
            {
                callTranscription && (
                    <div className="call">
                        <div className="call-action">
                            <img src={Search} alt="search" />
                            <img 
                                src={Close} 
                                alt="close" 
                                onClick={() => setCallTranscription(false)}
                            />
                        </div>
                        <h2 className="title" style={{marginTop: 0}}>Call transcript</h2>
                        <div className="call-wrap">
                            {
                                data.chat.map((item, index) => {
                                    return (
                                        <div className="call-message" key={index}>
                                            <img src={data.user[item.id].pic} className="call-message__photo" alt="user" />
                                            <div className="call-message__text">
                                                {item.text}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}