import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { DropDown } from '../../../components/DropDown'

import List from '../../../assets/icon/list.png'

import Light from '../../../assets/icon/light.png'
import People from '../../../assets/icon/people.png'
// import Head from '../../../assets/icon/head.png'

const list = [
    'Jessica closes bigger deals than the team average',
    'Jessica’s deals take longer to close than the team average',
    'Jessica could improve her vocal authority',
    'Jessica starts well but tends to linger before moving on to deal terms',
]

const list2 = [
    {
        title: 'Buying Zones',
        text: <span>Check what buying zone your client is currently in. <Link to="/admin/deals">Buying Zone Analysis</Link></span>,
        icon: Light
    },
    {
        title: 'Pair-Up',
        text: <span>Consider paring <a href="/">Jessica</a> with <a href="/">James</a>. He`s great with vocalics, and she`s awesome when it comes to account expansion and upseling.</span>,
        icon: People
    },
    // {
    //     title: 'Learn more',
    //     text: <span>Visit our <a href="/">“Learning Center”</a> for effective ways and research on vocal dominance.</span>,
    //     icon: Head
    // },
]

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]

export default () => {
    const [dropCurrent, setDropCurrent] = useState(data1[0])
    return (<>
        <div className="row">
            <div className="col opportunities-header">
                Dealmaker:
                <DropDown
                    data={data1}
                    current={dropCurrent}
                    setCurrent={setDropCurrent}
                    bgColor={'#F2F7FA'}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <h2 className="title">Specific insights</h2>
                <div className="list">
                    {
                        list.map((item, index) => (
                            <div className="list__elem" key={index}>
                                <div><img src={List} alt="list" className="list__elem-check"/></div>
                                {item}
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="col">
                <h2 className="title">Opportunities</h2>
                <div className="opportunities">
                    {
                        list2.map((item, index) => {
                            return (
                                <div className="opportunities__elem" key={index}>
                                    <div className="opportunities__elem-icon">
                                        <img src={item.icon} alt="list" />
                                    </div>
                                    <div className="opportunities__elem-content">
                                        <div className="opportunities__elem-content-label">{item.title}</div>
                                        <div className="opportunities__elem-content-text">{item.text}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="list-btn">
                        <a href="https://www.substrata.me/learning-center/">
                            <Button>
                                Go to Learning Center
                            </Button>
                        </a>
                        <div className="list-btn__text">
                            Visit our Learning Center for more research and useful insigths
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}