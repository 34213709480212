import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { DropDown } from '../../../components/DropDown'
import { Paper } from '../../../components/Paper'
import { Grafics } from '../../../components/Grafics'
import { LineGrafics } from '../../../components/LineGrafics'
import { connect } from 'react-redux'
import Widget from '../../../components/Widget'
import { UserInfo } from '../../../components/UserInfo'
import Pic from '../../../assets/user.png'
import './style.scss'

import Notification from '../../../assets/icon/notification.png'
import List from '../../../assets/icon/list.png'
import ArrowBack from '../../../assets/icon/arrow_back.png'

const list = [
    'This potential buyer is on the fence',
    'Current engagement ratio is not in your favor',
    'So far, you don’t have vocal dominance over your potential buyer'
]

const list2 = [
    'Since prospect isn’t “sold to” yet, there’s no point in discussing pricing/deal terms',
    'Improve your vocal dominance',
    'Review your alertss to understand what you can do to improve'
]

const DealsInsights = (props) => {

    const [dropCurrent, setDropCurrent] = useState(data1[0])

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (
                <div className="row">
                    <div className="col jcsb">
                        <DropDown
                            data={data1}
                            current={dropCurrent}
                            setCurrent={setDropCurrent}
                        />
                        <div className="btn-group">
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Notification} alt="notification" />
                            </Button>
                            <UserInfo
                                pic={Pic}
                                name={'Eran Yessodi'}
                                style={{marginLeft: '10px'}}
                            />
                        </div>
                    </div>
                </div>
            )
        })
    }, [])  // eslint-disable-line

    return (
        <div className="deal-insights">
            <div className="row">
                <div className="col">
                    <Paper>
                        <Widget />
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col flex-grow-1">
                    <div className="row">
                        <Paper
                            title={'Deal status summary'}
                        >
                            <div className="list">
                                {
                                    list.map((item, index) => (
                                        <div className="list__elem" key={index}>
                                            <div><img src={List} alt="list" className="list__elem-check"/></div>
                                            {item}
                                        </div>
                                    ))
                                }
                            </div>
                        </Paper>
                    </div>
                    <div className="row">
                        <Paper
                            title={'Deal insights'}
                        >
                            <div className="list">
                                {
                                    list2.map((item, index) => (
                                        <div className="list__elem" key={index}>
                                            <div><img src={List} alt="list" className="list__elem-check"/></div>
                                            {item}
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="list-btn">
                                <a href="https://www.substrata.me/learning-center/">
                                    <Button>
                                        Go to Learning Center
                                    </Button>
                                </a>
                            </div>
                        </Paper>
                    </div>
                </div>
                <div className="col flex-grow-2">
                    <div className="row">
                        <Paper>
                            <LineGrafics
                                data={data5}
                            />
                        </Paper>
                    </div>
                    
                    <div className="row">
                        <Paper>
                            <Grafics
                                data={data2}
                            />
                        </Paper>
                    </div>
                    <div className="row">
                        <Paper>
                            <Grafics
                                data={data3}
                            />
                        </Paper>
                    </div>
                    <div className="row">
                        <Paper>
                            <Grafics
                                data={data4}
                                revers={true}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col" style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Link to="/my-deals" style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 30,
                            width: 30,
                            background: '#394577',
                            borderRadius: 30,
                            marginRight: 15
                        }}>
                            <img src={ArrowBack} alt="arrow" />
                        </div>
                        Back
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        state: state
    })
)(DealsInsights);

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]

const data2 = {
    data: [
        {
            label: 'Deal lifecycle vs average',
            sub: 'by weeks',
            value: [
                {
                    color: '#f3cb3d',
                    value: 12,
                    sub: true
                },
                {
                    color: '#5F80F5',
                    value: 9,
                    sub: true
                }, 
            ]
        },
        {
            label: 'Est. contract value vs avg',
            sub: 'in USD',
            value: [
                {
                    color: '#f3cb3d',
                    value: 18202,
                },
                {
                    color: '#5F80F5',
                    value: 22010
                },
            ]
        }
    ],
    legend: [
        {
            color: '#F3CB3D',
            title: 'Dun & bradstreet'
        },
        {
            color: '#5F80F5',
            title: 'Average'
        }
    ]
}

const data3 = {
    data: [
        {
            label: 'Engagement ratio vs avg (seller/prospect)',
            sub: '',
            value: [
                {
                    color: '#f3cb3d',
                    value: 2.25,
                },
                {
                    color: '#5F80F5',
                    value: 1.08
                },
            ]
        },
        {
            label: 'Total alerts vs average',
            sub: '',
            value: [
                {
                    color: '#f3cb3d',
                    value: 27,
                },
                {
                    color: '#5F80F5',
                    value: 18
                },
            ]
        }
    ],
    legend: [
        {
            color: '#F3CB3D',
            title: 'You'
        },
        {
            color: '#5F80F5',
            title: 'Average'
        }
    ]
}

const data4 = {
    data: [
        {
            label: 'Vocal dominance score',
            sub: '',
            value: [
                {
                    color: '#f3cb3d',
                    value: 3.3,
                },
                {
                    color: '#5F80F5',
                    value: 3.9
                },
                {
                    color: '#26A653',
                    value: 4.3
                },
                {
                    color: '#F26065',
                    value: 3.2
                },
            ]
        }
    ],
    legend: [
        {
            color: '#F3CB3D',
            title: 'you'
        },
        {
            color: '#5F80F5',
            title: 'Prospect'
        },
        {
            color: '#26A653',
            title: 'Top sellers'
        },
        {
            color: '#F26065',
            title: 'closed-won'
        }
    ]
}

const data5 = {
    value: 50, 
    label: 'Buying zone',
    legend: [
        {
            color: '#32B76C',
            title: 'Ready to buy',
            width: 100
        },
        {
            color: '#F2C537',
            title: 'On the fence',
            width: 65
        },
        {
            color: '#F26065',
            title: 'NO GO',
            width: 35
        },
    ]
}

