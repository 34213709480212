import React, { useState, useEffect } from 'react'
import WidgetAll from './Widget/Widget'
import Widget from './WidgetEmail/Widget'

export default ({ setList }) => {

    const [visible, setVisible] = useState('Main contacts')

    useEffect(() => {
        setList && setList(visible)
    }, [visible]) // eslint-disable-line

    return (<>
        {
            visible === 'Main contacts'
                ? <Widget 
                    setVisible={setVisible}
                    visible={visible}
                />
                : visible === 'All contacts'
                    ? <WidgetAll 
                        setVisible={setVisible}
                        visible={visible}
                    />
                    : <Widget 
                        setVisible={setVisible}
                        visible={visible}
                    />
        }
    </>)
}