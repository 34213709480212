import React from 'react'
import styled from 'styled-components'

export default ({ style }) => {
    return <DotLine className="diagram-svg__line" style={style}>
        <svg>
            {
                [...new Array(200)].map((item, index) => {
                    return (
                        <line 
                            key={index}
                            stroke="#DAE0E7" 
                            strokeWidth="3"
                            x1={2 + (index * 10)} 
                            y1={0} 
                            x2={10 + (index * 10)}  
                            y2={0}
                        />
                    )
                })
            }
        </svg>
    </DotLine>
}

const DotLine = styled.div`
    height: 3px;
    width: 95%;
    overflow: hidden;
    transform: translateY(-50%);
    display: flex;

    svg {
        width: 100%;
        height: 3px;
    }
`