import React from 'react'
import styled from 'styled-components'

export const LineGrafic4 = ({ data, value, title }) => {
    return (
        <Grafic>
            <div className="v4-title">
                {title}
            </div>
            <div className="v4">
                <div className="v4-wrap">
                    {
                        data.map((item, index) => {
                            return (
                                <div 
                                    className="v4-wrap__elem" 
                                    key={index}
                                    style={{
                                        width: item.width,
                                        background: item.color
                                    }}
                                >
                                    <div className="v4-wrap__elem-tooltip">
                                        {item.value} prospects are ready to buy
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="v4-value">
                    {value}
                </div>
            </div>
        </Grafic>
    )
}

const Grafic = styled.div`
    margin-bottom: 20px;

    .v4 {
        display: flex;
        align-items: center;

        &-title {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #989899;
            margin-bottom: 20px;
        }

        &-value {
            font-weight: bold;
            font-size: 12px;
            line-height: 13px;
            color: #29335C;
            margin-left: 20px;
        }

        &-wrap {
            height: 7px;
            display: flex;

            &__elem {
                margin-right: 2px;
                position: relative;

                &:first-child {
                    border-bottom-left-radius: 5px;
                    border-top-left-radius: 5px;
                }

                &:last-child {
                    margin-right: 0;
                    border-bottom-right-radius: 5px;
                    border-top-right-radius: 5px;
                }

                &:hover {
                    z-index: 1000;
                    transform: scale(1.2);
                }

                &:hover {
                    .v4-wrap__elem-tooltip {
                        display: block;
                    }
                }

                &-tooltip {
                    min-width: 100px;
                    display: none;
                    position: absolute;
                    top: 130%;
                    left: 0;
                    font-size: 9px;
                    padding: 5px 8px;
                    border-radius: 5px;
                    background-color: #F2F7FA;
                    border: 2px solid #FFFFFF;
                    box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);
                    transform: scale(.9);
                }

            }
        }
    }
    
`