import React from'react'

export default ({ color, style }) => {
    return (
        <div 
            className="diagram-svg__point" 
            style={{
                ...style
            }}
        >
            <div className="diagram-svg__point-elem" style={{ background: color }}/>
        </div>
    )
}