import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import './style.scss'
import { Paper } from '../../../components/Paper'
import { Button } from '../../../components/Button'
import { DropDown } from '../../../components/DropDown'
import { VideoPlayer } from '../../../components/WidgetVideo/Widget'
import { UserInfo } from '../../../components/UserInfo'
import Pic from '../../../assets/user.png'
import InsightsList from './Insights'
import Eran from '../../../assets/Widget/user2.png'
import Lisa from '../../../assets/Widget/user4.png'
import Voice from '../../../assets/Player/voice.mp3'
import Voice2 from '../../../assets/Player/voice2.mp3'
import VideoFile from '../../../assets/Player/video.mp4'
import Add from '../../../assets/icon/add.png'
import Notification from '../../../assets/icon/notification.png'
import Arrow from '../../../assets/icon/arrow_line_v2.png'

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]
const data = {
    user: [
        {
            name: 'Eran',
            pic: Eran
        },
        {
            name: 'Lisa',
            pic: Lisa
        }
    ],
    audio: [Voice, Voice2],
    video: VideoFile,
    chat: [
        {
            id: 0,
            text: 'So how do you see your sales cycle? What can be improved?'
        },
        {
            id: 1,
            text: 'Honestly, we`re using a lot of tools, but in the end it`s more of an intuition thing you know.'
        },
        {
            id: 0,
            text: 'Trust me I know.'
        },
        {
            id: 1,
            text: 'I wish there was a way we could know exactly what are the issues at hand and how to deal with them. I spend an unbelievable amount of time listening to cold calls with customers just searching for the needle in the haystack'
        },
        {
            id: 0,
            text: 'Well actually, that`s exactly the reason I`m calling you in for.'
        },
        {
            id: 0,
            text: 'So how do you see your sales cycle? What can be improved?'
        },
        {
            id: 1,
            text: 'Honestly, we`re using a lot of tools, but in the end it`s more of an intuition thing you know.'
        },
        {
            id: 0,
            text: 'Trust me I know.'
        },
        {
            id: 1,
            text: 'I wish there was a way we could know exactly what are the issues at hand and how to deal with them. I spend an unbelievable amount of time listening to cold calls with customers just searching for the needle in the haystack'
        },
        {
            id: 0,
            text: 'Well actually, that`s exactly the reason I`m calling you in for.'
        },
    ]
}

const Video = (props) => {

    const [dropCurrent, setDropCurrent] = useState(data1[0])

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (<>
                <div className="row">
                    <div className="col jcsb">
                        <DropDown
                            data={data1}
                            current={dropCurrent}
                            setCurrent={setDropCurrent}
                        />
                        <div className="btn-group">
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Add} alt="add" />
                            </Button>
                            <Button
                                onClick={() => {}}
                                type={'min'}
                            >
                                <img src={Notification} alt="notification" />
                            </Button>
                            <UserInfo
                                pic={Pic}
                                name={'Eran Yessodi'}
                                style={{marginLeft: '10px'}}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{display: 'flex'}}>
                        <Link to="/my-deals">
                            <div className="widget-layout__header">
                                {
                                    data.user.map((item, index) => {
                                        return <img
                                            src={item.pic}
                                            alt="user"
                                            key={index}
                                            style={{transform: `translateX(-${index * 5}px)`}}
                                            className="widget-layout__header-pic"
                                        />
                                    })
                                }
                                <div className="widget-layout__header-text">View Relationship</div>
                                <img src={Arrow} alt="arrow" />
                            </div>
                        </Link>
                    </div>
                </div>
            </>)
        })
    }, []) // eslint-disable-line

    return (
        <div className="video">
            <div className="row">
                <div className="col" style={{width: '60%'}}>
                    <Paper title="Interaction #15 - Video call">
                        <div className="video-info">
                            <div className="video-info__top">
                                <div style={{fontWeight: 'bold'}}>Eran Yessodi, Lisa Brown</div>
                                <div>28 Dec 2019</div>
                            </div>
                            <div className="video-info__bottom">42:11 mins</div>
                        </div>
                        <VideoPlayer
                            data={data}
                        />
                    </Paper>
                </div>
                <div className="col" style={{width: '40%'}}>
                    <Paper
                        title="Interaction insights"
                    >
                        <div style={{padding: '0 30px'}}>
                            <InsightsList
                                data={dataList}
                            />
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

const dataList = [
    {
        text: 'Nice touch, bringing up our advantages over the competition when she said she spends a lot of time just listening to calls.',
        link: false
    },
    {
        text: "We've got some new use cases, let's go over them to see which fits the most.",
        icon: true
    }
]

export default connect(
    state => ({
        state: state
    })
)(Video);