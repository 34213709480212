import React from 'react'
import styled from 'styled-components'
import Menu from './Menu/Menu'
import { connect } from 'react-redux'

const MainLayout = (props) => {
    return (
        <StyledMainLayout>
            <Menu location={props.location}/>
            {
                props.state.header && (
                    <div className="header-layout">
                        { props.state.header }
                    </div>
                )
            }
            <div className="content">
                { props.children }
            </div>
        </StyledMainLayout>
    )
}

const StyledMainLayout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background: #F2F7FA;
    position: relative;

    .header-layout {
        width: calc(100% - 110px);
        margin-left: 110px;
        padding: 40px 45px 0;
        box-sizing: border-box;
    }

    .content {
        max-width: 1210px;
        width: calc(100% - 110px);
        padding: 40px 45px;
        box-sizing: border-box;
        margin-left: 110px;
    }
`

export default connect(
    state => ({
        state: state
    })
)(MainLayout);