import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

export default ({label, icon, link, active}) => {
    
    return (
        <div className="menu__elem-wrap">
            {
                link.indexOf('http') 
                    ? <Link className={`menu__elem${active ? ' active' : ''}`} to={link}>
                        <img src={icon[active ? 0 : 1]} alt="nav" />
                        <span className={`menu__elem-text${active ? ' active' : ''}`}>{label}</span>
                    </Link>
                    : <a className={`menu__elem${active ? ' active' : ''}`} href={link}>
                        <img src={icon[active ? 0 : 1]} alt="nav" />
                        <span className={`menu__elem-text${active ? ' active' : ''}`}>{label}</span>
                    </a>
            }
            <div className="line"></div>
        </div>
    )
}