import React from 'react'
import styled from 'styled-components'
// import LeftSide from './LeftSideAuth/Side'

export default ({ children }) => {

    return (
        <AuthLayout>
            {/* <LeftSide /> */}
            <div className="section">
                { children }
            </div>
        </AuthLayout>
    )
}

const AuthLayout = styled.section`
    height: 100vh;
    width: 100%;
    background: #F2F7FA;
    display: flex;

    .section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
`