import React, { useEffect, useState }from 'react';
import { Route, Redirect } from 'react-router-dom';
import moment from 'moment';
import Loader from '../components/loader'

// HACK FOR LOGIN
function isAutenticated() {
  if(!localStorage.getItem('loginTime')) {
    console.log('No login recorded');
    return false;
  }

  const loginTime = moment(localStorage.getItem('loginTime'), 'YYYY-MM-DDThh:mm:ss.SSSZ');
  if (loginTime.isBefore(moment().subtract(6, 'hours'))) {
    console.log('Login ttl');
    return false;
  }

  return true;
}
//END OF HACK


export default ({path, component, Layout, location}) => {
  const [loader, setLoader] = useState(true)
  useEffect(() => {
    setLoader(true)
    setTimeout(() => {
      setLoader(false)
    }, 1000)
  }, [path])
  
  
  if (path.startsWith('/auth')) {
    return loader
      ? <Loader />
      : (Layout
        ? <Layout location={location}>
            <Route path={path} component={component} />
        </Layout>
        : <Route path={path} component={component} />)
  }

  const authenticated = isAutenticated()
  if (!authenticated) {
    return (<Redirect to="/auth/login"/>)
  }

  return loader
    ? <Loader />
    : (Layout
      ? <Layout location={location}>
          <Route path={path} component={component} />
      </Layout>
      : <Route path={path} component={component} />)

}
