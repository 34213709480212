const initialState = {
    header: null
}

export default function Store(state = initialState, action) {
  switch (action.type) {
    case 'EDIT_HEADER':
        return {
            ...state,
            header: action.setHeader
        }
    default:
        return state
  }
}

