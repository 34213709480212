import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import RedArrow from '../../../assets/icon/red_arrow.png'
import Arrow from '../../../assets/icon/arrow_white.png'
import Arrowx2 from '../../../assets/icon/arrow_x2.png'
import Info from '../../../assets/icon/info.png'
import Mail from '../../../assets/icon/mail.png'
import Calendar from '../../../assets/Widget/calendar_v2.png'
import Phone from '../../../assets/Widget/phone_v2.png'
import Warning from '../../../assets/icon/warning.png'
import User2 from '../../../assets/Widget/user2.png'
import User4 from '../../../assets/Widget/user4.png'

const data = [
    {
        date: 'DEC 19 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 20 2019',
        pic: User2,
        type: 'mail',
        info: 'error'
    },
    {
        date: 'DEC 21 2019',
        pic: User4,
        type: 'phone',
        info: ''
    },
    {
        date: 'DEC 22 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 26 2019',
        pic: User2,
        type: 'phone',
        info: ''
    },
    {
        date: 'DEC 27 2019',
        pic: User2,
        type: 'calendar',
        info: 'error'
    },{
        date: 'DEC 19 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 20 2019',
        pic: User2,
        type: 'mail',
        info: 'error'
    },
    {
        date: 'DEC 21 2019',
        pic: User4,
        type: 'phone',
        info: ''
    },
    {
        date: 'DEC 22 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 26 2019',
        pic: User2,
        type: 'phone',
        info: ''
    },
    {
        date: 'DEC 27 2019',
        pic: User2,
        type: 'calendar',
        info: 'error'
    },{
        date: 'DEC 19 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 20 2019',
        pic: User2,
        type: 'mail',
        info: 'error'
    },
    {
        date: 'DEC 21 2019',
        pic: User4,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 22 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 26 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 27 2019',
        pic: User2,
        type: 'calendar',
        info: 'error'
    },{
        date: 'DEC 19 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 20 2019',
        pic: User2,
        type: 'mail',
        info: 'error'
    },
    {
        date: 'DEC 21 2019',
        pic: User4,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 22 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 26 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 27 2019',
        pic: User2,
        type: 'calendar',
        info: 'error'
    },{
        date: 'DEC 19 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 20 2019',
        pic: User2,
        type: 'mail',
        info: 'error'
    },
    {
        date: 'DEC 21 2019',
        pic: User4,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 22 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 26 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 27 2019',
        pic: User2,
        type: 'calendar',
        info: 'error'
    },{
        date: 'DEC 19 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 20 2019',
        pic: User2,
        type: 'mail',
        info: 'error'
    },
    {
        date: 'DEC 21 2019',
        pic: User4,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 22 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 26 2019',
        pic: User2,
        type: 'mail',
        info: ''
    },
    {
        date: 'DEC 27 2019',
        pic: User2,
        type: 'calendar',
        info: 'error'
    }
]

export default () => {

    const numPage = Math.floor(data.length/10) + 1
    const [currentPage, setCurrentPage] = useState(1)

    const dataOutput = () => {
        return [...data].splice((currentPage - 1) * 11 + 1, 11)
    }

    return (
        <div className="table-list">
            <div className="table-list__row">
                <div className="table-list__row-col">
                    Type
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    Who
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col start">
                    Date
                    <img src={RedArrow} alt="arrow" />
                </div>
                <div className="table-list__row-col">
                    #
                </div>
                <div className="table-list__row-col start">
                    Breakdown
                </div>
                <div className="table-list__row-col">
                    <img src={Info} alt="corona" />
                </div>
            </div>
            {
                dataOutput().map((item, index) => {
                    return (
                        <div className="table-list__row table-list__row-content" key={index}>
                            <div className="table-list__row-col">
                                {
                                    item.type === 'mail'
                                        ? <img src={Mail} alt="mail" />
                                        : item.type === 'calendar' 
                                            ? <img src={Calendar} alt="calendar" />
                                            : item.type === 'phone' 
                                                ? <img src={Phone} alt="phone" />
                                                : null
                                }
                            </div>
                            <div className="table-list__row-col">
                                <img src={item.pic} alt="user" className="user-pic" />
                            </div>
                            <div className="table-list__row-col start">
                                {item.date}
                            </div>
                            <div className="table-list__row-col">
                                {index + 1}
                            </div>
                            <div className="table-list__row-col start">
                                <Link to={`/my-deals/email/${index}`} className="table-link">View</Link>
                            </div>
                            <div className="table-list__row-col">
                                {
                                    item.info === 'error' 
                                        ? <img src={Warning} alt="corona" />
                                        : null
                                }
                            </div>
                            <div className="line" />
                        </div>
                    )
                })
            }
            <div className="page">
                <div className="page-line">
                    {
                        currentPage !== 1 && (<>
                            <div 
                                className="page-arrow"
                                onClick={() => {
                                    setCurrentPage(1)
                                }}
                            >
                                <img 
                                    src={Arrowx2} 
                                    alt="arrow" 
                                    style={{transform: 'rotate(180deg)'}}
                                />
                            </div>
                            <div 
                                className="page-arrow"
                                onClick={() => {
                                    setCurrentPage(val => val - 1)
                                }}
                            >
                                <img 
                                    src={Arrow} 
                                    alt="arrow" 
                                    style={{transform: 'rotate(180deg)'}}
                                />
                            </div>
                        </>)
                    }
                    <div className="page-num">
                        {`${currentPage} fo ${numPage}`}
                    </div>
                    {
                        currentPage !== numPage && (<>
                            <div 
                                className="page-arrow"
                                onClick={() => {
                                    setCurrentPage(val => val + 1)
                                }}
                            >
                                <img 
                                    src={Arrow} 
                                    alt="arrow" 
                                />
                            </div>
                            <div 
                                className="page-arrow"
                                onClick={() => {
                                    setCurrentPage(numPage)
                                }}
                            >
                                <img
                                    src={Arrowx2} 
                                    alt="arrow"
                                />
                            </div>
                        </>)
                    }
                </div>
            </div>
        </div>
    )
}