import React from 'react'
import DotLine from './DotLine'
import Point from './Point'
import Line from './Line'
import Col from './Col'
import './style.scss'

export const Diagram = ({ label, data, subdata }) => {

    const maxValue = Math.max.apply(null, data.map(item => Math.max.apply(null, item.value)))
    const numRow = Math.floor(maxValue/5000) + 1
    const numCol = label.length
    const widthCol = 100 / numCol

    return (
        <div className="diagram-scroll">
            <div className="diagram">
                <div className="diagram-main">
                    <div className="diagram-left" style={{height: `${numRow * 70 + 70}px`}}>
                        <div className="diagram-left__title">
                            {
                                [...new Array(numRow)].map((item, index) => {
                                    return (
                                        <div 
                                            className="diagram-left__title-second" 
                                            key={index}
                                            style={{
                                                top: `${(numRow * 70 + 70) - (70 * (index + 1))}px`
                                            }}
                                        >
                                            ${(index + 1) * 5}k
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="diagram-svg" style={{height: `${numRow * 70 + 70}px`}}>
                        {
                            [...new Array(numRow)].map((item, index) => {
                                return (
                                    <DotLine
                                        key={index}
                                        style={{
                                            top: `${(numRow * 70 + 70) - (70 * (index + 1))}px`
                                        }}
                                    />
                                )
                            })
                        }
                        {
                            subdata.map((item, index) => {
                                return (
                                    <Col
                                        key={`Col-${index}`}
                                        style={{
                                            left: `calc(${(widthCol * (index + 1)) - widthCol/2}%)`,
                                            height: `calc(${(70 * item/5000)}px - 8px)`
                                        }}
                                        value={item}
                                    />
                                )
                            })
                        }
                        {
                            data.map((item, index) => {
                                return (
                                    <Line 
                                        key={`line-${index}`}
                                        index={index}
                                        data={item}
                                        numRow={numRow}
                                        numCol={numCol}
                                    />
                                )
                            })
                        }
                        { 
                            data.map((item, index) => {
                                return item.value.map((elem, index2) => {
                                    return (
                                        <Point
                                            key={`${index}${index2}`}
                                            color={item.color}
                                            style={{
                                                left: `calc(${(widthCol * (index2 + 1)) - widthCol/2}% - 6px)`,
                                                top: `calc(${(numRow * 70 + 70) - (70 * elem/5000)}px - 6px)`
                                            }}
                                        />
                                    )
                                })
                            })
                        }
                    </div>
                </div>
                <div className="diagram-bottom">
                    <div className="diagram-bottom__title">
                        {
                            label.map((item, index) => {
                                return (
                                    <div 
                                        className="diagram-bottom__title-second" 
                                        key={index}
                                        style={{
                                            left: `${(widthCol * (index + 1)) - widthCol/2}%`
                                        }}
                                    >
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="diagram-bottom-legend">
                        { 
                            data.map((item, index) => {
                                return (
                                    <div className="diagram-bottom-legend__elem" key={index}>
                                        <div 
                                            className="diagram-bottom-legend__elem-point" 
                                            style={{
                                                background: item.color 
                                            }}
                                        >
                                            <div 
                                                className="diagram-bottom-legend__elem-subpoint" 
                                                style={{
                                                background: item.color 
                                                }}
                                            />
                                        </div>
                                        <div className="diagram-bottom-legend__elem-title">{item.name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}