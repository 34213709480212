import React, { useState, useEffect } from 'react'
import { Paper } from '../../../components/Paper'
import { Button } from '../../../components/Button'
import { Input } from '../../../components/Input'
import { InputFile } from '../../../components/InputFile'
import { InputDropDown } from '../../../components/InputDropDown'
import { Switch } from '../../../components/Switch'
import { UserInfo } from '../../../components/UserInfo'
import Pic from '../../../assets/user.png'
import { connect } from 'react-redux'
import './style.scss'

import Billing from './Billing'

const Settings = (props) => {

    const [value, setValue] = useState('Dealmaker')
    const [value2, setValue2] = useState('Senior project manager')
    const [value3, setValue3] = useState('Mr. Ori Manor')
    const [value4, setValue4] = useState('Ori@softarama.com')
    const [value5, setValue5] = useState('+972 54 55655 39')
    const [value6, setValue6] = useState('Softarama Inc.')
    const [value7, setValue7] = useState('5148 3377 1805')
    const [value8, setValue8] = useState('USA')
    const [value9, setValue9] = useState('121 Irvine blvd.')
    const [value10, setValue10] = useState('788 103')
    const [value11, setValue11] = useState('UTC +02:00 (Israel)')
    const [value12, setValue12] = useState('12345678')
    const [value13, setValue13] = useState('Security question')

    const [status1, setStatus1] = useState(true)
    const [status2, setStatus2] = useState(true)
    const [status3, setStatus3] = useState(true)
    const [status4, setStatus4] = useState(false)

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: <div className="row">
                <div className="col jcfe">
                    <UserInfo
                        pic={Pic}
                        name={'Eran Yessodi'}
                    />
                </div>
            </div>
        })
    }, [])  // eslint-disable-line

    return (
        <div className="settings">
            <div className="row">
                <div className="col flex-grow-2" style={{display: 'flex', width: '73%'}}>
                    <Paper
                        title={'Account Info'}
                    >
                        <div className="row">
                            <div className="col">
                                <InputDropDown
                                    title={'Account type'}
                                    value={value}
                                    setValue={setValue}
                                    list={[
                                        'Dealmaker',
                                        'test 2',
                                        'test 3',
                                        'test 4'
                                    ]}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    title={'Title'}
                                    value={value2}
                                    setValue={setValue2}
                                />
                            </div>
                            <div className="col" />
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <Input
                                    title={'Name'}
                                    value={value3}
                                    setValue={setValue3}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    title={'Email'}
                                    value={value4}
                                    setValue={setValue4}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    title={'Phone #'}
                                    value={value5}
                                    setValue={setValue5}
                                />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <Input
                                    title={'Company'}
                                    value={value6}
                                    setValue={setValue6}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    title={'Company ID / vat ID #'}
                                    value={value7}
                                    setValue={setValue7}
                                />
                            </div>
                            <div className="col">
                                <InputFile 
                                    title={'company logo'}
                                />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <InputDropDown
                                    title={'country'}
                                    value={value8}
                                    setValue={setValue8}
                                    list={[
                                        'USA',
                                        'test 2',
                                        'test 3',
                                        'test 4'
                                    ]}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    title={'ADdress'}
                                    value={value9}
                                    setValue={setValue9}
                                />
                            </div>
                            <div className="col">
                                <Input
                                    title={'zipcode'}
                                    value={value10}
                                    setValue={setValue10}
                                />
                            </div>
                        </div>
                        
                        <div className="row flex-grow-1">
                            <div className="col">
                                <Input
                                    title={'time zone'}
                                    value={value11}
                                    setValue={setValue11}
                                />
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className="col flex-grow-1" style={{width: '35%'}}>
                    <Paper
                        title={'Billing & Invoicing'}
                    >
                        <Billing

                        />
                    </Paper>
                    <Paper
                        title={'Subscription Plan'}
                    >
                        <div className="plan">
                            {'// Substrata Enterprise Edition'}
                            <div className="plan-btn">
                                <Button
                                    type="light"
                                >View details</Button>
                                <Button>
                                    Upgrade
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Paper
                        title={'Password & security'}
                    >
                        <div className="passwors">
                            <div className="row">
                                <div className="col">
                                    <Input
                                        title={'Password'}
                                        value={value12}
                                        setValue={setValue12}
                                        type="password"
                                        info="text"
                                        margin="5px"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col password-reset">
                                    Reset password
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <InputDropDown
                                        title={'Two-step verification'}
                                        value={value13}
                                        setValue={setValue13}
                                        list={[
                                            'Security question',
                                            'test 2',
                                            'test 3',
                                            'test 4'
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col verification">
                                    <div className="verification-label">
                                        phone verification
                                    </div>
                                    <div className="verification-text">
                                        Receive a unique 6-digit code to your phone along with your password
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col verification-btn">
                                    <Button
                                        type={'min'}
                                        style={{
                                            height: '30px',
                                            width: '110px'
                                        }}
                                    >
                                        Enable
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className="col">
                    <Paper
                        title={'Connected services & Integrations'}
                    >
                        <div className="connected">
                            <div className="connected-label">Salesforce</div>
                            <Switch
                                status={status1}
                                setStatus={setStatus1}
                            />
                        </div>
                        <div className="connected">
                            <div className="connected-label">Zoom.us</div>
                            <Switch
                                status={status2}
                                setStatus={setStatus2}
                            />
                        </div>
                        <div className="connected">
                            <div className="connected-label">G-SUITE</div>
                            <Switch
                                status={status3}
                                setStatus={setStatus3}
                            />
                        </div>
                        <div className="connected">
                            <div className="connected-label">Web ex</div>
                            <Switch
                                status={status4}
                                setStatus={setStatus4}
                            />
                        </div>
                    </Paper>
                </div>
                <div className="col">
                    <Paper
                        title={'Teams'}
                    >
                        <div className="terms">
                            <div className="terms-status">
                                <div className="terms-status__label">Status:</div>
                                <div className="terms-status__value">Enabled</div>
                            </div>
                            <div className="link">Go to team settings</div>
                        </div>
                    </Paper>
                    <Paper
                        title={'Notifications'}
                    >
                        <div className="notification">
                            {
                                ['Email', 'Mobile', 'Desktop'].map((item, index) => {
                                    return (
                                        <div className="notification-elem" key={index}>
                                            {item}
                                        </div>
                                    )
                                })
                            }
                            <div className="link">Other settings</div>

                            <h2 className="title">Permissions</h2>

                            <div className="link">Go to settings</div>
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        state: state
    })
)(Settings);