import React from 'react'
import styled from 'styled-components'

export const LineGrafic2 = ({ value }) => {
    return (
        <Grafic>
            <div className="v2-label">Target</div>
            <div className="v2">
                <div className="v2-wrap">
                    <div className="v2-line" style={{width: `${value}%`}}/>
                </div>
                <div className="v2-value">{value}%</div>
            </div>
        </Grafic>
    )
}

const Grafic = styled.div`
    margin: 0 20px;

    .v2 {
        display: flex;
        align-items: center;

        &-label {
            font-weight: bold;
            font-size: 9px;
            line-height: 12px;
            text-transform: uppercase;
            color: #25182E;
        }

        &-wrap {
            height: 7px;
            width: 100%;
            background: #F2F4FF;
            border-radius: 5px;
            overflow: hidden;
        }

        &-line {
            background: #5F80F5;
            border-radius: 5px;
            height: 100%;
        }

        &-value {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #25182E;
            margin: 0 20px;
        }
    }
`