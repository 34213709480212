import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { DropDown } from '../../../components/DropDown'
import { Paper } from '../../../components/Paper'
import { ScoreLine } from '../../../components/ScoreLine'
import { UserInfo } from '../../../components/UserInfo'
import { connect } from 'react-redux'
import Pic from '../../../assets/user.png'
import Widget from '../../../components/Widget'
import './style.scss'

import Notification from '../../../assets/icon/notification.png'
import Check from '../../../assets/icon/check.png'
import Error from '../../../assets/icon/error.png'
import Warning from '../../../assets/icon/warning.png'
import Arrow from '../../../assets/icon/arrow_white.png'
import ArrowBack from '../../../assets/icon/arrow_back.png'

import Data from './Text'

const DealsEmail = (props) => {

    const [dropCurrent, setDropCurrent] = useState(data1[0])
    const [page, setPage] = useState(1)

    const { id } = useParams()

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (
                <div className="row top">
                <div className="col jcsb">
                    <DropDown
                        data={data1}
                        current={dropCurrent}
                        setCurrent={setDropCurrent}
                    />
                    <div className="btn-group">
                        <Button
                            onClick={() => {}}
                            type={'min'}
                        >
                            <img src={Notification} alt="notification" />
                        </Button>
                        <UserInfo
                            pic={Pic}
                            name={'Eran Yessodi'}
                            style={{marginLeft: '10px'}}
                        />
                    </div>
                </div>
            </div>
            )
        })
    }, [])  // eslint-disable-line

    return (
        <>
            <div className="row">
                <div className="col">
                    <Paper>
                        <Widget />
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Paper
                        title='Eran Yessodi to Lisa Brown'
                        nav={<span className="mail-date">{Data[id - 1].date}</span>}
                    >
                        <div className="mail">
                            <div style={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column'
                            }}>
                                {Data[id - 1].text}
                            </div>
                        </div>
                        <div className="mail-arrow">
                            <div className="mail-arrow__elem" onClick={() => setPage(page => page > 1 ? page - 1 : page)}>
                                <img src={Arrow} alt="arrow" style={{transform: 'rotate(180deg)'}} />
                            </div>
                            <div className="mail-arrow__list">
                                {page}/3
                            </div>
                            <div className="mail-arrow__elem" onClick={() => setPage(page => page < 3 ? page + 1 : page)}>
                                <img src={Arrow} alt="arrow" />
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className="col">
                    <Paper
                        title='Message Strength Score'
                    >
                        
                        <div className="score">
                            <ScoreLine value={2} style={{marginBottom: '30px'}}/>
                            <div className="score-line" />
                            <h2 className="title">Insights</h2>
                            {
                                data2.map((item, index) => {
                                    return (
                                        <div className="score-insights" key={'0' + index}>
                                            <div className="score-insights__fage">
                                                <div className="score-insights__fage-line" />
                                                <img className="score-insights__img" src={item.status === 0 ? Check : item.status === 1 ? Error : Warning} alt="status" />
                                            </div>
                                            <div className="score-insights__text">
                                                {item.text}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="score-line" />
                            <h2 className="title">Interaction Breakdown</h2>
                            {
                                data3.map((item, index) => {
                                    return (
                                        <div className="score-breakdown" key={'1' + index}>
                                            <div className="score-breakdown__label">
                                                {item.label}
                                            </div>
                                            <div className="score-breakdown__text">
                                                {
                                                    typeof item.text === 'string'
                                                        ? item.text
                                                        : item.text.map((item, index) => {
                                                            return (
                                                                <div className="score-breakdown__text-elem" key={index}>
                                                                    {item}
                                                                </div>
                                                            )
                                                        })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col" style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Link to="/my-deals" style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 30,
                            width: 30,
                            background: '#394577',
                            borderRadius: 30,
                            marginRight: 15
                        }}>
                            <img src={ArrowBack} alt="arrow" />
                        </div>
                        Back
                    </Link>
                </div>
            </div>
        </>
    )
}

export default connect(
    state => ({
        state: state
    })
)(DealsEmail);

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]

const data2 = [
    {
        text: 'Real time tip applied successfully',
        status: 0
    },
    {
        text: '3 of your previous attemps to engage were ignored',
    },
    {
        text: <span>Subordinate language detected, <a href="/" className="underline">see marked</a></span>,
    },
    {
        text: 'More recipients - more complexity',
        status: 1
    }
]

const data3 = [
    {
        label: 'Date',
        text: '12 Dec 2019'
    },
    {
        label: 'Time',
        text: '3:48 pm (+3 GMT)'
    },
    {
        label: 'Sender',
        text: 'Eran Yessodi'
    },
    {
        label: 'Recipients',
        text: ['Lisa Brown', 'Chris johnson']
    },
    {
        label: 'Flags',
        text: '4'
    },
    {
        label: 'Event',
        text: '7 of 13'
    }
]