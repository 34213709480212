import React, { Suspense } from 'react'
import { Switch, Redirect } from "react-router-dom";
import Route from './routeHelper'

import AuthLayout from '../layout/AuthLayout'
import MainLayout from '../layout/MainLayout'

import Error from '../view/error'

import Login from '../view/auth/login'
import Registration from '../view/auth/registration'
import Forgot from '../view/auth/forgot'
import Password from '../view/auth/password'

import Insights from '../view/home/Insights/Insights'
import Deals from '../view/home/Deals/Deals'
import DealsEmail from '../view/home/DealsEmail/DealsEmail'
import DealsOverview from '../view/home/DealsOverview/DealsOverview'
import DealsOverviewTable from '../view/home/DealsOverviewTable/DealsOverview'
import DealsInsights from '../view/home/DealsInsights/DealsInsights'
import Settings from '../view/home/Settings/Settings'
import Zoom from '../view/home/Zoom/Zoom'
import ZoomInsight from '../view/home/ZoomInsights/Zoom'
import Stats from '../view/home/Stats/Stats'
import Video from '../view/home/Video/Video'
import VideoComment from '../view/home/VideoComment/Video'

import AdminInsight from '../view/admin/Insights/Insights'
import AdminStats from '../view/admin/Stats/Stats'

export default () => {
    return (
        <Suspense fillback={null}>
            <Switch>      
                <Redirect exact from="/" to="/auth/login" />
                <Redirect exact from="/admin" to="/admin/insights" />

                <Route path="/error" component={Error} />

                <Route path="/auth/login" component={Login} Layout={AuthLayout} />
                <Route path="/auth/get-started" component={Registration} Layout={AuthLayout} />
                <Route path="/auth/forgot-password" component={Forgot} Layout={AuthLayout} />
                <Route path="/auth/password" component={Password} Layout={AuthLayout} />
                
                <Route path="/my-insights" component={Insights} Layout={MainLayout} />
                <Route path="/my-deals" exact component={Deals} Layout={MainLayout} />
                <Route path="/my-deals/email/:id" component={DealsEmail} Layout={MainLayout} />
                <Route path="/my-deals/overview" component={DealsOverview} Layout={MainLayout} />
                <Route path="/my-deals/overview-table" component={DealsOverviewTable} Layout={MainLayout} />
                <Route path="/my-deals/insights" component={DealsInsights} Layout={MainLayout} />
                <Route path="/account" component={Settings} Layout={MainLayout} />
                <Route path="/zoom" exact component={Zoom} Layout={MainLayout} />
                <Route path="/zoom/insights" component={ZoomInsight} Layout={MainLayout} />
                <Route path="/video" exact component={Video} Layout={MainLayout} />
                <Route path="/video/comment" component={VideoComment} Layout={MainLayout} />
                <Route path="/my-stats" component={Stats} Layout={MainLayout} />

                <Route path="/admin/insights" exact component={AdminInsight} Layout={MainLayout} />
                <Route path="/admin/stats" exact component={AdminStats} Layout={MainLayout} />
                <Route path="/admin/deals" exact component={Deals} Layout={MainLayout} />
                <Route path="/admin/account" component={Settings} Layout={MainLayout} />
                <Route path="/account" component={null} Layout={MainLayout} />
                
                <Redirect to="/error" />
            </Switch>
        </Suspense>
    )
}