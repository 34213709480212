import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import { Button } from '../../../components/Button'
// import { DropDown } from '../../../components/DropDown'
import { LineGrafic2 } from '../../../components/LineGraficv2'
import { LineGrafic3 } from '../../../components/LineGraficv3'
import { Clock } from '../../../components/ClockDiagram/Clock'
import { Paper } from '../../../components/Paper'
import { DropDown } from '../../../components/DropDown'
import { connect } from 'react-redux'
import { UserInfo } from '../../../components/UserInfo'
import Pic from '../../../assets/user.png'
import './style.scss'

import { Diagram } from '../../../components/DiagramV2/Diagram'

import Mask from '../../../assets/icon/mask2.png'
import Mail from '../../../assets/Widget/mail.png'
import Phone from '../../../assets/Widget/phone.png'
import Video from '../../../assets/Widget/video.png'
import Arrow from '../../../assets/Widget/arrow_v3.png'
import Calendar from '../../../assets/Widget/calendar_v2.png'
import D9 from '../../../assets/d9.png'

const data1 = [
    'Dun & Bradstreet',
    'Jessica Taylor',
    'Hoover`s',
    'Experian',
    'Wells Fargo',
    'Bloomberg'
]

const Stats = (props) => {

    const [nav, setNav] = useState(0)
    const [dealNav, setDealNav] = useState(0)
    
    const [dropCurrent, setDropCurrent] = useState(data1[0])
    
    useEffect(() => {

        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: (
                <div className="row stats__nav">
                    <div className="col stats__nav">
                        <div 
                            className={`stats__nav-elem${nav === 0 ? ' active' : ''}`} 
                            onClick={() => setNav(0)}
                        >
                            By Team
                        </div>
                        <div 
                            className={`stats__nav-elem${nav === 1 ? ' active' : ''}`} 
                            onClick={() =>  setNav(1)}
                        >
                            By dealmaker
                        </div>
                        {
                            nav === 1 && (
                                <DropDown
                                    data={data1}
                                    current={dropCurrent}
                                    setCurrent={setDropCurrent}
                                />
                            )
                        }
                        <div className="stats__nav-calendar">
                            <img src={Calendar} alt="Calendar" />
                            2018 - 2019 by quarters
                        </div>
                    </div>
                    <UserInfo
                        pic={Pic}
                        name={'Eran Yessodi'}
                        style={{marginLeft: '10px'}}
                    />
                </div>
            )
        })
    }, [nav]) // eslint-disable-line

    return (
        <div className="stats">
            <div className="row">
                <div className="col" style={{display: 'flex'}}>
                    <Paper
                        title="Deals by stage"
                    >
                        <div style={{height: 170}}>
                            <Clock  
                                data={{
                                    h1: 50,
                                    h2: 65,
                                    h3: 75,
                                    h4: 90,
                                    sale: true,
                                    line: [
                                        {
                                            value: nav === 0 ? '508' : '108',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#25182E',
                                                bottom: '15%'
                                            }
                                        },
                                        {
                                            value: nav === 0 ? '43' : 7,
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#989899',
                                                bottom: '55%'
                                            }
                                        },
                                        {
                                            value: nav === 0 ? '57' : '19',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#989899',
                                                bottom: '65%'
                                            }
                                        },
                                        {
                                            value: nav === 0 ? '92' : '6',
                                            arrow: true,
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#989899',
                                                bottom: '75%'
                                            }
                                        },
                                        {
                                            value: 'Pipeline',
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '11px',
                                                lineHeight: '15px',
                                                color: '#25182E',
                                                bottom: '90%'
                                            }
                                        },
                                    ]
                                }}
                            />
                        </div>
                        <LineGrafic2
                            value={nav === 0 ? 66.7 : 77.1}
                        />
                        <div className="stats-legend">
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#5F80F5`}}/>
                                <div className="stats-legend__elem-title">Closed-won:</div>
                                <div className="stats-legend__elem-value">{nav === 0 ? '508/760' : '108/140'}</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#32B76C`}}/>
                                <div className="stats-legend__elem-title">Ready to buy:</div>
                                <div className="stats-legend__elem-value">{nav === 0 ? 43 : 7}</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#F3CB3D`}}/>
                                <div className="stats-legend__elem-title">On the fence:</div>
                                <div className="stats-legend__elem-value">{nav === 0 ? 57 : 19}</div>
                            </div>
                            <div className="stats-legend__elem">
                                <div className="stats-legend__elem-point" style={{background:  `#F26065`}}/>
                                <div className="stats-legend__elem-title">NO GO:</div>
                                <div className="stats-legend__elem-value">{nav === 0 ? 92 : 6}</div>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className="col">
                    <Paper style={{marginBottom: 10}}>
                        <div className='stats__title'>
                            Deals lifecycle
                            <div className="stats__title-sub">by weeks</div>
                            
                        </div>
                        <LineGrafic3
                            data={[
                                {
                                    color: '32B76C',
                                    value: 7
                                },
                                {
                                    color: 'F3CB3D',
                                    value: 12
                                },
                                {
                                    color: 'F26065',
                                    value: 14
                                }
                            ]}
                        />
                        <div className="stats-legend" style={{display: 'flex', justifyContent: 'space-between', padding: '1px 20px'}}>
                            <div className="stats-legend__elem" style={{margin: '20px 0'}}>
                                <div className="stats-legend__elem-point" style={{background:  `#32B76C`, marginRight: 5}}/>
                                <div className="stats-legend__elem-title">Shortest</div>
                            </div>
                            <div className="stats-legend__elem" style={{margin: '20px 0'}}>
                                <div className="stats-legend__elem-point" style={{background:  `#F3CB3D`, marginRight: 5}}/>
                                <div className="stats-legend__elem-title">Average</div>
                            </div>
                            <div className="stats-legend__elem" style={{margin: '20px 0'}}>
                                <div className="stats-legend__elem-point" style={{background:  `#F26065`, marginRight: 5}}/>
                                <div className="stats-legend__elem-title">Longest</div>
                            </div>
                        </div>
                    </Paper>
                    
                    <Paper>
                        <div className='stats__title'>
                            Closed-won breakdown
                            <div className="stats__title-sub">by engagement type</div>
                        </div>
                        <div className="stats__breakdown">
                            <img src={Mask} alt="mask" className="stats__breakdown-mask" />
                            <div className="stats__breakdown-elem-wrap">
                                <div className="stats__breakdown-elem" style={{background: `#394577`}}>
                                    <img src={Mail} alt="mail" />
                                    <div className="stats__breakdown-elem__title">
                                        {nav === 0 ? 18 : 16}
                                    </div>
                                </div>
                                <div className="stats__breakdown-elem" style={{background: `#32B76C`}}>
                                    <img src={Phone} alt="phone" />
                                    <div className="stats__breakdown-elem__title">
                                        {nav === 0 ? 7 : 6}
                                    </div>
                                </div>
                                <div className="stats__breakdown-elem" style={{background: `#32B76C`}}>
                                    <img src={Video} alt="video" />
                                    <div className="stats__breakdown-elem__title">
                                        {nav === 0 ? 6 : 5}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stats__breakdown-total">
                            {nav === 0 ? '31 total (avg)' : '27 total'}
                        </div>
                    </Paper>
                </div>
                <div className="col" style={{display: 'flex'}}>
                    <Paper
                        title={`Cancellations & refunds`}
                    >
                        <div className="stats__refunds">
                            <div className="stats__refunds-diagram">
                                <img src={D9} alt="diagram" style={{transform: 'rotate(50deg)'}} />
                                <div className="stats__refunds-diagram__legend">
                                    <div className="stats__refunds-diagram__legend-elem" style={{top: '23%'}}>
                                        <img src={Arrow} alt="arrow"/>
                                        {nav === 0 ? '2.8%' : '3.3%'}
                                    </div>
                                    <div className="stats__refunds-diagram__legend-elem" style={{top: '40%'}}>
                                        <img src={Arrow} alt="arrow"/>
                                        {nav === 0 ? '1.1%' : '2%'}
                                    </div>
                                    <div className="stats__refunds-diagram__legend-elem" style={{top: '70%'}}>
                                        <img src={Arrow} alt="arrow"/>
                                        {nav === 0 ? '96.1%' : '94.7%'}
                                    </div>
                                </div>
                            </div>
                            <div className="stats-legend">
                                <div className="stats-legend__elem" style={{margin: '35px 0'}}>
                                    <div className="stats-legend__elem-point" style={{background:  `#5F80F5`}}/>
                                    <div className="stats-legend__elem-title">Closed-won:</div>
                                    <div className="stats-legend__elem-value">{nav === 0 ? '96.1%' : '94.7%'}</div>
                                </div>
                                <div className="stats-legend__elem" style={{margin: '35px 0'}}>
                                    <div className="stats-legend__elem-point" style={{background:  `#F3CB3D`}}/>
                                    <div className="stats-legend__elem-title">Cancellations:</div>
                                    <div className="stats-legend__elem-value">{nav === 0 ? '2.8%' : '3.3%'}</div>
                                </div>
                                <div className="stats-legend__elem" style={{margin: '35px 0'}}>
                                    <div className="stats-legend__elem-point" style={{background:  `#F26065`}}/>
                                    <div className="stats-legend__elem-title">Refunds:</div>
                                    <div className="stats-legend__elem-value">{nav === 0 ? '1.1%' : '2%'}</div>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Paper>
                        <div className='stats__title'>
                            <div className="stats__title-nav">
                                Deal size
                                <div 
                                    className={`stats__title-nav__elem${dealNav === 0 ? ' active' : ''}`}
                                    onClick={() => setDealNav(0)}
                                >
                                    average
                                </div>
                                <div 
                                    className={`stats__title-nav__elem${dealNav === 1 ? ' active' : ''}`}
                                    onClick={() => setDealNav(1)}
                                >
                                    total
                                </div>
                            </div>
                            <div className="stats__title-sub">by quarter</div>
                        </div>
                        
                    <Diagram 
                        label={[
                            <span>Q<sub>1</sub>18</span>,
                            <span>Q<sub>2</sub>18</span>,
                            <span>Q<sub>3</sub>18</span>,
                            <span>Q<sub>4</sub>18</span>,
                            <span>Q<sub>1</sub>19</span>,
                            <span>Q<sub>2</sub>19</span>,
                            <span>Q<sub>3</sub>19</span>,
                            <span>Q<sub>4</sub>19</span>
                        ]}
                        subdata={nav === 0 
                            ? [18231, 17878, 20142, 16237, 20975, 21087, 21542, 23432]
                            : [17319, 16984, 19135, 15425, 19926, 20032, 20465, 22260]
                        }
                        data={[
                            {
                                name: 'Best performers trend line',
                                color: '#32B76C',
                                value: nav === 0 
                                    ? [23000, 24000, 25000, 22500, 25000, 26000, 27000, 28000]
                                    : [23000, 24000, 2500, 22500, 25000, 26000, 27000, 28000]
                            },
                            {
                                name: 'Worst performers trend line',
                                color: '#F26065',
                                value: nav === 0 
                                    ? [15000, 14000, 12000, 10000, 14500, 16500, 17500, 20000]
                                    : [15000, 14000, 12500, 10000, 14500, 16500, 17500, 20000]
                            }
                        ]}
                    />
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        state: state
    })
)(Stats);