import React from 'react'
import styled from 'styled-components'

export const Checkbox = ({
    value, 
    onChange, 
    checked,
    title
}) => {

    const check = value === checked

    return (
        <StyledCheckbox onClick={() => onChange(value)}>
            <div className="check-wrap">
                <div className={`check-active${check ? ' active' : ''}`} />
            </div>
            <div className={`check-title${check ? ' active' : ''}`}>
                {title}
            </div>
        </StyledCheckbox>
    )
}

const StyledCheckbox = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    .check {
        &-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background: #F3F8FA;
            box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);
            margin-right: 15px;
        }

        &-active {
            width: 0;
            height: 0;
            border-radius: 4px;
            background: #5F80F5;
            transition: all .2s ease-in;

            &.active {
                width: 8px;
                height: 8px;
            }
        }

        &-title {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #394577;

            &.active {
                font-weight: 600;
            }
        }
    }

`