import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

export default (props) => {
    const {current, next, lenghtX, lenghtY} = props

    const ref = useRef()
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [renderSvg, setRenderSvg] = useState([])

    const dot = (x, y, i) => <circle cx={x} cy={y} r="1" fill="rgba(57, 69, 119, 0.4)" key={i} />

    const point = () => {
        const heightStart = (height / 2) + (current.userId * height)
        const widthStart = (current.date.id * width) - (width / 2)
        const heightEnd = (height / 2) + (next.userId * height)
        const widthEnd = (next.date.id * width) - (width / 2)

        let line = [dot(widthStart, heightStart, 0)]

        for (let i = widthStart; i < widthEnd; i += 5) {
            line.push(dot(i, heightStart, i))
        }
        if (heightStart < heightEnd) {
            for (let i = heightStart; i < heightEnd; i += 5) {
                line.push(dot(widthEnd, i, `111${i}`))
            }  
        } else {
            for (let i = heightEnd; i < heightStart; i += 5) {
                line.push(dot(widthEnd, i, `111${i}`))
            } 
        }
        
        setRenderSvg(line)
    }

    useEffect(() => {
        window.addEventListener('onload', editSize)
        window.addEventListener('resize', editSize)
    }, []) // eslint-disable-line

    useEffect(() => {
        editSize()
    }, [ref, ref.current, current, next, lenghtX, lenghtY]) // eslint-disable-line

    useEffect(() => {
        point()
    }, [height, width]) // eslint-disable-line

    const editSize = () => {
        setWidth(ref.current.width.animVal.value/ lenghtX)
        setHeight(ref.current.height.animVal.value / lenghtY)
    }

    return (
        <StyledDotLine
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
        >
            {renderSvg}
        </StyledDotLine>
    )
}

const StyledDotLine = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 25;

    animation-name: fage3;
    animation-duration: 0.8s;
    animation-delay: 2s;
    animation-timing-function: linear;
    animation-fill-mode: backwards;

    @keyframes fage3 {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
`