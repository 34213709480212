import React, { useState, useEffect } from 'react'
import { Paper } from '../../../components/Paper'
import { connect } from 'react-redux'
import { UserInfo } from '../../../components/UserInfo'
import Pic from '../../../assets/user.png'
import './style.scss'

import ryg from '../../../assets/icon/ryg.png'
import red from '../../../assets/icon/red.png'
import yellow from '../../../assets/icon/yellow.png'
import green from '../../../assets/icon/green.png'
import Filter from '../../../assets/Widget/filter.png'

import DealsByStage from './DealsByStage'
import Optimization from './Optimization'
import PerfomanceTrends from './PerfomanceTrends'
import LeaderBoard from './LeaderBoard'

import user1 from '../../../assets/admin/user1.png'
import user2 from '../../../assets/admin/user2.png'
import user3 from '../../../assets/admin/user3.png'
import user4 from '../../../assets/admin/user4.png'
import user5 from '../../../assets/admin/user5.png'
import user6 from '../../../assets/admin/user6.png'
import user7 from '../../../assets/admin/user7.png'
import user8 from '../../../assets/admin/user8.png'

const dataNav = [
    'Deals by stage',
    'Perfomance trends',
    'Leader boards',
    'Optimization'
]

const filter = [
    {
        label: 'All',
        color: ryg
    },
    {
        label: 'Ready to Buy',
        color: green
    },
    {
        label: 'On the Fence',
        color: yellow
    },
    {
        label: 'No Go',
        color: red
    }
]

const Insights = (props) => {

    const [nav, setNav] = useState(0)
    const [currentFilter, setCurrnetFilter] = useState(filter[0])
    const [visibleFilter, setVisibleFilter] = useState(false)

    useEffect(() => {
        props.dispatch({ 
            type: 'EDIT_HEADER', 
            setHeader: <div className="row">
                <div className="col jcfe">
                    <UserInfo
                        pic={Pic}
                        name={'Eran Yessodi'}
                        style={{marginLeft: '10px'}}
                    />
                </div>
            </div>
        })
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className="row">
            <div className="col">
                <Paper
                    title={'Admin Insights'}
                >
                    <div className="admin-insights">
                        <div className="admin-nav">
                            {
                                dataNav.map((item, index) => {
                                    return (
                                        <div 
                                            key={index} 
                                            className={`admin-nav__elem${nav === index ? ' active' : ''}`} 
                                            onClick={() => setNav(index)}
                                        >
                                            {item}
                                        </div>
                                    )
                                })
                            }
                            {
                                nav === 0
                                    ? (
                                        <div className="admin-nav__elem admin-nav__filter" onClick={() => setVisibleFilter(val => !val)}>
                                            <img src={Filter} alt="filter" />
                                            <div className="admin-nav__filter-check-text" >Filter by:</div>
                                            <div className="admin-nav__filter-check">
                                                <img src={currentFilter.color} alt="all" className="admin-nav__filter-check-img" />
                                                <div className="admin-nav__filter-check-label">{currentFilter.label}</div>
                                            </div>
                                            {
                                                visibleFilter && (
                                                    <div className="admin-nav__filter-popup">
                                                        {
                                                            filter.map((item, index) => {
                                                                return (
                                                                    <div 
                                                                        key={index}
                                                                        className="admin-nav__filter-popup__elem"
                                                                        onClick={() => {
                                                                            setCurrnetFilter(filter[index])
                                                                        }}
                                                                    >
                                                                        <div className="admin-nav__filter-check">
                                                                            <img src={item.color} alt="all" className="admin-nav__filter-check-img" />
                                                                            {item.label}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                    : <div className={`admin-nav__elem`}/>
                            }
                            
                        </div>
                        {
                            nav === 0 && 
                                <DealsByStage 
                                    data={data}
                                />
                        }
                        {
                            nav === 1 && 
                                <PerfomanceTrends

                                />
                        }
                        {
                            nav === 2 &&
                                <LeaderBoard

                                />
                        }
                        {
                            nav === 3 && 
                                <Optimization 
                                
                                />
                        }
                    </div>
                </Paper>
            </div>
        </div>
    )
}

const random = (max = 10) => Math.floor(Math.random() * max)

const generateData = (
    value = random(), 
    status = random() > 5 ? 'error' : '', 
    bold = random() > 4, 
    child = true
) => {
    return {
        value: value,
        bold: bold,
        status: status,
        child: child 
            ? [
                {
                    title: 'NO GO',
                    color: '#F26065',
                    value: '3'
                },
                {
                    title: 'On the fence',
                    color: '#F3CB3D',
                    value: '5'
                },
                {
                    title: 'Ready to buy',
                    color: '#32B76C',
                    value: '1'
                } 
            ]
            : null
    }
}

export default connect(
    state => ({
        state: state
    })
)(Insights);

const data = [
    {
        pic: user1,
        name: 'John Higgins',
        qualification: generateData(17, 'error', true),
        analysis: generateData(8, '', false),
        note: generateData(5, '', false),
        negotiation: generateData(7, '', false),
        total: generateData(7, '', true, false)
    },
    {
        pic: user2,
        name: 'Beverly Mendes',
        qualification: generateData(12, '', false),
        analysis: generateData(11, '', false),
        note: generateData(8, '', false),
        negotiation: generateData(5, '', false),
        total: generateData(36, '', true)
    },
    {
        pic: user3,
        name: 'Emma Watkins',
        qualification: generateData(8, 'eror', false),
        analysis: generateData(9, '', false),
        note: generateData(6, '', false),
        negotiation: generateData(10, '', false),
        total: generateData(33, '', true)
    },
    {
        pic: user4,
        name: 'William Young',
        qualification: generateData(15, '', false),
        analysis: generateData(13, '', false),
        note: generateData(8, '', false),
        negotiation: generateData(4, '', false),
        total: generateData(39, '', true)
    },
    {
        pic: user5,
        name: 'Donna Perez',
        qualification: generateData(6, '', false),
        analysis: generateData(7, '', false),
        note: generateData(8, '', false),
        negotiation: generateData(9, 'error', true),
        total: generateData(34, '', true)
    },
    {
        pic: user6,
        name: 'Don Moore',
        qualification: generateData(6, '', false),
        analysis: generateData(5, '', false),
        note: generateData(7, '', false),
        negotiation: generateData(4, '', false),
        total: generateData(22, 'error', true)
    },
    {
        pic: user7,
        name: 'James Reed',
        qualification: generateData(10, '', false),
        analysis: generateData(5, '', false),
        note: generateData(12, '', false),
        negotiation: generateData(10, '', false),
        total: generateData(37, 'error', true)
    },
    {
        pic: user8,
        name: 'Beth Stewart',
        qualification: generateData(8, '', false),
        analysis: generateData(7, '', false),
        note: generateData(11, '', false),
        negotiation: generateData(6, '', false),
        total: generateData(32, '', true)
    }
]