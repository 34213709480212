import React from 'react'
import './style.scss'
import MenuElem from './MenuElem'
// import Logo from '../../assets/logo.png'
import Logo from '../../assets/logo.jpg'
import { MenuConfig, AdminMenuConfig } from './MenuConfig'

export default (props) => {
    const adminType = () => props.location.pathname.split('?')[0].split('/')[1] === 'admin'
    const current = link => props.location.pathname.split('?')[0].split('/')[adminType() ? 2 : 1] === link
    const selectConfig = () => adminType() ? AdminMenuConfig : MenuConfig
    return (
        <div className="menu">
            <div className="menu__top">
                <div className="menu__logo">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="menu__link">
                    {
                        selectConfig().top.map((item, index) => {
                            return (
                                <MenuElem
                                    key={index}
                                    label={item.label}
                                    icon={item.icon}
                                    link={item.link}
                                    active={current(item.active)}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className="menu__bottom">
                {
                    selectConfig().bottom.map((item, index) => {
                        return (
                            <MenuElem
                                key={index}
                                label={item.label}
                                icon={item.icon}
                                link={item.link}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}